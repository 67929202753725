<template>
	<v-card class="d-flex flex-column pb-5">
		<v-toolbar
			color="primary"
			class="pr-3"
			flat
		>
			<v-toolbar-title>{{ title }}</v-toolbar-title>

			<v-spacer />

			<v-btn
				class="mr-3 bg-white text-primary"
				elevation="2"
				@click="displayDeleted = !displayDeleted"
			>
				<template v-if="!displayDeleted">
					<v-icon start> mdi-eye </v-icon>
					Show deleted
				</template>

				<template v-else>
					<v-icon start> mdi-eye-off </v-icon>
					Hide deleted
				</template>
			</v-btn>

			<v-dialog
				v-model="dialog"
				max-width="800px"
				transition="fade-transition"
				@click:outside="clearSelectedUser"
			>
				<template #activator="{ props }">
					<v-btn
						v-bind="props"
						class="bg-secondary text-white"
						elevation="2"
					>
						<v-icon start> mdi-plus </v-icon>
						Add new user
					</v-btn>
				</template>

				<user-edit
					:edit-mode="dialogEditMode"
					:default-user="selectedUser"
					@cancel="closeDialog"
					@refresh="closeDialogAndRefresh"
				/>
			</v-dialog>

			<v-dialog
				v-model="deleteDialog"
				max-width="800px"
				transition="fade-transition"
			>
				<v-card>
					<v-toolbar
						color="primary"
						dark
						flat
					>
						<v-toolbar-title>Delete user</v-toolbar-title>
						<v-hover-icon class="mr-3" />
					</v-toolbar>

					<v-card-title class="text-center py-4">
						Are you sure you want to delete this user?
					</v-card-title>

					<v-divider />

					<v-card-subtitle class="text-center py-4">
						{{ getUserLabel(selectedUser) }}
					</v-card-subtitle>

					<v-divider />

					<v-card-actions class="py-5">
						<v-spacer />

						<v-btn
							class="bg-secondary text-white"
							elevation="2"
							@click="deleteUser"
						>
							Yes
						</v-btn>

						<v-btn
							class="bg-grey"
							elevation="2"
							@click="closeDeleteDialog"
						>
							No
						</v-btn>

						<v-spacer />
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog
				v-model="restoreDialog"
				max-width="800px"
				transition="fade-transition"
			>
				<v-card>
					<v-toolbar
						color="primary"
						dark
						flat
					>
						<v-toolbar-title>Restore user</v-toolbar-title>
						<v-hover-icon class="mr-3" />
					</v-toolbar>

					<v-card-title class="text-center py-4">
						Are you sure you want to restore this user?
					</v-card-title>

					<v-divider />

					<v-card-subtitle class="text-center py-4">
						{{ getUserLabel(selectedUser) }}
					</v-card-subtitle>

					<v-divider />

					<v-card-actions class="py-5">
						<v-spacer />

						<v-btn
							class="bg-secondary text-white"
							elevation="2"
							@click="restoreUser"
						>
							Yes
						</v-btn>

						<v-btn
							class="bg-grey"
							elevation="2"
							@click="closeRestoreDialog"
						>
							No
						</v-btn>

						<v-spacer />
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-toolbar>

		<v-data-table-server
			v-model:options="options"
			class="user-table"
			item-key="uuid"
			:headers="headers"
			:items="users"
			:row-props="getRowProps"
			:loading="loading"
			:items-length="totalUsers"
		>
			<template #item.email="{ item }">
				{{ item.email }}

				<v-chip
					v-if="isAdmin(item)"
					class="ml-1 px-1 bg-accent text-white"
					label
					size="x-small"
				>
					ADMIN
				</v-chip>

				<v-chip
					v-if="isSSO(item)"
					class="ml-1 px-1"
					color="secondary"
					label
					size="x-small"
				>
					SSO
				</v-chip>
			</template>

			<template #item.last_login_at="{ item }">
				{{ getLastLoginTimestamp(item) }}
			</template>

			<template #item.created_at="{ item }">
				{{ getCreatedTimestamp(item) }}
			</template>

			<template #item.organizations="{ item }">
				<div class="d-flex flex-wrap my-2">
					<v-chip
						v-for="organization of item.organizations"
						:key="organization.uuid"
						size="small"
						:disabled="!!item.deleted_at"
					>
						{{ organization.name }}
					</v-chip>

					<v-tooltip
						v-if="!item.organizations.length"
						location="bottom"
						open-delay="500"
					>
						<template #activator="{ props }">
							<v-icon
								v-bind="props"
								color="error"
								class="cursor-default"
							>
								mdi-alert-circle
							</v-icon>
						</template>

						<span>No associated organizations</span>
					</v-tooltip>
				</div>
			</template>

			<template #item.roles="{ item }">
				<div class="d-flex flex-wrap my-2">
					<v-chip
						v-for="role of item.roles"
						:key="role.uuid"
						size="small"
						:disabled="!!item.deleted_at"
					>
						{{ role.name }}
					</v-chip>

					<v-tooltip
						v-if="!item.roles.length"
						location="bottom"
						open-delay="500"
					>
						<template #activator="{ props }">
							<v-icon
								v-bind="props"
								color="error"
								class="cursor-default"
							>
								mdi-alert-circle
							</v-icon>
						</template>

						<span>No associated roles</span>
					</v-tooltip>
				</div>
			</template>

			<template #item.actions="{ item }">
				<v-tooltip
					v-if="!item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								class="mr-2"
								:color="hover ? 'secondary' : ''"
								@click="edit(item)"
							>
								mdi-pencil
							</v-icon>
						</v-hover>
					</template>

					<span>Edit</span>
				</v-tooltip>

				<v-tooltip
					v-if="!item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								:color="hover ? 'error' : ''"
								@click="remove(item)"
							>
								mdi-delete
							</v-icon>
						</v-hover>
					</template>

					<span>Delete</span>
				</v-tooltip>

				<v-tooltip
					v-if="item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								:color="hover ? 'secondary' : ''"
								@click="restore(item)"
							>
								mdi-restore
							</v-icon>
						</v-hover>
					</template>

					<span>Restore</span>
				</v-tooltip>
			</template>
		</v-data-table-server>
	</v-card>
</template>

<script>
import { mapMutations } from "vuex";

import UserEdit from "@/pages/UserManager/UserEdit";
import VHoverIcon from "@/components/VHoverIcon";

export default {
	name: "UserTable",

	components: {
		UserEdit,
		VHoverIcon,
	},

	props: {
		refresh: Boolean,
	},

	data() {
		return {
			deleteDialog: false,
			dialog: false,
			dialogEditMode: false,
			displayDeleted: false,
			loading: false,
			options: {},
			restoreDialog: false,
			selectedUser: {},
			title: "Users",
			totalUsers: 0,
			users: [],
			headers: [
				{
					title: "User email",
					value: "email",
					width: "190px",
				},
				{
					title: "First name",
					value: "first_name",
					width: "125px",
				},
				{
					title: "Last name",
					value: "last_name",
					width: "125px",
				},
				{
					title: "UUID",
					value: "uuid",
					align: "left",
					width: "20%",
				},
				{
					title: "Roles",
					value: "roles",
					align: "left",
					width: "15%",
				},
				{
					title: "Organizations",
					value: "organizations",
					align: "left",
					width: "155px",
				},
				{
					title: "Last login at",
					value: "last_login_at",
					align: "center",
					divider: true,
					width: "165px",
				},
				// {
				// 	title: "Created at",
				// 	value: "created_at",
				// 	align: "center",
				// 	divider: true,
				// 	width: "170px",
				// },
				{
					title: "Actions",
					value: "actions",
					align: "center",
					width: "95px",
					sortable: false,
				},
			],
		};
	},

	watch: {
		options: {
			deep: true,
			handler() {
				this.getUsers();
			},
		},
		displayDeleted() {
			this.options.page = 1;
			this.getUsers();
		},
	},

	methods: {
		...mapMutations(["showNotification"]),
		isAdmin(user) {
			return user.roles.length && user.roles[0].slug === "administrator";
		},
		isSSO(user) {
			return user.sso_enabled;
		},
		getRowProps(user) {
			return {
				class: this.isDeleted(user),
			};
		},
		isDeleted(user) {
			return user.deleted_at ? "deleted" : "";
		},
		getUserLabel(user) {
			return `${user.first_name} ${user.last_name} (${user.email})`;
		},
		getLastLoginTimestamp(user) {
			return user.last_login_at
				? this._getLocaleTimestamp(user.last_login_at)
				: "-";
		},
		getCreatedTimestamp(user) {
			return this._getLocaleTimestamp(user.created_at);
		},
		_getLocaleTimestamp(timestamp) {
			return new Date(timestamp).toLocaleString();
		},
		clearSelectedUser() {
			this.dialogEditMode = false;
			setTimeout(() => {
				this.selectedUser = {};
			}, 300);
		},
		remove(item) {
			this.selectedUser = Object.assign({}, item);

			this.deleteDialog = true;
		},
		closeDeleteDialog() {
			this.deleteDialog = false;

			this.clearSelectedUser();
		},
		deleteUser() {
			this.loading = "secondary";

			if (this.selectedUser) {
				this.$http
					.delete(`/users/${this.selectedUser.uuid}`)
					.then((response) => {
						// display success
						this.showNotification(response.data);

						// refresh table
						this.getUsers();
					})
					.catch((error) => {
						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}

						this.loading = false;
					});
			} else {
				this.loading = false;
			}

			this.closeDeleteDialog();
		},
		restore(item) {
			this.selectedUser = Object.assign({}, item);

			this.restoreDialog = true;
		},
		closeRestoreDialog() {
			this.restoreDialog = false;

			this.clearSelectedUser();
		},
		restoreUser() {
			this.loading = "secondary";

			if (this.selectedUser) {
				this.$http
					.put(`/users/${this.selectedUser.uuid}`, {
						deleted_at: null,
					})
					.then((response) => {
						// display success
						this.showNotification(response.data);

						// refresh table
						this.getUsers();
					})
					.catch((error) => {
						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}

						this.loading = false;
					});
			} else {
				this.loading = false;
			}

			this.closeRestoreDialog();
		},
		edit(item) {
			this.selectedUser = Object.assign({}, item);
			this.dialogEditMode = true;
			this.dialog = true;
		},
		closeDialog() {
			this.dialog = false;
			this.dialogEditMode = false;

			setTimeout(() => {
				this.clearSelectedUser();
			}, 250);
		},
		closeDialogAndRefresh() {
			this.closeDialog();

			this.getUsers();
		},
		getUsers() {
			this.loading = "secondary";

			const { filter, itemsPerPage, page, sortBy, sortDesc } =
				this.options;

			this.$http
				.get("/users", {
					params: {
						...(this.displayDeleted ? { deletedOnly: true } : {}),
						...(!this.displayDeleted ? { omitDeleted: true } : {}),
						filter: filter,
						itemsPerPage: itemsPerPage,
						page: page,
						sortBy: sortBy,
						sortDesc: sortDesc,
					},
				})
				.then((response) => {
					this.users = response.data.users.data;
					this.totalUsers = parseInt(
						response.data.users.meta.total,
						10
					);
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.user-table) {
	&.v-data-table .v-table__wrapper td {
		font-size: 12px;
	}

	&.v-data-table .v-table__wrapper th {
		font-size: 12px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.6);
		height: 48px;
	}
}
</style>
