// interfaces to bridge functionality between Dactyl and other modules
// each must have an "init" function

import * as Elements from "./wpd/elements.js";
import * as Events from "./wpd/events.js";
import * as Functions from "./wpd/functions.js";
import * as KeyboardShortcuts from "./wpd/keyboard-shortcuts.js";
import * as Styles from "./wpd/styles.js";

import * as AcquireData from "./wpd/acquire-data.js";
import * as AlignAxes from "./wpd/align-axes.js";
import * as Colors from "./wpd/colors.js";
import * as CustomFunctions from "./wpd/custom-functions.js";
import * as Dataset from "./wpd/dataset.js";
import * as DataSeriesManagement from "./wpd/data-series-management.js";
import * as FileManager from "./wpd/file-manager.js";
import * as GraphicsWidget from "./wpd/graphics-widget.js";
import * as ImageManager from "./wpd/image-manager.js";
import * as LayoutManager from "./wpd/layout-manager.js";
import * as Repainters from "./wpd/repainters.js";
import * as PageManager from "./wpd/page-manager.js";
import * as PdfManager from "./wpd/pdf-manager.js";
import * as PlotData from "./wpd/plot-data.js";
import * as PointGroups from "./wpd/point-groups.js";
import * as TableAxis from "./wpd/table-axis.js";
import * as TagGroups from "./wpd/tag-groups.js";
import * as Tools from "./wpd/tools.js";
import * as Tree from "./wpd/tree.js";
import * as Utils from "./wpd/utils.js";

const interfaces = {
	wpd: (() => {
		let wpdDocument = null;
		let wpd = null;

		const options = {};

		/**
		 * Sets up customizations for Dactyl.
		 * @param {Object}       wpdWindow   - Main window object
		 * @param {VueComponent} dataEntryVM - DataEntry Vue instance
		 */
		function init(wpdWindow, dataEntryVM) {
			wpdDocument = wpdWindow.document;
			wpd = wpdWindow.wpd;

			// With Vue3 upgrade, the default logging was leading to 404s in "/modules/wpd/app/log"
			wpd.log = function () {
				return;
			};

			// utils
			wpd.CompositeKeyUtils = dataEntryVM.compositeKeyUtils;

			// apply UI changes
			Elements.apply(wpd, wpdDocument, dataEntryVM);
			Styles.apply(wpdDocument);

			// initialize custom logic
			AcquireData.init(wpd);
			AlignAxes.init(wpd, wpdDocument, dataEntryVM);
			Colors.init(wpd);
			CustomFunctions.init(wpd, wpdWindow, wpdDocument, dataEntryVM);
			Dataset.init(wpd);
			DataSeriesManagement.init(wpd, wpdDocument, dataEntryVM);
			FileManager.init(wpd, dataEntryVM);
			GraphicsWidget.init(wpd, wpdDocument, dataEntryVM);
			ImageManager.init(wpd);
			LayoutManager.init(wpd, wpdWindow, wpdDocument, dataEntryVM);
			Repainters.init(wpd);
			PageManager.init(wpd, wpdDocument, dataEntryVM);
			if (dataEntryVM.highQualityPdfZoomFlag) {
				PdfManager.init(wpd, wpdWindow, wpdDocument, dataEntryVM);
			}
			PlotData.init(wpd);
			PointGroups.init(wpd, wpdDocument, dataEntryVM);
			TagGroups.init(wpd, wpdDocument, dataEntryVM);
			TableAxis.init(wpd, wpdWindow, wpdDocument, dataEntryVM, options);
			Tools.init(wpd, wpdDocument, wpdWindow, dataEntryVM);
			Tree.init(wpd, wpdDocument, dataEntryVM);
			Utils.init(wpd);

			// apply events
			Events.apply(wpd, wpdWindow, wpdDocument);

			// apply custom keyboard shortcuts
			KeyboardShortcuts.apply(wpd, wpdDocument);

			// overwrite WPD init workflow
			wpd.loadDefaultImage = () => {};
			wpd.initApp();
		}

		return {
			init: init,
			getBounds: () => Functions.getBounds(wpdDocument),
			getCurrentLocationInfo: () => Functions.getCurrentLocationInfo(wpd),
			inject: (dataEntryVM) =>
				Functions.inject(wpd, wpdDocument, dataEntryVM),
			loadFiles: (files) => Functions.loadFiles(wpd, files),
			loadJSON: async (dataEntryVM, json) =>
				await Functions.loadJSON(wpd, wpdDocument, dataEntryVM, json),
			saveJSON: () => Functions.saveJSON(wpd),
			setMetadata: (payload) => Functions.setMetadata(wpd, payload),
			setOptions: (newOptions) =>
				Functions.setOptions(options, newOptions),
		};
	})(),
};

export default interfaces;
