<template>
	<v-hover v-slot="{ hover }">
		<div
			class="file-upload-overlay pt-2 pr-2"
			:class="{ 'overlay-enabled': hover }"
			@dragover.capture.stop.prevent="onDragOver"
			@dragleave.capture.stop.prevent="onDragLeave"
			@drop.capture.stop.prevent="onDrop"
		>
			<v-file-input
				ref="fileInput"
				v-model="files"
				:disabled="disabled"
				:error="error"
				:hint="hint"
				:placeholder="placeholder"
				clearable
				color="secondary"
				label="Upload files"
				prepend-icon="mdi-paperclip"
				counter
				multiple
				variant="outlined"
				persistent-hint
				show-size
				@click:clear="onClear"
			>
				<template #selection="{ fileNames }">
					<template
						v-for="(fileName, index) in fileNames"
						:key="fileName"
					>
						<v-chip
							class="bg-secondary text-white chip-ellipsis"
							closable
							@click:close="removeFile(index)"
						>
							{{ truncateText(fileName, 50) }}
						</v-chip>
					</template>
				</template>
			</v-file-input>
		</div>
	</v-hover>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";
import utils from "@/utils";

export default {
	name: "FileUpload",

	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
		success: {
			type: Boolean,
			default: false,
		},
		modelValue: { type: Array, default: () => [] },
	},

	emits: ["removed", "update:modelValue"],

	data() {
		return {
			hint: "Include all associated files, such as supplements. Removed files are not deleted from Dactyl.",
			placeholder: "Drag files here or click here to select files",
			overlay: false,
			removedFiles: [],
		};
	},

	computed: {
		files: {
			get() {
				return this.modelValue;
			},
			set(val) {
				if (_.isEmpty(val)) {
					this.$emit("update:modelValue", []);
				} else {
					const addedFiles = _.differenceWith(
						val,
						this.modelValue,
						_.isEqual
					);

					this.$emit("update:modelValue", [
						...addedFiles,
						...this.files,
					]);
				}
			},
		},
	},

	watch: {
		error(val) {
			this.delayedReset(val);
		},
		success(val) {
			this.delayedReset(val);
		},
	},

	methods: {
		...mapMutations(["showNotification"]),
		truncateText(text) {
			return utils.truncateText(text);
		},
		removeFile(index) {
			if (this.files[index].uuid) {
				this.$emit("removed", [this.files[index]]);
			}
			this.files = this.files.filter((_, i) => i !== index);
		},
		delayedReset(flag) {
			if (flag === true) {
				setTimeout(() => (flag = false), 1000);
			}
		},
		onClear() {
			this.$emit(
				"removed",
				this.files.filter((file) => file.uuid)
			);
			this.files = [];
		},
		onDragOver() {
			if (this.dragGuard) {
				clearTimeout(this.dragGuard);
				this.dragGuard = null;
			}

			this.overlay = true;
		},
		onDragLeave() {
			if (!this.dragGuard) {
				this.dragGuard = setTimeout(() => {
					this.overlay = false;
				}, 100);
			}
		},
		onDrop() {
			// set files to new files
			this.files = [...event.dataTransfer.files];
			this.overlay = false;
		},
	},
};
</script>

<style scoped lang="scss">
.file-upload-overlay {
	position: relative;
	background: rgba($color-dactyl-orange, 0);
	border-radius: 5px;

	&.overlay-enabled {
		background: rgba($color-dactyl-orange, 0.3);
		outline: 3px dashed $color-dactyl-orange;
	}

	& .overlay-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $color-dactyl-blue;
		opacity: 0;

		&.overlay-enabled {
			opacity: 1;
			z-index: 100;
		}
	}
}

:deep(.chip-ellipsis) {
	z-index: 999;
	.v-chip__content {
		z-index: 999;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

:deep(.v-field__input) {
	z-index: 2;
}
</style>
