<template>
	<v-dialog
		v-model="showDialog"
		max-width="700"
	>
		<v-card class="pb-5">
			<v-toolbar
				color="primary"
				dark
				flat
			>
				<v-toolbar-title> Unsupported Dactyl Version </v-toolbar-title>
			</v-toolbar>
			<v-card-text
				class="pa-5 d-flex flex-sm-nowrap align-center justify-center validation-header-text"
			>
				We've detected data that was extracted for this paper using an
				unsupported version of Dactyl. Unfortunately, we are unable to
				load the data; please contact support if you have any questions.
			</v-card-text>

			<v-card-actions>
				<v-spacer />
				<v-btn
					class="bg-grey"
					elevation="2"
					@click="closeDialog"
				>
					Ok
				</v-btn>
				<v-spacer />
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "DataEntryPreReworkDialog",

	props: {
		modelValue: Boolean,
	},
	emits: ["update:modelValue"],

	computed: {
		showDialog: {
			get() {
				return this.modelValue;
			},
			set(newValue) {
				this.$emit("update:modelValue", newValue);
			},
		},
	},

	methods: {
		closeDialog() {
			this.showDialog = false;
		},
	},
};
</script>

<style scoped lang="scss"></style>
