/**
 * Initializes the layout manager in WPD.
 */
function init(wpd, wpdWindow, wpdDocument, dataEntryVM) {
	wpd.layoutManager = (function () {
		var layoutTimer,
			$graphicsContainer,
			$sidebarContainer,
			$sidebarControlsContainer,
			$centerContainer,
			$mainContainer,
			$treeContainer;

		// Redo layout when window is resized
		function adjustLayout() {
			let windowWidth = parseInt(wpdDocument.body.offsetWidth, 10);
			let windowHeight = parseInt(wpdDocument.body.offsetHeight, 10);

			let bannerHeight = 0;
			if (dataEntryVM.showBanner) {
				bannerHeight = 58;
			}

			$sidebarContainer.style.height = windowHeight + "px";
			$sidebarControlsContainer.style.height =
				windowHeight - 280 - bannerHeight + "px";
			$mainContainer.style.width =
				windowWidth - $sidebarContainer.offsetWidth - 5 + "px";
			$mainContainer.style.height = windowHeight - bannerHeight + "px";
			$graphicsContainer.style.height =
				windowHeight - 85 - 56 - bannerHeight + "px";
			$treeContainer.style.height =
				windowHeight - 85 - bannerHeight + "px";
			$centerContainer.style.height =
				windowHeight - 85 - bannerHeight + "px";
			wpd.sidebar.resize();
		}

		function getGraphicsViewportSize() {
			return {
				width: $graphicsContainer.offsetWidth,
				height: $graphicsContainer.offsetHeight,
			};
		}

		// event handler
		function adjustLayoutOnResize(ev) {
			clearTimeout(layoutTimer);
			layoutTimer = setTimeout(adjustLayout, 80);
		}

		// Set initial layout. Called right when the app is loaded.
		function initialLayout() {
			// do initial layout and also bind to the window resize event
			$graphicsContainer =
				wpdDocument.getElementById("graphicsContainer");
			$sidebarContainer = wpdDocument.getElementById("sidebarContainer");
			$sidebarControlsContainer = wpdDocument.getElementById(
				"sidebarControlsContainer"
			);
			$mainContainer = wpdDocument.getElementById("mainContainer");
			$treeContainer = wpdDocument.getElementById("left-side-container");
			$centerContainer = wpdDocument.getElementById("center-container");
			adjustLayout();

			wpdWindow.addEventListener("resize", adjustLayoutOnResize, false);
			wpdWindow.addEventListener("message", adjustLayoutOnResize);

			wpd.tree.init();
		}

		return {
			initialLayout: initialLayout,
			getGraphicsViewportSize: getGraphicsViewportSize,
		};
	})();
}

export { init };
