<template>
	<v-navigation-drawer
		permanent
		width="275"
		elevation="2"
		class="dactyl-nav-drawer"
	>
		<v-list
			density="compact"
			nav
		>
			<v-list-item :class="{ 'px-0': mini }">
				<v-container class="d-flex align-center pl-0 pr-0 pt-3 pb-3">
					<v-avatar
						color="primary"
						size="48"
						class="mr-4 flex-shrink-0"
					>
						<span class="text-white text-h5">{{
							user.initials
						}}</span>
					</v-avatar>
					<v-container class="pa-0 min-width-0 overflow-hidden">
						<v-list-item-title>
							{{ user.first_name }} {{ user.last_name }}
						</v-list-item-title>
						<v-list-item-subtitle>
							{{ user.email }}
						</v-list-item-subtitle>
					</v-container>
				</v-container>
			</v-list-item>

			<v-divider class="mb-2" />

			<div
				v-for="item in menuItems"
				:key="item.title"
			>
				<v-list-item
					v-if="!item.subGroup && userHasPermission(item.permissions)"
					color="secondary"
					:to="item.to"
				>
					<v-container class="d-flex align-center pa-0">
						<v-icon class="mr-8">
							{{ item.icon }}
						</v-icon>
						<v-list-item-title>
							{{ item.title }}
						</v-list-item-title>
					</v-container>
				</v-list-item>

				<v-list-group
					v-else-if="
						item.subGroup && userHasPermission(item.permissions)
					"
					class="nav-group-container"
					append-icon="mdi-account-circle"
				>
					<template #activator="{ props }">
						<v-list-item
							v-bind="props"
							class="d-flex align-center pl-2 pr-2 pt-0 pb-0"
							color="secondary"
							append-icon="mdi-chevron-down"
						>
							<v-container
								class="d-flex align-center pl-0 pr-0 pt-3 pb-3"
							>
								<v-icon class="mr-8">
									{{ item.icon }}
								</v-icon>
								<v-list-item-title>
									{{ item.title }}
								</v-list-item-title>
							</v-container>
						</v-list-item>
					</template>

					<v-list-item
						v-for="subItem in item.subGroup"
						:key="subItem.title"
						class="d-flex align-center pl-2 pr-2 pt-0 pb-0"
						color="secondary"
						:to="subItem.to"
					>
						<v-container class="d-flex align-center pa-0">
							<v-icon class="mr-8">
								{{ subItem.icon }}
							</v-icon>
							<v-list-item-title>
								{{ subItem.title }}
							</v-list-item-title>
						</v-container>
					</v-list-item>
				</v-list-group>
			</div>
		</v-list>
	</v-navigation-drawer>
	<v-container
		class="pa-12"
		fluid
	>
		<v-row
			align-content="start"
			justify="start"
			class="flex-nowrap"
		>
			<v-col class="pt-0 pl-8 flex-grow-1">
				<router-view />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { useLDFlag } from "launchdarkly-vue-client-sdk";

const permissions = [
	"create_user",
	"edit_user",
	"edit_user_self",
	"data_entry",
	"delete_user",
	"delete_file",
	"manage_tags",
	"quality_control",
	"upload_file",
	"manage_organizations",
	"access_completed_extractions",
];

export default {
	name: "DactylHome",

	props: {
		login: {
			type: Boolean,
			default: false,
		},
		showBanner: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		"token",
		"enterDataEntry",
		"leaveDataEntry",
		"enterDataEntryTraining",
		"leaveDataEntryTraining",
		"enterQualityControl",
		"leaveQualityControl",
		"activateActivityTimer",
		"deactivateActivityTimer",
		"checkDirty",
		"handleLogoutSave",
	],

	setup() {
		const qcDashboardEnabled = useLDFlag("qc-dashboard");

		return {
			qcDashboardEnabled,
		};
	},

	data() {
		return {
			isAdmin: false,
			userPermissions: {},
			mini: false,
			menuItems: [
				{
					permissions: [["data_entry"], ["quality_control"]],
					icon: "mdi-keyboard",
					title: "Source Data Extraction",
					to: "/home/dashboard",
				},
				{
					permissions: [["data_entry"], ["quality_control"]],
					icon: "mdi-school",
					title: "Training",
					to: "/home/training-dashboard",
				},
				{
					permissions: ["access_completed_extractions"],
					icon: "mdi-file-document-check",
					title: "Completed Extractions",
					to: "/home/completed-extractions",
				},
				{
					permissions: ["delete_file", "upload_file"],
					icon: "mdi-briefcase",
					title: "Project Management",
					to: "/home/project-manager",
				},
				{
					permissions: ["delete_file", "upload_file"],
					icon: "mdi-file-document-multiple",
					title: "Paper Management",
					to: "/home/paper-manager",
				},
				{
					permissions: ["manage_tags"],
					icon: "mdi-tag-edit",
					title: "Tag Management",
					subGroup: [
						{
							permissions: ["manage_tags"],
							icon: "mdi-tag-text",
							title: "Tag Groups",
							to: "/home/tag/tag-group-manager",
						},
						{
							permissions: ["manage_tags"],
							icon: "mdi-tag",
							title: "Base Tags",
							to: "/home/tag/base-tag-manager",
						},
					],
				},
				{
					permissions: ["create_user", "edit_user", "delete_user"],
					icon: "mdi-account-supervisor",
					title: "User Management",
					to: "/home/user-manager",
				},
				{
					permissions: ["manage_organizations"],
					icon: "mdi-office-building-cog",
					title: "Organizations",
					to: "/home/organizations",
				},
				{
					permissions: ["edit_user_self"],
					icon: "mdi-account-cog",
					title: "Settings",
					to: "/home/settings",
				},
			],
		};
	},

	computed: {
		user() {
			return this.$store.getters.user;
		},
	},

	async created() {
		await this.isAdminUser();

		if (!this.isAdmin) {
			await this.getUserPermissions();
		} else {
			// is admin, no need to fetch permissions
			this.userPermissions = permissions.reduce((result, permission) => {
				result[permission] = true;
				return result;
			}, {});
		}

		// const qcDashboardEnabled = useLDFlag("qc-dashboard");
		if (this.qcDashboardEnabled || this.isAdmin) {
			this.menuItems.splice(2, 0, {
				permissions: ["quality_control"],
				icon: "mdi-eye-check",
				title: "Quality Control",
				to: "/home/qc-dashboard",
			});
		}
	},

	methods: {
		...mapMutations(["showNotification"]),
		isAdminUser() {
			return this.$http
				.get(`/users/${this.user.uuid}/is-admin`)
				.then(({ data }) => {
					this.isAdmin = data;
				});
		},
		getUserPermissions() {
			return this.$http
				.get(`/users/${this.user.uuid}/can-map`, {
					params: {
						permissions: permissions,
					},
				})
				.then(({ data }) => {
					this.userPermissions = data;
				})
				.catch(() => {
					// do not display errors on these permission checks
				});
		},
		userHasPermission(permissions) {
			if (Array.isArray(permissions[0])) {
				// or-logic required
				return permissions.some((set) =>
					set.every((permission) => this.userPermissions[permission])
				);
			}

			return permissions.every(
				(permission) => this.userPermissions[permission]
			);
		},
	},
};
</script>

<style lang="scss">
.deleted {
	td {
		color: #999;
		text-decoration: line-through;
		text-decoration-thickness: 1px;
	}

	td:last-of-type {
		opacity: 1;
		text-decoration: none;
	}
}

.dactyl-nav-drawer {
	height: auto !important;
	border-radius: 4px;
}

.nav-group-container > .v-list-group__header {
	// get rid of the extra bottom margin on v-list-group in the side nav
	margin-bottom: 0 !important;

	.v-list-item__icon > i {
		color: rgba(0, 0, 0, 0.54) !important;
	}

	.v-list-item__content > .v-list-item__title {
		color: rgba(0, 0, 0, 0.87);
	}
}
</style>
