/**
 * Initializes WPD File Manager.
 */
function init(wpd, dataEntryVM) {
	// update file input initialization to include disabled options
	wpd.FileManager.prototype._initializeInput = function (disabled) {
		const labels = Array.prototype.map.call(
			this.files,
			(file) => file.name
		);
		const values = wpd.utils.integerRange(this.files.length);
		const selected = this.currentIndex;
		this.$fileSelector.innerHTML = wpd.utils.createOptionsHTML(
			labels,
			values,
			selected,
			disabled
		);
	};

	// update set to set unsupported files as disabled in the options
	wpd.FileManager.prototype.set = function (files) {
		this.files = files;

		// disable unsupported file types in the file drop down
		const disabled = [];
		this.files.forEach((file, index) => {
			if (!wpd.utils.isSupportedFile(file)) {
				disabled.push(index);
			}
		});

		this._initializeInput(disabled);

		if (files.length > 1) {
			this._showFileInfo();
		} else {
			this._hideFileInfo();
			this.$navSeparator.hidden = true;
		}
	};

	// add delete axes from current file function to file manager
	wpd.FileManager.prototype.deleteAxesFromCurrentFile = function (axes) {
		wpd.utils.deleteFromCollection(
			this.axesByFile,
			this.currentIndex,
			axes
		);
	};

	// add current page storing logic
	wpd.FileManager.prototype.getMetadata = function () {
		const metadata = {};

		const allMeasurements = wpd.appData.getPlotData().getMeasurementColl();

		// save the latest page manager, in case it hasn't been saved
		this._savePageManager();

		// only include file metadata if there is more than 1 file
		if (this.fileCount() > 1) {
			metadata.file = {
				axes: this.getAxesNameMap(),
				datasets: this.getDatasetNameMap(),
				measurements: allMeasurements.map((ms) =>
					wpd.utils.findKey(this.measurementsByFile, ms)
				),
			};
		}

		// only include page and pageLabel metadata if there are page managers saved in the file manager
		if (Object.keys(this.pageManagers).length > 0) {
			// setting axes name maps and dataset name maps to start with an empty object
			// for ease of calling Object.assign later
			let axesNameMaps = [{}];
			let datasetNameMaps = [{}];
			let measurementPageMaps = []; // measurements do not have unique names
			let pageLabelMaps = {};
			let rotationMaps = {};
			let currentPages = {};

			// collect metadata from all page managers
			for (const index in this.pageManagers) {
				axesNameMaps.push(this.pageManagers[index].getAxesNameMap());
				datasetNameMaps.push(
					this.pageManagers[index].getDatasetNameMap()
				);
				measurementPageMaps.push(
					this.pageManagers[index].getMeasurementPageMap()
				);

				const pageLabelMap = this.pageManagers[index].getPageLabelMap();
				if (Object.keys(pageLabelMap).length) {
					pageLabelMaps[index] = pageLabelMap;
				}

				const rotationMap = this.pageManagers[index].getRotationMap();
				if (Object.keys(rotationMap).length) {
					rotationMaps[index] = rotationMap;
				}

				currentPages[index] = this.pageManagers[index].currentPage();
			}

			metadata.page = {
				axes: Object.assign.apply(null, axesNameMaps),
				datasets: Object.assign.apply(null, datasetNameMaps),
				measurements: allMeasurements.map((ms) => {
					for (const measurementPageMap of measurementPageMaps) {
						const foundPage = wpd.utils.findKey(
							measurementPageMap,
							ms
						);
						if (foundPage) {
							return foundPage;
						}
					}
				}),
			};

			// always include current file index
			metadata.misc = {
				current: {
					file: this.currentFileIndex(),
					zoom: wpd.graphicsWidget.getZoomRatio(),
					scroll: wpd.graphicsWidget.getScroll(),
				},
			};

			if (
				Object.keys(pageLabelMaps).length ||
				Object.keys(rotationMaps).length ||
				Object.keys(currentPages).length
			) {
				// include page label maps by file in the miscellaneous category
				if (Object.keys(pageLabelMaps).length) {
					metadata.misc.pageLabel = pageLabelMaps;
				}

				// include rotation maps by file in the miscellaneous category
				if (Object.keys(rotationMaps).length) {
					metadata.misc.rotation = rotationMaps;
				}

				// include current page map by file in the miscellaneous category
				if (Object.keys(currentPages).length) {
					metadata.misc.current.page = currentPages;
				}
			}
		}

		return metadata;
	};

	// add current page loading logic
	wpd.FileManager.prototype.loadMetadata = async function (metadata) {
		let fileManager = this;

		// load file metadata
		if (metadata.file) {
			fileManager.axesByFile = metadata.file.axes || {};
			fileManager.datasetsByFile = metadata.file.datasets || {};
			fileManager.measurementsByFile = metadata.file.measurements || {};
		} else {
			// if there does not exist file indexes, assume there is only one file and
			// associate all data collections with the only file
			fileManager.axesByFile["0"] = wpd.appData
				.getPlotData()
				.getAxesColl()
				.slice();
			fileManager.datasetsByFile["0"] = wpd.appData
				.getPlotData()
				.getDatasets()
				.slice();
			fileManager.measurementsByFile["0"] = wpd.appData
				.getPlotData()
				.getMeasurementColl()
				.slice();
		}

		let files = [];
		for (let index = 0; index < fileManager.files.length; index++) {
			let filePromise = null;
			if (fileManager.files[index].type === "application/pdf") {
				// if the first file is a pdf, it has already been loaded with a page manager
				// save the page manager
				if (index === 0) {
					fileManager._savePageManager();
				} else {
					filePromise = new Promise((resolve) => {
						let reader = new FileReader();
						reader.onload = function () {
							if (!dataEntryVM.isUnmounted) {
								// get the window object of the wpd frame to access pdfjs
								document
									.getElementById("wpd")
									.contentWindow.pdfjsLib.getDocument(
										reader.result
									)
									.promise.then((pdf) => resolve(pdf));
							}
						};
						reader.readAsDataURL(this.files[index]);
					});
				}
			}
			files.push(filePromise);
		}

		const files_1 = await Promise.all(files);
		for (let index_1 = 0; index_1 < files_1.length; index_1++) {
			let pageData = {};

			// only supporting pages in pdf files for now, this should include tiff files
			// in the future
			if (fileManager.files[index_1].type === "application/pdf") {
				if (files_1[index_1] !== null) {
					// initialize page managers
					fileManager.pageManagers[index_1] =
						wpd.imageManager.initializePDFManager(
							files_1[index_1],
							true
						);
				}

				// load page metadata
				if (metadata.page) {
					let pageAxes = {};
					let pageDatasets = {};
					let pageMeasurements = {};

					for (const page in metadata.page.axes) {
						pageAxes[page] = metadata.page.axes[page].filter(
							(ax) => {
								return (
									fileManager.axesByFile[index_1] &&
									fileManager.axesByFile[index_1].indexOf(
										ax
									) > -1
								);
							}
						);
					}
					for (const page_1 in metadata.page.datasets) {
						pageDatasets[page_1] = metadata.page.datasets[
							page_1
						].filter((ds) => {
							return (
								fileManager.datasetsByFile[index_1] &&
								fileManager.datasetsByFile[index_1].indexOf(
									ds
								) > -1
							);
						});
					}
					for (const page_2 in metadata.page.measurements) {
						pageMeasurements[page_2] = metadata.page.measurements[
							page_2
						].filter((ms) => {
							return (
								fileManager.measurementsByFile[index_1] &&
								fileManager.measurementsByFile[index_1].indexOf(
									ms
								) > -1
							);
						});
					}

					Object.assign(pageData, {
						axes: pageAxes,
						datasets: pageDatasets,
						measurements: pageMeasurements,
					});
				}
			}

			// load miscellaneous metadata
			if (metadata.misc) {
				// load page labels
				if (metadata.misc.pageLabel) {
					if (Object.hasOwn(fileManager.pageManagers, index_1)) {
						Object.assign(pageData, {
							pageLabels: metadata.misc.pageLabel[index_1],
						});
					}
				}

				// load page rotations
				if (metadata.misc.rotation) {
					if (Object.hasOwn(fileManager.pageManagers, index_1)) {
						Object.assign(pageData, {
							rotations: metadata.misc.rotation[index_1],
						});
					}
				}

				// load current page
				if (metadata.misc.current?.page) {
					if (Object.hasOwn(fileManager.pageManagers, index_1)) {
						Object.assign(pageData, {
							currentPage: metadata.misc.current.page[index_1],
						});
					}
				}
			}

			// load page data into page manager
			if (Object.hasOwn(fileManager.pageManagers, index_1)) {
				if (Object.keys(pageData).length) {
					fileManager.pageManagers[index_1].loadPageData(pageData);
				}
			}
		}
		// switch to saved current file
		if (metadata.misc?.current?.file !== undefined) {
			await fileManager.switch(metadata.misc.current.file);

			// switch to the current page in the current file
			const pageManager =
				fileManager.pageManagers[metadata.misc.current.file];
			if (pageManager) {
				await pageManager.switch(pageManager.currentPage());
			}
		} else {
			// no saved file index
			if (Object.hasOwn(fileManager.pageManagers, 0)) {
				// refresh the page select input for the first file
				fileManager.pageManagers[0].refreshInput();
			}
		}
		if (metadata.misc?.current?.zoom) {
			wpd.graphicsWidget.setZoomRatio(metadata.misc.current.zoom);
		}
		if (metadata.misc?.current?.scroll) {
			wpd.graphicsWidget.setScroll(metadata.misc.current.scroll);
		}
		wpd.tree.refresh();
		return await new Promise((resolve_1) =>
			resolve_1(metadata.misc?.current?.file)
		);
	};

	// update switch function to include updating the select input after switching and remove same page check
	wpd.FileManager.prototype.switch = async function (index) {
		const newIndex = parseInt(index, 10);
		if (newIndex > -1 && newIndex <= this.files.length) {
			// unselect all data points
			wpd.tree.getActiveDataset()?.unselectAll();

			// save page manager
			this._savePageManager();

			// load or clear page manager
			this._loadPageManager(newIndex);

			// save undo manager
			this._saveUndoManager();

			// load or clear undo manager
			this._loadUndoManager(newIndex);

			// update current file index
			this.currentIndex = newIndex;

			// update input
			this.$fileSelector.value = newIndex;

			// refresh the tree
			await wpd.tree.selectPath(null);
			wpd.tree.refresh();
			wpd.tagGroups.refreshAll(false, true);

			// load the file
			return wpd.imageManager.loadFromFile(this.files[newIndex], true);
		}
	};
}

export { init };
