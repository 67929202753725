<template>
	<v-dialog
		v-model="showDialog"
		max-width="700"
	>
		<v-card class="pb-5">
			<v-toolbar
				color="primary"
				dark
				flat
			>
				<v-toolbar-title>
					Source Data Extraction Validation
				</v-toolbar-title>
			</v-toolbar>
			<v-card-text
				class="pa-5 d-flex flex-sm-nowrap align-center justify-center validation-header-text"
			>
				<v-icon
					v-if="isValid"
					class="mr-3"
					color="green"
					size="x-large"
				>
					mdi-check-circle
				</v-icon>
				<v-icon
					v-else
					class="mr-3"
					color="red"
					size="x-large"
				>
					mdi-alert-circle
				</v-icon>
				{{ validationMessage }}
			</v-card-text>

			<data-entry-validation-table
				v-if="!isValid"
				:validation-data="validationData"
			></data-entry-validation-table>
			<v-card-actions>
				<v-spacer />
				<v-btn
					v-if="isValid"
					class="mr-3 bg-grey"
					elevation="2"
					@click="downloadDactylizerJson"
				>
					Download JSON
				</v-btn>
				<v-btn
					v-if="showDataPointSelectionButton()"
					class="mr-3 bg-grey"
					elevation="2"
					@click="selectDataPoint"
				>
					{{ fixDataLabel() }}
				</v-btn>
				<v-btn
					class="bg-grey"
					elevation="2"
					@click="closeValidationDialog"
				>
					Ok
				</v-btn>
				<v-spacer />
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import DataEntryValidationTable from "@/pages/DataEntry/DataEntryValidationTable";

export default {
	name: "DataEntryValidationDialog",

	components: { DataEntryValidationTable },
	props: {
		modelValue: Boolean,
		validationData: { type: Object, default: () => null },
		// eslint-disable-next-line vue/require-prop-types
		moduleWindow: {
			required: true,
		},
	},
	emits: ["update:modelValue"],

	computed: {
		showDialog: {
			get() {
				return this.modelValue;
			},
			set(newValue) {
				this.$emit("update:modelValue", newValue);
			},
		},
		isValid() {
			return (
				this.validationData && this.validationData.status === "success"
			);
		},
		validationMessage() {
			if (this.validationData && this.validationData.message) {
				return this.validationData.message;
			}

			return "";
		},
		supportedDataTypes() {
			return ["image", "branch", "text", "table"];
		},
	},

	methods: {
		fixDataLabel() {
			switch (this.validationData?.data?.type) {
				case "branch":
					return "Add study branch";
				case "table":
					return "Edit table";
				default:
					return "View data point";
			}
		},
		showDataPointSelectionButton() {
			return (
				!this.isValid &&
				this.supportedDataTypes.includes(
					this.validationData.data.type
				) &&
				this.validationData.data.x &&
				this.validationData.data.y
			);
		},
		closeValidationDialog() {
			this.showDialog = false;
		},
		downloadDactylizerJson() {
			const data = this.validationData.data;
			const blob = new Blob([JSON.stringify(data, null, 2)], {
				type: "application/json",
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.download = data.data.paper
				? data.data.paper.name
				: "Unknown paper";
			document.body.appendChild(link);

			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		},
		selectDataPoint() {
			const data = this.validationData.data;
			this.moduleWindow.document.dispatchEvent(
				new CustomEvent("select-data-point", {
					detail: {
						axesName: data.axesName,
						datasetName: data.datasetName,
						file: data.file,
						page: data.page,
						x: data.x,
						y: data.y,
						type: data.type,
					},
				})
			);

			this.showDialog = false;
		},
	},
};
</script>

<style scoped lang="scss">
.validation-header-text {
	font-size: 1.1em;
	color: rgba(0, 0, 0, 0.87) !important;
}
</style>
