<template>
	<v-data-table
		:headers="headers"
		:items="displayTagGroups"
		:row-props="getRowProps"
		:sort-by="[name]"
		:items-length="totalTagGroups"
		:items-per-page="-1"
		hide-default-footer
		disable-pagination
	>
		<template #top>
			<v-toolbar
				class="bg-white"
				flat
			>
				<v-toolbar-title class="tag-group-toolbar-title">
					{{ name }}
				</v-toolbar-title>

				<v-divider
					class="mx-4"
					inset
					vertical
				/>

				<div>{{ description }}</div>

				<v-spacer />

				<v-btn
					class="mr-3 bg-grey text-primary"
					elevation="2"
					@click="displayDeleted = !displayDeleted"
				>
					<template v-if="!displayDeleted">
						<v-icon start> mdi-eye </v-icon>

						Show deleted
					</template>

					<template v-else>
						<v-icon start> mdi-eye-off </v-icon>

						Hide deleted
					</template>
				</v-btn>

				<v-dialog
					v-model="dialog"
					max-width="500px"
					transition="fade-transition"
				>
					<template #activator="{ props }">
						<v-btn
							v-bind="props"
							class="mr-3 bg-secondary text-white"
							elevation="2"
						>
							<v-icon start> mdi-plus </v-icon>

							New Tag Group
						</v-btn>
					</template>

					<tag-group-edit
						v-bind="editedItem"
						:base-tags="baseTags"
						:form-title="formTitle"
						:level="level"
						@dialog-save="save"
						@dialog-close="close"
					/>
				</v-dialog>

				<v-dialog
					v-model="dialogDelete"
					max-width="500px"
					transition="fade-transition"
				>
					<tag-group-delete
						:name="editedItem.name"
						:level="level"
						@dialog-delete="deleteItemConfirm"
						@dialog-close="closeDelete"
					/>
				</v-dialog>
			</v-toolbar>
		</template>

		<template #item.tags="{ item }">
			<div class="d-flex flex-wrap my-2">
				<v-chip
					v-for="tag in item.tags"
					:key="tag.uuid"
					size="small"
				>
					{{ tag.name }}
				</v-chip>
			</div>
		</template>

		<template #item.actions="{ item }">
			<template v-if="!item.deleted_at">
				<v-icon
					class="mr-1"
					@click="editItem(item)"
				>
					mdi-pencil
				</v-icon>

				<v-icon
					class="mr-1"
					@click="deleteItem(item)"
				>
					mdi-delete
				</v-icon>

				<v-icon
					v-if="level > 1"
					class="mr-1"
					@click="levelDown(item)"
				>
					mdi-chevron-double-down
				</v-icon>

				<v-icon
					v-if="level < 3"
					class="mr-1"
					@click="levelUp(item)"
				>
					mdi-chevron-double-up
				</v-icon>
			</template>

			<v-icon
				v-if="item.deleted_at"
				@click="restore(item)"
			>
				mdi-restore
			</v-icon>
		</template>

		<template #no-data />
	</v-data-table>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";

import TagGroupDelete from "@/pages/TagGroupManager/TagGroupDelete";
import TagGroupEdit from "@/pages/TagGroupManager/TagGroupEdit";

export default {
	name: "TagGroupList",

	components: {
		TagGroupDelete,
		TagGroupEdit,
	},

	props: {
		baseTags: { type: Array, default: () => [] },
		description: { type: String, default: "" },
		level: { type: Number, default: 0 },
		name: { type: String, default: "" },
		tagGroups: { type: Array, default: () => [] },
	},
	emits: ["refresh"],

	data() {
		return {
			dialog: false,
			dialogDelete: false,
			displayDeleted: false,
			displayTagGroups: [],
			editedIndex: -1,
			// default empty item
			defaultItem: {
				description: "",
				level: "",
				name: "",
				tags: [],
				uuid: "",
			},
			// edited item defaults to the default item
			editedItem: {
				description: "",
				level: "",
				name: "",
				tags: [],
				uuid: "",
			},
			headers: [
				{
					title: "Tag group name",
					value: "name",
					align: "start",
					sortable: true,
					width: "250px",
				},
				{
					title: "Description",
					value: "description",
					align: "start",
					sortable: true,
					width: "350px",
				},
				{
					title: "Tags",
					value: "tags",
					align: "start",
					sortable: false,
				},
				{
					title: "Actions",
					value: "actions",
					align: "center",
					sortable: false,
					width: "150px",
				},
			],
			totalTagGroups: 0,
		};
	},

	computed: {
		formTitle() {
			return this.editedIndex === -1 ? "New tag group" : "Edit tag group";
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
		displayDeleted() {
			this.filterDeleted();
		},
		tagGroups() {
			this.filterDeleted();
		},
	},

	mounted() {
		this.filterDeleted();
	},

	methods: {
		...mapMutations(["showNotification"]),
		editItem(item) {
			// store the current item index
			this.editedIndex = this.tagGroups.indexOf(item);

			// deep clone the item
			this.editedItem = _.cloneDeep(item);

			// activate the add/edit dialog
			this.dialog = true;
		},
		deleteItem(item) {
			// store the current item index
			this.editedIndex = this.tagGroups.indexOf(item);

			// deep clone the item
			this.editedItem = _.cloneDeep(item);

			// activate the delete dialog
			this.dialogDelete = true;
		},
		deleteItemConfirm() {
			this.$http
				.delete(`tag-groups/${this.editedItem.uuid}`)
				.then((response) => {
					// display success
					this.showNotification(response.data);

					// close the delete dialog
					this.closeDelete();
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.refreshTagGroups();
				});
		},
		close() {
			// close the add/edit dialog
			this.dialog = false;

			// skip rendering the reset, reset the edit pointers
			setTimeout(() => {
				this.resetEdited();
			}, 250);
		},
		closeDelete() {
			// close the delete dialog
			this.dialogDelete = false;

			// skip rendering the reset, reset the edit pointers
			setTimeout(() => {
				this.resetEdited();
			}, 250);
		},
		save(tagGroup) {
			if (tagGroup.uuid) {
				this.update(tagGroup);
			} else {
				this.create(tagGroup);
			}
		},
		update(tagGroup) {
			this.$http
				.put(`/tag-groups/${tagGroup.uuid}`, {
					description: tagGroup.description.trim(),
					level: tagGroup.level,
					name: tagGroup.name.trim(),
					tags: tagGroup.tags.map((tag) => tag.uuid),
				})
				.then((response) => {
					// display success
					this.showNotification(response.data);

					// close the dialog
					this.close();
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.refreshTagGroups();
				});
		},
		create(tagGroup) {
			this.$http
				.post("/tag-groups", {
					description: tagGroup.description.trim(),
					level: tagGroup.level,
					name: tagGroup.name.trim(),
					tags: tagGroup.tags.map((tag) => tag.uuid),
				})
				.then((response) => {
					// display success
					this.showNotification(response.data);

					// close the dialog
					this.close();
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.refreshTagGroups();
				});
		},
		restore(item) {
			this.$http
				.put(`/tag-groups/${item.uuid}`, {
					undelete: true,
				})
				.then((response) => {
					// display success
					this.showNotification(response.data);
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.refreshTagGroups();
				});
		},
		levelDown(item) {
			this.changeLevel(item, "down");
		},
		levelUp(item) {
			this.changeLevel(item, "up");
		},
		changeLevel(item, direction) {
			const newLevel = {
				down: (level) => level - 1,
				up: (level) => level + 1,
			};

			this.$http
				.put(`/tag-groups/${item.uuid}`, {
					level: newLevel[direction](parseInt(item.level, 10)),
				})
				.then((response) => {
					// display success
					this.showNotification(response.data);
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.refreshTagGroups();
				});
		},
		resetEdited() {
			// reset edited item to default item and edited index to -1
			this.editedItem = _.cloneDeep(this.defaultItem);
			this.editedIndex = -1;
		},
		filterDeleted() {
			if (this.displayDeleted) {
				this.displayTagGroups = this.tagGroups;
			} else {
				this.displayTagGroups = this.tagGroups?.filter(
					(tagGroup) => !tagGroup.deleted_at
				);
			}

			this.totalTagGroups = this.displayTagGroups.length;
		},
		getRowProps(tagGroup) {
			return {
				class: this.isDeleted(tagGroup),
			};
		},
		isDeleted(tagGroup) {
			return tagGroup.deleted_at ? "deleted" : "";
		},
		refreshTagGroups() {
			this.$emit("refresh");
		},
	},
};
</script>

<style scoped lang="scss">
.tag-group-toolbar-title {
	flex: none;
}
</style>
