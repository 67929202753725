/**
 * Initializes WPD Page Manager.
 */
function init(wpd, wpdDocument, dataEntryVM) {
	const originalRenderPage = wpd.PageManager.prototype.renderPage;
	const originalNext = wpd.PageManager.prototype.next;
	const originalPrevious = wpd.PageManager.prototype.previous;

	wpd.PageManager.prototype.next = function () {
		const self = this;
		originalNext.call(self);

		if (dataEntryVM.reworkStudyTree) {
			wpd.tagGroups.refreshAll(true, true);
		} else {
			wpd.tagGroups.refreshAll();
		}
	};

	wpd.PageManager.prototype.previous = function () {
		const self = this;
		originalPrevious.call(self);
		if (dataEntryVM.reworkStudyTree) {
			wpd.tagGroups.refreshAll(true, true);
		} else {
			wpd.tagGroups.refreshAll();
		}
	};

	// select text extraction item after switching files (for subsequent loads)
	wpd.PageManager.prototype.renderPage = async function (
		pageNumber,
		isQualityChange
	) {
		const self = this;

		// update previous and next buttons
		this.updateButtons(pageNumber);

		return new Promise((resolve) => {
			originalRenderPage.call(self, pageNumber).then(async () => {
				// reset axis tree buttons
				wpdDocument
					.getElementById("graph-add-button")
					?.classList.remove("pressed-button");
				wpdDocument
					.getElementById("table-add-button")
					?.classList.remove("pressed-button");

				if (!isQualityChange) {
					await wpd.tree.selectPath(null);
				}

				resolve();
			});
		});
	};

	// add current page loading logic
	wpd.PageManager.prototype.loadPageData = function (data) {
		this.axesByPage = data.axes || {};
		this.datasetsByPage = data.datasets || {};
		this.measurementsByPage = data.measurements || {};
		this.customLabelsByPage = data.pageLabels || {};
		this.rotationsByPage = data.rotations || {};
		this.curPage = data.currentPage ?? 1;

		// set graphics widget rotation if the loaded page has rotation data
		if (Object.keys(this.rotationsByPage).length) {
			wpd.graphicsWidget.setRotation(this.getRotation());
			wpd.graphicsWidget.rotateAndResize();
		}
	};

	// disable previous and next buttons when they will be out of bounds
	wpd.PageManager.prototype.switch = async function (pageNumber = 1) {
		wpd.busyNote.show();

		const parsedPageNumber = parseInt(pageNumber, 10);

		// update previous and next buttons
		this.updateButtons(parsedPageNumber);

		this.curPage = parsedPageNumber;

		// udpate select value for calls from other controls
		this.$pageSelector.value = parsedPageNumber;

		this._resetRelabelPopup();

		// clear tag group ui elements
		wpd.tagGroups.hideControls();

		// unselect all data points
		wpd.tree.getActiveDataset()?.unselectAll();

		return await this.renderPage(parsedPageNumber);
	};

	wpd.PageManager.prototype.updateButtons = function (pageNumber) {
		// short-circuit if the given page number is out of bounds
		if (!this._validatePageNumber(pageNumber)) {
			wpd.busyNote.close();
			wpd.messagePopup.show("Error", "Invalid page number.");
			return false;
		}

		const previousButton = wpdDocument.getElementById(
			"previous-page-button"
		);
		const nextButton = wpdDocument.getElementById("next-page-button");

		// reset previous and next buttons
		previousButton.disabled = false;
		previousButton.classList.remove("disabled");
		nextButton.disabled = false;
		nextButton.classList.remove("disabled");

		switch (true) {
			case !this._validatePageNumber(pageNumber - 1):
				// previous page is out of bounds
				previousButton.disabled = true;
				previousButton.classList.add("disabled");
				break;
			case !this._validatePageNumber(pageNumber + 1):
				// next page is out of bounds
				nextButton.disabled = true;
				nextButton.classList.add("disabled");
				break;
		}
	};
}

export { init };
