<template>
	<v-card
		class="d-flex flex-column pb-5"
		:disabled="!!loading"
		:loading="loading"
	>
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-toolbar-title v-if="editMode">
				{{ editTitle }}
			</v-toolbar-title>

			<v-toolbar-title v-else>
				{{ title }}
			</v-toolbar-title>

			<v-hover-icon class="mr-3" />
		</v-toolbar>

		<v-form @submit.prevent>
			<v-card-text class="pa-10">
				<v-row class="pb-2">
					<v-text-field
						v-model="firstName"
						:error-messages="firstNameErrors"
						:disabled="!!loading"
						label="First name"
						name="firstName"
						type="text"
						class="col-6"
						autocomplete="off"
						required
						@update:model-value="v$.firstName.$touch()"
					/>
				</v-row>
				<v-row class="pb-2">
					<v-text-field
						v-model="lastName"
						:error-messages="lastNameErrors"
						:disabled="!!loading"
						label="Last name"
						name="lastName"
						type="text"
						class="col-6"
						autocomplete="off"
						required
						@update:model-value="v$.lastName.$touch()"
					/>
				</v-row>

				<v-row class="pb-2">
					<v-text-field
						v-model.trim="email"
						:error-messages="emailErrors"
						:disabled="!!loading"
						label="Email address"
						name="email"
						type="text"
						class="col-12"
						autocomplete="off"
						required
						@blur="v$.email.$touch()"
					/>
				</v-row>

				<v-row class="pb-2">
					<password-input
						ref="password"
						v-model="password"
						v-model:password-valid="isPasswordValid"
						v-model:password-dirty="isPasswordDirty"
						:loading="!!loading"
						label="New password"
						input-class="col-12"
						meter-size="large"
						:required="!editMode"
					/>
				</v-row>

				<v-row>
					<v-col>
						<role-search
							v-model="selectedRoles"
							v-model:roles-loading="rolesLoading"
							:disabled="!!loading"
							label="Add roles"
							@role-updated="handleRoleUpdate"
						/>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<organization-search
							v-model="selectedOrganizations"
							v-model:organizations-loading="organizationsLoading"
							v-model:organizations-missing="organizationsMissing"
							:disabled="!!loading"
							label="Add organizations"
							@organization-updated="handleOrganizationUpdate"
						/>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-actions>
				<v-spacer />

				<v-btn
					v-if="editMode"
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="
						(!v$.$anyDirty && !isPasswordDirty) ||
						v$.$invalid ||
						!!loading ||
						!!organizationsLoading ||
						!!organizationsMissing ||
						!!rolesLoading ||
						(password !== '' && !isPasswordValid)
					"
					@click="update"
				>
					Update
				</v-btn>

				<v-btn
					v-else
					class="bg-secondary text-white"
					elevation="2"
					type="submit"
					:disabled="
						v$.$invalid ||
						!!loading ||
						password === '' ||
						!isPasswordValid ||
						!!organizationsLoading ||
						!!organizationsMissing ||
						!!rolesLoading
					"
					@click="create"
				>
					Create
				</v-btn>

				<v-btn
					class="bg-grey"
					elevation="2"
					:disabled="!!loading"
					@click="clear"
				>
					Clear
				</v-btn>

				<v-btn
					class="bg-grey"
					elevation="2"
					:disabled="!!loading"
					@click="cancel"
				>
					Cancel
				</v-btn>

				<v-spacer />
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";
import { required, email, maxLength } from "@vuelidate/validators";
import PasswordInput from "@/components/VPasswordInput.vue";
import { useVuelidate } from "@vuelidate/core";

import RoleSearch from "@/pages/UserManager/RoleSearch";
import OrganizationSearch from "@/pages/Organizations/OrganizationSearch";
import VHoverIcon from "@/components/VHoverIcon";

export default {
	name: "UserEdit",

	components: {
		RoleSearch,
		PasswordInput,
		OrganizationSearch,
		VHoverIcon,
	},

	props: {
		editMode: { type: Boolean },
		defaultUser: {
			type: Object,
			default: () => ({}),
		},
	},
	emits: ["cancel", "refresh"],

	setup() {
		return {
			v$: useVuelidate(),
		};
	},

	data() {
		return {
			editTitle: "Edit user",
			email: this.defaultUser ? this.defaultUser.email : "",
			firstName: this.defaultUser ? this.defaultUser.first_name : "",
			lastName: this.defaultUser ? this.defaultUser.last_name : "",
			loading: true,
			organizations: [],
			organizationsLoading: true,
			organizationsMissing: true,
			password: "",
			rolesLoading: true,
			selectedOrganizations:
				this.defaultUser && this.defaultUser.organizations
					? [...this.defaultUser.organizations]
					: [],
			selectedRoles:
				this.defaultUser && this.defaultUser.roles
					? [...this.defaultUser.roles]
					: [],
			showPassword: false,
			isPasswordValid: false,
			isPasswordDirty: false,
			title: "Create user",
		};
	},

	computed: {
		firstNameErrors() {
			if (!this.v$.firstName.$dirty) {
				return [];
			}
			return this.v$.firstName.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "First name is required";
					case "maxLength":
						return "First name is too long";
					default:
						return "Invalid first name";
				}
			});
		},
		lastNameErrors() {
			if (!this.v$.lastName.$dirty) {
				return [];
			}
			return this.v$.lastName.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "Last name is required";
					case "maxLength":
						return "Last name is too long";
					default:
						return "Invalid last name";
				}
			});
		},
		emailErrors() {
			if (!this.v$.email.$dirty) {
				return [];
			}
			return this.v$.email.$errors.map((error) => {
				switch (error.$validator) {
					case "required":
						return "Email is required";
					case "email":
						return "Must be a valid email address";
					case "maxLength":
						return "Email is too long";
					default:
						return "Invalid email";
				}
			});
		},
	},

	watch: {
		defaultUser() {
			this.loadUser();
		},
	},

	mounted() {
		this.loadUser();
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			if (_.isEmpty(this.defaultUser)) {
				this.firstName = "";
				this.lastName = "";
				this.email = "";
				this.password = "";

				this.$refs.password.clearPassword();

				this.selectedRoles = [];
				this.selectedOrganizations = [];

				// clear dirty flags
				this.v$.$reset();
			} else {
				this.loadUser();
			}
		},
		cancel() {
			this.$emit("cancel");
		},
		update() {
			if (!this.v$.$invalid) {
				this.loading = "secondary";
				this.$http
					.put(`/users/${this.defaultUser.uuid}`, {
						first_name: this.firstName.trim(),
						last_name: this.lastName.trim(),
						email: this.email,
						roles: this.selectedRoles,
						organizations: this.selectedOrganizations,
						...(this.password ? { password: this.password } : {}),
					})
					.catch((error) => {
						// set dirty flags
						this.v$.$touch();

						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}
					})
					.finally(() => {
						this.loading = false;

						// update users table
						this.$emit("refresh");
					});
			} else {
				// set all to dirty
				this.v$.$touch();
			}
		},
		create: function () {
			if (!this.v$.$invalid) {
				this.loading = "secondary";

				this.$http
					.post("/users", {
						first_name: this.firstName.trim(),
						last_name: this.lastName.trim(),
						email: this.email,
						password: this.password,
						roles: this.selectedRoles,
						organizations: this.selectedOrganizations,
					})
					.catch((error) => {
						// set dirty flags
						this.v$.$touch();

						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}
					})
					.finally(() => {
						this.loading = false;

						// update users table
						this.$emit("refresh");
					});
			} else {
				// set all to dirty
				this.v$.$touch();
			}
		},
		loadUser() {
			if (!_.isEmpty(this.defaultUser)) {
				this.firstName = this.defaultUser.first_name;
				this.lastName = this.defaultUser.last_name;
				this.email = this.defaultUser.email;
				this.uuid = this.defaultUser.uuid;

				this.selectedRoles = [...this.defaultUser.roles];
				this.selectedOrganizations = [
					...this.defaultUser.organizations,
				];

				this.isPasswordValid = true;
				this.v$.$reset();
			} else {
				this.clear();
			}

			this.loading = false;
		},
		handleOrganizationUpdate() {
			this.v$.selectedOrganizations.$touch();
		},
		handleRoleUpdate() {
			this.v$.selectedRoles.$touch();
		},
	},
	validations: {
		email: {
			required,
			email,
			maxLength: maxLength(254),
		},
		firstName: {
			required,
			maxLength: maxLength(40),
		},
		lastName: {
			required,
			maxLength: maxLength(40),
		},
		password: {},
		selectedOrganizations: {},
		selectedRoles: {},
	},
};
</script>

<style scoped lang="scss"></style>
