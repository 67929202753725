<template>
	<v-dialog
		ref="dialog"
		v-model="dialog"
		attach="#data-entry"
		:max-width="width"
		:persistent="preventClosing"
		:content-class="className"
		eager
		transition="fade-transition"
		@keydown="dialogKeydownHandler"
		@click:outside="focusDialog"
	>
		<v-card
			class="metadata-dialog-content"
			border
		>
			<v-container class="px-3 pt-3 pb-1">
				<v-row
					class="tag-toggle mx-n2 mt-n3"
					no-gutters
					justify="space-between"
				>
					<v-col>
						<v-hover v-slot="{ hover }">
							<v-icon
								class="metadata-dialog-button drag-handle"
								:color="hover ? 'secondary' : ''"
								size="small"
							>
								mdi-drag-horizontal
							</v-icon>
						</v-hover>

						<span
							v-if="multiple"
							class="text-caption text-grey-darken-1"
						>
							Editing multiple
						</span>
					</v-col>

					<div>
						<v-hover v-slot="{ hover }">
							<v-icon
								v-if="expanded"
								size="x-small"
								class="metadata-dialog-button mr-1"
								:color="hover ? 'primary' : ''"
								@click="collapseDialog"
							>
								mdi-unfold-less-vertical
							</v-icon>

							<v-icon
								v-else
								size="x-small"
								class="metadata-dialog-button mr-1"
								:color="hover ? 'primary' : ''"
								@click="expandDialog"
							>
								mdi-unfold-more-vertical
							</v-icon>
						</v-hover>

						<v-hover v-slot="{ hover }">
							<v-icon
								size="x-small"
								:color="hover ? 'primary' : ''"
								@click="toggleUserManual"
							>
								mdi-help-circle-outline
							</v-icon>
						</v-hover>
					</div>
				</v-row>

				<v-row
					v-for="(metadatum, index) in metadata"
					:key="index"
					justify="start"
					:class="{
						'dactyl-metadata-row': true,
						'pa-0': true,
						'mx-0': true,
						'my-1': !isHiddenMetadataRow(metadatum),
					}"
				>
					<v-col
						v-if="!isHiddenMetadataRow(metadatum)"
						:cols="expanded ? '6' : '10'"
						class="pl-0 pr-2 py-0"
					>
						<v-combobox
							v-if="!showKeyPicker(metadatum?.tag)"
							v-model="metadatum.value"
							v-model:search="valueSuggestionSearch"
							:rules="getRules(index)"
							label="Value"
							:autofocus="autoFocus(index, metadatum)"
							:items="suggestions"
							:index="index"
							:menu-props="{
								value: !(
									valueSuggestionSearch === '' ||
									!isNaN(valueSuggestionSearch)
								),
								right: true,
								disabled:
									!suggestions || suggestions.length === 0,
								contentProps: {
									onKeydown: (event) => {
										event.stopPropagation();
									},
								},
							}"
							:custom-filter="filterSuggestions"
							:auto-select-first="false"
							:disabled="blankTableCell"
							autocomplete="off"
							hide-details="auto"
							hide-no-data
							single-line
							color="secondaryDark"
							density="compact"
							:append-icon="
								pdfCopyPasteFlag && selectTextFunction
									? 'mdi-form-textbox'
									: ''
							"
							@click:append="selectText($event, index)"
						>
							<template
								v-if="suggestions && suggestions.length > 0"
								#prepend-item
							>
								<v-list-subheader>
									Is this the same as:
								</v-list-subheader>
							</template>
						</v-combobox>
						<v-autocomplete
							v-if="showKeyPicker(metadatum?.tag)"
							ref="keyInput"
							v-model="metadatum.value"
							:items="filterCompositeKeys(metadatum)"
							item-title="name"
							:autofocus="autoFocus(index, metadatum)"
							:custom-filter="searchCompositeKeys"
							:menu-props="{
								offsetY: true,
								closeOnClick: false,
								contentClass:
									'composite-key-selector-list-menu',
								contentProps: {
									onKeydown: (event) => {
										event.stopPropagation();
									},
								},
							}"
							item-value="key"
							single-line
							density="compact"
							:label="keyPickerLabel(metadatum)"
							hide-details="auto"
							@keydown="keyInputKeydown($event, metadatum)"
						>
							<template #item="{ item, props }">
								<v-list-item
									v-bind="props"
									class="composite-key-selector-title-container"
								>
									<template #title>
										<div
											class="composite-key-selector-list-item"
										>
											<span
												class="composite-key-selector-key"
											>
												{{ item.title }}
											</span>
											<div
												v-if="item.raw.study"
												class="composite-key-selector-path-container"
											>
												<span
													class="composite-key-selector-path"
												>
													{{ item.raw.study }}
												</span>
												<span
													v-if="item.raw.studyPart"
													class="composite-key-selector-path"
												>
													{{ item.raw.studyPart }}
												</span>
												<span
													v-if="item.raw.studyArm"
													class="composite-key-selector-path"
												>
													{{ item.raw.studyArm }}
												</span>
												<span
													v-if="item.raw.population"
													class="composite-key-selector-path"
												>
													{{ item.raw.population }}
												</span>
												<span
													v-if="item.raw.subgroup"
													class="composite-key-selector-path"
												>
													{{ item.raw.subgroup }}
												</span>
											</div>
										</div>
									</template>
								</v-list-item>
							</template>
							<template #selection="{ item }">
								<div
									class="v-select__selection composite-key-selector-selected"
								>
									<span class="composite-key-selector-key">
										{{ item.title }}
									</span>
								</div>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col
						v-if="!isHiddenMetadataRow(metadatum) && expanded"
						cols="5"
						class="pl-0 pr-2 py-0 tag"
					>
						<v-autocomplete
							ref="tagInput"
							v-model="metadatum.tag"
							v-model:search="tagInputSearch"
							:items="getFilteredTags(index) || []"
							:menu-props="{
								offsetY: true,
								closeOnClick: true,
								activator: 'parent',
								contentProps: {
									onKeydown: (event) => {
										event.stopPropagation();
									},
								},
							}"
							:disabled="blankTableCell || abbreviationOnly"
							item-title="name"
							item-value="uuid"
							no-data-text="No tags available"
							:placeholder="getTagInputLabel()"
							hide-details="auto"
							single-line
							density="compact"
							return-object
							:index="index"
							persistent-placeholder
							class="tag-input-autocomplete"
							@keydown="tagInputKeydown($event, metadatum)"
							@update:model-value="selectTag(metadatum)"
						>
							<template #item="{ props }">
								<v-list-item
									class="wpd-metadata-tag-input-autocomplete-list-item"
									v-bind="props"
								/>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col
						v-if="!isHiddenMetadataRow(metadatum)"
						:cols="expanded ? 1 : 2"
						class="px-0 pt-2 metadata-dialog-button-group"
					>
						<v-tooltip
							v-if="showDeleteRowButton()"
							location="bottom"
							open-delay="500"
						>
							<template #activator="{ props }">
								<v-hover v-slot="{ hover }">
									<v-icon
										v-bind="props"
										size="small"
										class="metadata-dialog-button"
										:color="hover ? 'error' : ''"
										tabindex="-1"
										:disabled="blankTableCell"
										@click="deleteRow(index)"
									>
										mdi-delete-outline
									</v-icon>
								</v-hover>
							</template>

							<span>Shift ⇧ + Backspace ←</span>
						</v-tooltip>

						<v-tooltip
							v-if="showAddRowButton(metadatum)"
							location="bottom"
							open-delay="500"
						>
							<template #activator="{ props }">
								<v-hover v-slot="{ hover }">
									<v-icon
										v-bind="props"
										size="small"
										class="metadata-dialog-button"
										:color="hover ? 'primary' : ''"
										tabindex="-1"
										:disabled="blankTableCell"
										@click="addRow()"
									>
										mdi-plus
									</v-icon>
								</v-hover>
							</template>

							<span>Shift ⇧ + Enter ↵</span>
						</v-tooltip>

						<v-spacer v-else />
					</v-col>
				</v-row>
				<v-row
					v-if="tableMode"
					class="blank-cell-row mb-2 ml-n1"
					no-gutters
					dense
				>
					<v-col>
						<v-checkbox
							v-model="blankTableCell"
							label="Blank table cell"
							class="mt-0"
							density="compact"
							hide-details
							@update:model-value="validateValues"
						/>
					</v-col>
				</v-row>

				<div
					v-if="displayHint"
					class="text-caption text-grey-darken-1 mt-3 mb-n3"
				>
					Press Esc or click outside to dismiss
				</div>
			</v-container>
			<v-container
				v-if="axisType === 'tableHeader' && !hasInputMetadata()"
				class="table-header-warning-container"
			>
				Warning:
				<ul class="ml-5">
					<li>
						Leaving row and column table headers blank will result
						in blank table cells
					</li>
					<li>
						Each table cell that contains data must reference
						<i>exactly</i> one study branch in either its row or
						column header
					</li>
				</ul>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { mapMutations } from "vuex";
import { useLDFlag } from "launchdarkly-vue-client-sdk";

import utils from "@/utils";
import createCompositeKeyUtils from "@/compositeKeyUtils";

export default {
	name: "VMetadata",

	props: {
		bounds: { type: Object, default: null },
		tags: { type: Array, default: () => [] },
		tagGroups: { type: Array, default: () => [] },
	},

	setup() {
		const pdfCopyPasteFlag = useLDFlag("pdf-copy-paste");

		return {
			pdfCopyPasteFlag,
		};
	},

	data() {
		return {
			abbreviationOnly: false,
			allowEmpty: false,
			arrow: null,
			arrowPadding: 8,
			arrowSize: 8,
			axisType: null,
			blankTableCell: false,
			branchSelection: false,
			calculatedValues: [],
			className: "metadata-dialog",
			compositeKeys: [],
			container: null,
			currentCompositeKey: null,
			defaultTags: null,
			dialog: false,
			displayHint: false,
			element: null, // the floating element, not the anchor
			emptyOnExit: false,
			excludeTags: [],
			excludeKeys: [
				"uuid",
				"tagGroup",
				"type",
				"compositeKey",
				"referenceCompositeKey",
			],
			expanded: true,
			filteredTags: [],
			forceAxis: null,
			forceDirection: null,
			height: 64,
			// index: null,
			initialCompositeKey: null,
			largeWidth: 550,
			metadata: [],
			multiple: false,
			offset: 0,
			position: {},
			positionless: false,
			preventAutocompleteFocus: false,
			preventClosing: false,
			reject: null,
			resolve: null,
			rowIndex: null,
			valueSuggestionSearch: "",
			selectTextFunction: null,
			smallWidth: 300,
			suggestions: [],
			tableMode: false,
			tagGroup: null,
			indexTagMap: {},
			tagInputSearch: "",
			studyArmAbbreviations: [],
		};
	},

	computed: {
		userDialogExpanded() {
			return this.$store.getters.dialogExpanded;
		},
		fetchMetadata() {
			return this.$store.getters.fetchMetadata;
		},
		width() {
			return this.expanded ? this.largeWidth : this.smallWidth;
		},
		help() {
			return this.$store.getters.help;
		},
	},

	watch: {
		dialog(val) {
			if (!val) {
				this.$nextTick(function () {
					// resolve with metadata
					if (this.resolve) {
						this.resolve(this.exportMetadata());
						this.clear();
					}

					// hide arrow
					if (this.arrow) {
						this.arrow.className = "";
					}

					// clear in-progress metadata cache
					this.clearMetadataCache();
				});
			}
		},
		expanded() {
			if (!_.isEmpty(this.position)) {
				this.refreshPosition(this.position);
			}
		},
	},

	mounted() {
		// default dialog to expanded in the store as well
		this.setDialogExpanded(true);
	},

	methods: {
		...mapMutations([
			"setHelp",
			"clearHelp",
			"receiveFetchMetadata",
			"setMetadataCache",
			"clearMetadataCache",
			"setDialogExpanded",
		]),
		clear: function () {
			this.abbreviationOnly = false;
			this.branchSelection = false;
			this.allowEmpty = false;
			this.arrowPadding = 8;
			this.axisType = null;
			this.calculatedValues = [];
			this.compositeKeys = [];
			this.container = null;
			this.currentCompositeKey = null;
			this.suggestions = [];
			this.defaultTags = null;
			this.displayHint = false;
			this.emptyOnExit = false;
			this.excludeTags = [];
			this.expanded = true;
			this.forceDirection = null;
			this.height = 64;
			this.initialCompositeKey = null;
			this.metadata = [];
			this.multiple = false;
			this.position = {};
			this.positionless = false;
			this.preventClosing = false;
			this.reject = null;
			this.resolve = null;
			this.rowIndex = null;
			this.tableMode = false;
			this.tagGroup = null;
			this.indexTagMap = {};
			this.selectTextFunction = null;
			this.studyArmAbbreviations = [];
			this.validateAbbreviations = false;
		},
		getCompositeKeyDisplayData(item, metadatum, index) {
			let name = "";
			let study = "";
			let studyPart = "";
			let studyArm = "";
			let population = "";

			const tagName = metadatum.tag ? metadatum.tag.name : "";
			switch (tagName) {
				case this.compositeKeyUtils.studyTag:
					name = item.study;
					break;
				case this.compositeKeyUtils.studyPartTag:
					name = item.studyPart;
					study = item.study;
					break;
				case this.compositeKeyUtils.studyArmTag:
					name = item.studyArm;
					study = item.study;
					studyPart = item.studyPart;
					break;
				case this.compositeKeyUtils.populationTag:
					name = item.population;
					study = item.study;
					studyPart = item.studyPart;
					studyArm = item.studyArm;
					break;
				case this.compositeKeyUtils.subgroupTag:
					name = item.subgroup;
					study = item.study;
					studyPart = item.studyPart;
					studyArm = item.studyArm;
					population = item.population;
					break;
				case "Reference study arm abbreviation":
					if (item.subgroup) {
						name = item.subgroup;
						study = item.study;
						studyPart = item.studyPart;
						studyArm = item.studyArm;
						population = item.population;
					} else if (item.population) {
						name = item.population;
						study = item.study;
						studyPart = item.studyPart;
						studyArm = item.studyArm;
					} else if (item.studyArm) {
						name = item.studyArm;
						study = item.study;
						studyPart = item.studyPart;
					} else if (item.studyPart) {
						name = item.studyPart;
						study = item.study;
					} else {
						name = item.study;
					}
					break;
				default:
					if (item.subgroup) {
						name = item.subgroup;
						study = item.study;
						studyPart = item.studyPart;
						studyArm = item.studyArm;
						population = item.population;
					} else if (item.population) {
						name = item.population;
						study = item.study;
						studyPart = item.studyPart;
						studyArm = item.studyArm;
					} else if (item.studyArm) {
						name = item.studyArm;
						study = item.study;
						studyPart = item.studyPart;
					} else if (item.studyPart) {
						name = item.studyPart;
						study = item.study;
					} else {
						name = item.study;
					}
					break;
			}
			return {
				index,
				key: item.key,
				name,
				study,
				studyPart,
				studyArm,
				population,
			};
		},
		isKeyTag(tag) {
			return this.compositeKeyUtils.isBranchTag(tag?.name);
		},
		isReferenceKeyTag(tag) {
			return this.compositeKeyUtils.isReferenceTag(tag?.name);
		},
		showKeyPicker(tag) {
			return (
				this.branchSelection ||
				(!this.abbreviationOnly &&
					(this.isKeyTag(tag) || this.isReferenceKeyTag(tag)))
			);
		},
		showAddRowButton(metadatum) {
			if (this.abbreviationOnly) return false;

			const maxVisibleIndex = this.metadata
				.filter((data) => !isNaN(data.index) && !data.hidden)
				.map((data) => parseInt(data.index, 10))
				.reduce((max, current) => Math.max(max, current), -Infinity);
			return parseInt(metadatum.index, 10) === maxVisibleIndex;
		},
		showDeleteRowButton() {
			if (
				this.multiple &&
				this.metadata.length <= this.excludeKeys.length
			) {
				return false;
			}

			if (this.abbreviationOnly && this.initialCompositeKey) {
				return false;
			}

			return true;
		},
		keyPickerLabel(metadatum) {
			if (this.branchSelection) {
				return "Study branch";
			}

			let label;
			switch (metadatum.tag.name) {
				case this.compositeKeyUtils.studyTag:
					label = "Study";
					break;
				case this.compositeKeyUtils.studyPartTag:
					label = "Part";
					break;
				case this.compositeKeyUtils.studyArmTag:
					label = "Arm";
					break;
				case this.compositeKeyUtils.populationTag:
					label = "Population";
					break;
				case this.compositeKeyUtils.subgroupTag:
					label = "Subgroup";
					break;
				case this.compositeKeyUtils.referenceArmTag:
					label = "Reference";
					break;
			}

			return label;
		},
		searchCompositeKeys(item, queryText) {
			const key = item.toLowerCase();
			const searchText = queryText.toLowerCase();
			return key.indexOf(searchText) > -1;
		},
		filterCompositeKeys(metadatum) {
			const nonEmptyKeys = this.compositeKeys.filter((key) => key.study);
			if (this.branchSelection) {
				return nonEmptyKeys.map((key, index) =>
					this.getCompositeKeyDisplayData(key, metadatum, index)
				);
			}

			let filteredKeys = [];
			switch (metadatum.tag.name) {
				case this.compositeKeyUtils.studyTag:
					filteredKeys = nonEmptyKeys.filter(
						(key) => key.study && !key.studyPart && !key.studyArm
					);
					break;
				case this.compositeKeyUtils.studyPartTag:
					filteredKeys = nonEmptyKeys.filter(
						(key) => key.study && key.studyPart && !key.studyArm
					);
					break;
				case this.compositeKeyUtils.studyArmTag:
					filteredKeys = nonEmptyKeys.filter(
						(key) =>
							key.study &&
							key.studyArm &&
							!key.population &&
							!key.subgroup
					);
					break;
				case this.compositeKeyUtils.populationTag:
					filteredKeys = nonEmptyKeys.filter(
						(key) =>
							key.study &&
							key.studyArm &&
							key.population &&
							!key.subgroup
					);
					break;
				case this.compositeKeyUtils.subgroupTag:
					filteredKeys = nonEmptyKeys.filter(
						(key) => key.study && key.studyArm && key.subgroup
					);
					break;
				case this.compositeKeyUtils.referenceArmTag:
					filteredKeys = nonEmptyKeys;
					break;
			}

			return filteredKeys.map((key, index) =>
				this.getCompositeKeyDisplayData(key, metadatum, index)
			);
		},
		addRow: function () {
			const maxIndex = this.metadata
				.map((item) => item.index)
				.filter((index) => !isNaN(index))
				.map((index) => parseInt(index, 10))
				.reduce((max, current) => Math.max(max, current), -Infinity);

			this.metadata.push({
				index: maxIndex < 0 ? 0 : maxIndex + 1,
				tag: null,
				value: "",
			});

			this.refreshHeight();
			this.validateValues();
		},
		deleteRow: function (index) {
			if (this.abbreviationOnly) {
				this.dialog = false;
				return;
			}

			const removedTag = this.metadata.splice(index, 1)[0].index;

			// only uuid and tagGroup remain, clear and close dialog
			if (
				this.metadata.filter((md) => !md.hidden).length ===
				this.excludeKeys.length
			) {
				this.metadata = [];
				this.dialog = false;
			} else {
				this.refreshHeight();
				this.validateValues();

				// re-index untagged values
				if (!isNaN(removedTag)) {
					_.forEach(this.metadata, (metadatum) => {
						if (!isNaN(metadatum.index)) {
							if (metadatum.index > removedTag) {
								metadatum.index = String(metadatum.index - 1);
							}
						}
					});
				}
			}
		},
		refreshHeight: function () {
			this.$nextTick(function () {
				this.height = this.element.getBoundingClientRect().height;
			});
		},
		determineDirection: function (x, y) {
			let direction = "";

			switch (this.forceAxis) {
				case "x":
					direction = "east";
					if (this.bounds.right - x < this.width) {
						direction = "west";
					}
					break;
				case "y":
					// default to south
					direction = "south";
					if (this.bounds.bottom - y < this.height) {
						direction = "north";
					}
					break;
				default:
					// default to south
					direction = "south";
					if (this.bounds.bottom - y < this.height) {
						direction = "north";
					}
					if (x - this.bounds.left < this.width / 2) {
						direction = "east";
					} else if (this.bounds.right - x < this.width / 2) {
						direction = "west";
					}
					break;
			}

			return direction;
		},
		setAxisPolicy: function () {
			switch (this.axisType) {
				case "tableHeader":
					this.allowEmpty = true;
					this.arrowPadding = 0;
					this.emptyOnExit = true;
					this.validateAbbreviations = true;
					break;
				case "barGraphHeader":
					this.allowEmpty = true;
					this.arrowPadding = 0;
					this.emptyOnExit = true;
					this.validateAbbreviations = true;
					break;
				case "table":
					this.allowEmpty = true;
					this.arrowPadding = 0;
					this.tableMode = true;
					this.validateAbbreviations = true;
					break;
				case "image":
					this.allowEmpty = true;
					this.emptyOnExit = true;
					break;
				case "bar":
				case "map":
				case "polar":
				case "ternary":
				case "xy":
					this.allowEmpty = true;
					this.displayHint = true;
					break;
			}
		},
		open: function (args) {
			const {
				abbreviationOnly,
				branchSelection,
				axisType,
				blankTableCell,
				calculatedValues,
				compositeKeys,
				container,
				currentCompositeKey,
				data,
				excludeTags,
				forceAxis,
				forceDirection,
				indexes,
				offset,
				position,
				rowIndex,
				suggestions,
				defaultTags,
				tagGroup,
				studyArmAbbreviations,
				selectTextFunction,
			} = args;

			this.compositeKeyUtils = createCompositeKeyUtils(this.tags);

			const tagGroupTags = _.uniqBy(
				this.tagGroups
					.flatMap((tagGroup) => tagGroup.tags)
					.sort((a, b) => a.name.localeCompare(b.name)),
				"uuid"
			);

			this.filteredTags =
				axisType === "tableHeader" || abbreviationOnly
					? tagGroupTags
					: tagGroupTags.filter((tag) => {
							return !this.isKeyTag(tag);
					  });

			this.abbreviationOnly = abbreviationOnly;
			this.branchSelection = branchSelection;
			this.axisType = axisType;
			this.blankTableCell = blankTableCell;
			this.calculatedValues = calculatedValues;
			this.compositeKeys = compositeKeys;
			this.currentCompositeKey = currentCompositeKey;
			this.excludeTags = excludeTags ?? [];
			this.forceAxis = forceAxis;
			this.forceDirection = forceDirection;
			this.offset = offset ?? 0;
			this.expanded = this.userDialogExpanded;
			this.defaultTags = defaultTags;
			this.tagGroup = tagGroup;
			this.studyArmAbbreviations = studyArmAbbreviations;
			this.selectTextFunction = selectTextFunction;

			this.setAxisPolicy();
			this.importMetadata(data);

			// set container, indexes, and existing values for filtering
			this.container = container;
			this.indexes = indexes;
			this.rowIndex = rowIndex;
			this.suggestions = suggestions;

			// find and set element if not yet set
			if (!this.element) {
				const container = document.getElementById("data-entry");
				this.element =
					container.getElementsByClassName("metadata-dialog")[0];
			}

			// create arrow div and attach to DOM if does not exist
			if (!this.arrow) {
				this.arrow = document.createElement("div");
				this.element.parentElement.prepend(this.arrow);
			}

			this.resetPosition();

			// show the dialog
			this.dialog = true;

			if (position) {
				this.position = position;

				this.refreshHeight();
				this.refreshPosition(this.position);
			} else {
				if (this.indexes.length > 1) {
					// no position data and multiple indexes, assume multiple
					// data points edit
					this.multiple = true;
					this.positionless = true;
					this.preventClosing = false;
				} else if (this.indexes.length > 0) {
					// position-less dialog
					this.positionless = true;
					this.preventClosing = false;
				}
			}

			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		refreshPosition: function (position) {
			this.$nextTick(function () {
				// translate x and y values to viewport pixels
				const x = position.x + this.bounds.left;
				const y = position.y + this.bounds.top;

				// get viewport height to calculate bottom
				const viewportBounds =
					this.element.parentElement.getBoundingClientRect();
				const viewportMaxY = viewportBounds.height;
				const viewportMaxX = viewportBounds.width;

				const direction =
					this.forceDirection ?? this.determineDirection(x, y);

				switch (direction) {
					case "north":
						this.element.style.bottom = `${
							viewportMaxY -
							y +
							this.arrowPadding +
							this.arrowSize +
							this.offset
						}px`;
						this.element.style.left = `${x - this.width / 2}px`;

						this.arrow.style.bottom = `${
							viewportMaxY - y + this.arrowPadding + this.offset
						}px`;
						this.arrow.style.left = `${x - this.arrowSize}px`;

						this.arrow.className = "arrow-down";

						break;
					case "east":
						this.element.style.top = `${y - this.height / 2}px`;
						this.element.style.left = `${
							x + this.arrowPadding + this.arrowSize + this.offset
						}px`;

						this.arrow.style.top = `${y - this.arrowSize}px`;
						this.arrow.style.left = `${
							x + this.arrowPadding + this.offset
						}px`;

						this.arrow.className = "arrow-left";

						break;
					case "south":
						this.element.style.top = `${
							y + this.arrowPadding + this.arrowSize + this.offset
						}px`;
						this.element.style.left = `${x - this.width / 2}px`;

						this.arrow.style.top = `${
							y + this.arrowPadding + this.offset
						}px`;
						this.arrow.style.left = `${x - this.arrowSize}px`;

						this.arrow.className = "arrow-up";

						break;
					case "west":
						this.element.style.top = `${y - this.height / 2}px`;
						this.element.style.right = `${
							viewportMaxX -
							x +
							this.arrowPadding +
							this.arrowSize +
							this.offset
						}px`;

						this.arrow.style.top = `${y - this.arrowSize}px`;
						this.arrow.style.right = `${
							viewportMaxX - x + this.arrowPadding + this.offset
						}px`;

						this.arrow.className = "arrow-right";

						break;
				}
			});
		},
		resetPosition: function () {
			this.element.style.top = "auto";
			this.element.style.right = "auto";
			this.element.style.bottom = "auto";
			this.element.style.left = "auto";

			this.arrow.style.top = "auto";
			this.arrow.style.right = "auto";
			this.arrow.style.bottom = "auto";
			this.arrow.style.left = "auto";
		},
		findTag: function (uuid) {
			return this.tags.find((tag) => tag.uuid === uuid);
		},
		refreshMetadata: function () {
			if (this.dialog && _.isEmpty(this.metadata)) {
				const filteredTagUuids = this.getFilteredTags(0).map(
					(tag) => tag.uuid
				);

				const filteredDefaultTags = this.defaultTags
					? this.defaultTags.filter((tag) => {
							return filteredTagUuids.includes(tag.uuid);
					  })
					: this.defaultTags;
				if (filteredDefaultTags && filteredDefaultTags.length > 0) {
					// default tag group tags
					filteredDefaultTags.forEach(({ uuid }, index) => {
						const tag = this.findTag(uuid);
						this.metadata.push({
							index: index,
							tag,
							value: "",
						});

						this.indexTagMap[index] = tag;
					});
				} else {
					this.metadata.push({
						index: 0,
						tag: null,
						value: "",
					});
				}

				if (this.allowEmpty || this.blankTableCell) {
					this.preventClosing = false;
				} else {
					this.preventClosing = true;
				}
			}
		},
		importMetadata: function (rawData) {
			// make sure uuid is the first element
			// add a uuid if it doesn't already exist
			this.metadata.push(
				{
					index: "uuid",
					tag: null,
					value: rawData?.uuid || uuidv4(),
				},
				{
					index: "tagGroup",
					tag: null,
					value: this.tagGroup,
				},
				{
					index: "type",
					tag: null,
					value: this.axisType,
				},
				{
					index: "compositeKey",
					tag: null,
					value: rawData?.compositeKey,
				},
				{
					index: "referenceCompositeKey",
					tag: null,
					value: rawData?.referenceCompositeKey,
				}
			);

			if (_.isEmpty(rawData)) {
				const filteredTagUuids = this.getFilteredTags(0).map(
					(tag) => tag.uuid
				);
				const filteredDefaultTags = this.defaultTags
					? this.defaultTags.filter((tag) => {
							return filteredTagUuids.includes(tag.uuid);
					  })
					: this.defaultTags;
				if (filteredDefaultTags && filteredDefaultTags.length > 0) {
					// default tag group tags
					filteredDefaultTags.forEach(({ uuid }, index) => {
						const tag = this.findTag(uuid);
						this.metadata.push({
							index: index,
							tag: tag,
							value: "",
						});

						this.indexTagMap[index] = tag;
					});
				} else {
					this.metadata.push({
						index: 0,
						tag: null,
						value: "",
					});
				}

				if (this.allowEmpty || this.blankTableCell) {
					this.preventClosing = false;
				} else {
					this.preventClosing = true;
				}
			} else {
				// set label if necessary
				if (rawData.label) {
					if (_.isObject(rawData.label)) {
						this.metadata.push({
							index: "label",
							tag: rawData.label.tag,
							value: rawData.label.value,
						});
					} else {
						this.metadata.push({
							index: "label",
							tag: null,
							value: rawData.label,
						});
					}
				}

				_.forEach(
					_.omit(rawData, [...this.excludeKeys, "label"]),
					(data, index) => {
						const value =
							data.isCompositeKey && !data.hidden
								? rawData.compositeKey
								: data.value ?? "";

						const tag = data.tag ?? this.findTag(data.tagUUID);
						this.metadata.push({
							index: index,
							tag,
							value,
							axis: data.axis ?? undefined,
							hidden: data.hidden,
							headerTag: data.headerTag,
						});

						this.indexTagMap[index] = tag;
					}
				);

				// get all indexes from the metadata array
				const metadataIndexes = this.metadata.map(
					(metadatum) => metadatum.index
				);

				// if there aren't metadata other than uuid,
				// tagGroup, or label, add a blank entry
				if (!metadataIndexes.some(utils.isNumber)) {
					const filteredTagUuids = this.getFilteredTags(0).map(
						(tag) => tag.uuid
					);
					const filteredDefaultTags = this.defaultTags
						? this.defaultTags.filter((tag) => {
								return filteredTagUuids.includes(tag.uuid);
						  })
						: this.defaultTags;
					if (filteredDefaultTags && filteredDefaultTags.length > 0) {
						// default tag group tags
						filteredDefaultTags.forEach(({ uuid }, index) => {
							this.metadata.push({
								index: index,
								tag: this.findTag(uuid),
								value: this.calculatedValues[index] || "",
							});
						});
					} else {
						this.metadata.push({
							index: 0,
							tag: null,
							value: "",
						});
					}
				}

				if (this.abbreviationOnly) {
					this.initialCompositeKey = this.metadata.find(
						(metadatum) => metadatum.index === "compositeKey"
					)?.value;
				}
				this.preventClosing = false;
			}
		},
		exportMetadata: function () {
			const result = {};

			// if this is a table cell and it is marked as blank, return immediately
			if (this.tableMode && this.blankTableCell) {
				return {
					blank: true,
				};
			}

			// if empty is allowed and should be empty on exit and nothing has been added,
			// remove uuid
			if (this.allowEmpty && this.emptyOnExit && this.isClean()) {
				this.metadata = [];
			}

			if (this.branchSelection) {
				const compositeKeyData = this.metadata.find(
					(metadatum) =>
						!isNaN(metadatum.index) &&
						metadatum.value &&
						!metadatum.tag
				);

				return compositeKeyData
					? { compositeKey: compositeKeyData.value }
					: null;
			}
			let hasReference = false;

			const validMetadataRow = this.metadata.find((metadatum) => {
				if (
					this.excludeKeys.includes(metadatum.index) ||
					metadatum.value === "" ||
					metadatum.value === undefined ||
					_.isEmpty(metadatum.tag)
				) {
					return false;
				}

				return true;
			});

			if (_.isEmpty(validMetadataRow)) {
				return result;
			}

			_.forEach(this.metadata, (metadatum) => {
				// omit empty metadata fields
				if (metadatum.value !== "" && metadatum.value !== undefined) {
					if (this.excludeKeys.includes(metadatum.index)) {
						// in multiple mode exclude uuids and tag groups
						if (!this.multiple) {
							switch (typeof metadatum.value) {
								case "string":
									result[metadatum.index] = [
										"compositeKey",
										"referenceCompositeKey",
									].includes(metadatum.index)
										? metadatum.value
										: metadatum.value.trim();
									break;
								case "object":
									result[metadatum.index] = metadatum.value;
									break;
							}
						}
					} else if (!_.isEmpty(metadatum.tag)) {
						// make sure numbers are represented as plain numbers
						// javascript converts number less than 10^-6 and
						// greater than 10^21 to scientific notation
						if (utils.isNumber(metadatum.value)) {
							// cast string values to number
							metadatum.value = parseFloat(metadatum.value);
						} else if (!_.isEmpty(metadatum.value)) {
							// trim string values
							metadatum.value = this.showKeyPicker(metadatum?.tag)
								? metadatum.value
								: metadatum.value.trim();
						}

						result[metadatum.index] = {
							tag: _.pick(metadatum.tag, ["name", "uuid"]),
							value: metadatum.value,
							hidden: metadatum.hidden,
							headerTag: metadatum.headerTag,
							...(metadatum.axis ? { axis: metadatum.axis } : {}),
						};

						if (
							metadatum.tag &&
							metadatum.tag.name ===
								"Reference study arm abbreviation"
						) {
							hasReference = true;
							result.referenceCompositeKey = metadatum.value;
						}
					}
				}
			});

			// check if reference composite key needs to be removed
			if (result.referenceCompositeKey) {
				if (!hasReference) {
					delete result.referenceCompositeKey;
				}
			}

			return result;
		},
		processTags: function (index, newTag) {
			const oldTag = this.metadata[index].tag;
			const wasKeyTag =
				this.isKeyTag(oldTag) || this.isReferenceKeyTag(oldTag);
			const isKeyTag = newTag
				? this.isKeyTag(newTag) || this.isReferenceKeyTag(newTag)
				: false;
			if (
				!!wasKeyTag !== !!isKeyTag ||
				(isKeyTag && oldTag && oldTag.name !== newTag.name)
			) {
				this.metadata[index].value = "";
			}

			// TODO if user is a QC person do not allow for empty values
			if (newTag) {
				this.metadata[index].tag = _.pick(newTag, ["name", "uuid"]);
			} else {
				// if no tag is present, unset the tag in case it's been selected and deleted
				this.metadata[index].tag = null;
			}
		},
		getTagInputLabel: function () {
			return this.branchSelection ? "Study branch" : "Tag";
		},
		getFilteredTags: function (index) {
			const referenceKeyIndex = this.metadata.findIndex((data) =>
				this.isReferenceKeyTag(data.tag)
			);
			const keyIndex = this.metadata.findIndex((data) =>
				this.isKeyTag(data.tag)
			);
			return this.filteredTags.filter((tag) => {
				if (this.excludeTags.includes(tag.name)) return false;

				const isReferenceKey = this.isReferenceKeyTag(tag);
				const isKey = this.isKeyTag(tag);
				if (!isReferenceKey && !isKey) return true;

				if (keyIndex < 0 && referenceKeyIndex < 0) return true;
				if (keyIndex === index) {
					return referenceKeyIndex >= 0 ? !isReferenceKey : true;
				}

				if (referenceKeyIndex === index) {
					return keyIndex >= 0 ? !isKey : true;
				}

				if (referenceKeyIndex >= 0 && keyIndex >= 0) {
					return !isKey && !isReferenceKey;
				} else if (referenceKeyIndex >= 0) {
					return !isReferenceKey;
				} else if (keyIndex >= 0) {
					return !isKey;
				}

				return true;
			});
		},
		getRules: function (index) {
			const metadatum = this.metadata[index];
			const rules = [];
			if (this.abbreviationOnly) {
				rules.push((value) => {
					// Make sure a value is present and that this is actually an abbreviation input
					if (!this.isKeyTag(metadatum)) {
						return true;
					}

					// Add the newly input value to the composite key to generate the new composite key
					const keyParts = this.compositeKeyUtils.splitCompositeKey(
						this.currentCompositeKey
					);

					let previousValue;
					switch (metadatum.tag?.name) {
						case this.compositeKeyUtils.studyTag:
							previousValue = keyParts[0];
							keyParts[0] = value;
							break;
						case this.compositeKeyUtils.studyPartTag:
							previousValue = keyParts[1];
							keyParts[1] = value;
							break;
						case this.compositeKeyUtils.studyArmTag:
							previousValue = keyParts[2];
							keyParts[2] = value;
							break;
						case this.compositeKeyUtils.populationTag:
							previousValue = keyParts[3];
							keyParts[3] = value;
							break;
						case this.compositeKeyUtils.subgroupTag:
							previousValue = keyParts[4];
							keyParts[4] = value;
							break;
					}

					// Cannot unset a previously existing value
					if (previousValue && !value) {
						return false;
					}

					const nextCompKey =
						this.compositeKeyUtils.joinCompositeKey(keyParts);

					// Don't check if composite key hasn't changed (user didn't change the input value)
					if (this.currentCompositeKey === nextCompKey) return true;

					// Fail if the new composite key matches one that already exists in the list
					const match = this.compositeKeys.find(
						(key) => key.key === nextCompKey
					);

					if (match) {
						let errorMessage = "";

						if (match.subgroup) {
							errorMessage = `Subgroup '${
								match.subgroup
							}' already exists under ${
								match.population
									? `population '${match.population}', `
									: ""
							} study arm '${match.studyArm}', ${
								match.studyPart
									? `study part '${match.studyPart}'`
									: ""
							}, and study '${match.study}'`;
						} else if (match.population) {
							errorMessage = `Population '${
								match.population
							}' already exists under study arm '${
								match.studyArm
							}', ${
								match.studyPart
									? `study part '${match.studyPart}'`
									: ""
							}, and study '${match.study}'`;
						} else if (match.studyArm) {
							errorMessage = `Study arm '${
								match.studyArm
							}' already exists under ${
								match.studyPart
									? `study part '${match.studyPart}' and`
									: ""
							} 'study '${match.study}'`;
						} else if (match.studyPart) {
							errorMessage = `Study part '${match.studyPart}' already exists under study '${match.study}'`;
						} else {
							errorMessage = `Study '${match.study}' already exists`;
						}
						return errorMessage;
					}

					return true;
				});
			}

			return rules;
		},

		validateValues: function () {
			let allValid = true;
			this.metadata.forEach((metadatum, index) => {
				const rules = this.getRules(index);
				rules.forEach((rule) => {
					const result = rule(metadatum.value);
					if (result !== true) allValid = false;
				});
			});

			this.preventClosing = !allValid;

			return allValid;
		},
		isClean: function () {
			for (const metadatum of this.metadata) {
				if (!isNaN(metadatum.index)) {
					if (
						metadatum.value !== "" &&
						metadatum.value !== null &&
						metadatum.value !== undefined
					) {
						return false;
					}
				}
			}

			return true;
		},
		hasInputMetadata: function () {
			const found = this.metadata.find((metadatum) => {
				return !isNaN(metadatum.index) && !_.isEmpty(metadatum.value);
			});

			return found;
		},
		dialogKeydownHandler: function (event) {
			switch (event.key) {
				case "Enter":
					// add a new row without closing the dialog
					if (event.shiftKey) {
						// append new row
						if (!this.blankTableCell && !this.abbreviationOnly)
							this.addRow();
					} else {
						const isValid = this.validateValues();
						if (isValid) {
							this.dialog = false;
						}

						if (this.tableMode) {
							// emit next cell event
							document.dispatchEvent(new Event("next-cell"));
						}
					}

					// stop from bubbling to dactyl shortcuts
					event.stopPropagation();

					break;
				case "Backspace":
					// disable default WPD backspace behavior (deletes point)
					event.stopPropagation();
					// delete a row and prevent deleting reindexed values
					if (event.shiftKey && !this.blankTableCell) {
						// remove last row
						const index =
							this.element.getElementsByClassName(
								"dactyl-metadata-row"
							).length - 1;

						if (index > 1) {
							this.deleteRow(index);
							event.preventDefault();
						}
					}
					break;
				case "Escape":
					// set metadata array to empty array if nothing has been entered
					// removes the point entirely
					if (!this.allowEmpty && this.isClean()) {
						this.metadata = [];
					}

					// close the dialog
					this.dialog = false;

					// stop from bubbling to dactyl shortcuts
					event.stopPropagation();

					break;
				case "ArrowLeft":
					// collapse the dialog
					// TODO: events with ctrl held aren't being correctly detected
					if (event.ctrlKey) {
						event.stopPropagation();
						this.expanded = false;
						this.setDialogExpanded(false);
					}

					if (this.tableMode) {
						event.stopPropagation();
					}
					break;
				case "ArrowRight":
					// expand the dialog
					// TODO: events with ctrl held aren't being correctly detected
					if (event.ctrlKey) {
						event.stopPropagation();
						this.expanded = true;
						this.setDialogExpanded(true);
					}

					if (this.tableMode) {
						event.stopPropagation();
					}
					break;
				case "ArrowUp":
				case "ArrowDown":
					if (this.tableMode) {
						event.stopPropagation();
					}
					break;
				default:
					// stop from bubbling to dactyl shortcuts
					event.stopPropagation();
					break;
			}
		},
		valueKeydownHandler: function (event) {
			switch (event.key) {
				case "Escape":
					// workaround for v-dialog preventing first keydown.esc
					this.dialogKeydownHandler(event);

					break;
				case "Enter":
					// add a new row without closing the dialog
					if (
						event.shiftKey &&
						!this.blankTableCell &&
						!this.abbreviationOnly
					) {
						this.addRow();
						event.preventDefault();
						event.stopPropagation();
					}
					break;
				case "Backspace":
					// disable default WPD backspace behavior (deletes point)
					event.stopPropagation();
					// delete a row and prevent deleting reindexed values
					if (event.shiftKey && !this.blankTableCell) {
						const index = parseInt(
							event.target.getAttribute("index"),
							10
						);

						if (index > 1) {
							event.target
								.closest(".dactyl-metadata-row")
								.previousElementSibling.querySelector(
									".v-input input"
								)
								.focus();

							this.deleteRow(index);
							event.preventDefault();
						}
					}
					break;
			}
		},
		tagInputKeydown: function (event, metadatum) {
			switch (event.key) {
				case "ArrowLeft":
				case "ArrowRight":
				case "ArrowUp":
				case "ArrowDown":
					event.stopPropagation();
					break;
				case "Backspace":
					if (!_.isEmpty(metadatum.tag)) {
						if (this.showKeyPicker(metadatum.tag)) {
							metadatum.value = null;
						}
						metadatum.tag = null;
						this.tagInputSearch = "";
					}
					break;
			}
		},
		keyInputKeydown: function (event, metadatum) {
			const value = event.target.value?.trim();
			const matchedItem = this.filterCompositeKeys(metadatum).find(
				(item) => item.name.toLowerCase() === value.toLowerCase()
			);

			switch (event.key) {
				case "Enter":
					if (value && matchedItem) {
						metadatum.value = matchedItem.key;
					}
					break;
			}
		},
		toggleUserManual: function () {
			if (this.help) {
				this.clearHelp();
			} else {
				this.setHelp("data-entry-keyboard-shortcuts");
			}
		},
		autoFocus: function (index, metadatum) {
			const filteredMetadata = this.metadata.filter(
				(data) => !isNaN(data.index) && !data.hidden
			);
			const firstEmptyMetadatum = filteredMetadata.find(
				(metadatum) => metadatum.value === ""
			);

			if (firstEmptyMetadatum) {
				return metadatum.index === firstEmptyMetadatum.index;
			}

			const lastFilledMetadatum = filteredMetadata
				.reverse()
				.find((metadatum) => !!metadatum.value);

			if (lastFilledMetadatum) {
				return metadatum.index === lastFilledMetadatum.index;
			}
			return false;
		},
		focusDialog(event) {
			if (this.preventClosing) {
				document
					.getElementsByClassName("v-overlay__content")[0]
					.focus();
			}

			event.stopImmediatePropagation();
		},
		collapseDialog() {
			this.expanded = false;
			this.setDialogExpanded(false);
		},
		expandDialog() {
			this.expanded = true;
			this.setDialogExpanded(true);
		},
		updateMetadataCache() {
			if (this.dialog && this.container && this.indexes) {
				// cache the currently opened metadata dialog in the store
				this.setMetadataCache({
					container: this.container,
					indexes: this.indexes,
					metadata: this.exportMetadata(),
					rowIndex: this.rowIndex,
				});
			} else {
				// not open, clear the cache
				this.clearMetadataCache();
			}
		},
		isHiddenMetadataRow(metadatum) {
			return (
				metadatum.hidden || this.excludeKeys.includes(metadatum.index)
			);
		},
		filterSuggestions(item, queryText, itemText) {
			if (queryText.length > 0) {
				return (
					itemText.title
						.toLocaleLowerCase()
						.indexOf(queryText.toLocaleLowerCase()) > -1
				);
			}
			return false;
		},
		selectTag(metadatum) {
			const index = metadatum.index;
			const existingTag = this.indexTagMap[index];

			const wasKeyPicker = this.showKeyPicker(existingTag);
			const isKeyPicker = this.showKeyPicker(metadatum?.tag);

			if (
				existingTag?.uuid !== metadatum.tag?.uuid &&
				(wasKeyPicker !== isKeyPicker || (wasKeyPicker && isKeyPicker))
			) {
				metadatum.value = null;
			}

			this.indexTagMap[index] = metadatum.tag;
		},
		async selectText(event, index) {
			if (
				this.pdfCopyPasteFlag &&
				typeof this.selectTextFunction === "function"
			) {
				const container = this.$refs.dialog.contentEl.closest(
					".v-overlay-container"
				);

				// hide everything somehow without closing
				container.style.display = "none";

				// call text selection function
				const text = await new Promise((resolve) =>
					this.selectTextFunction(resolve)
				);

				// set value for given row
				this.metadata[index].value = text;

				// unhide everything
				container.style.display = "";

				// set focus
				event.target
					.closest(".v-input")
					?.querySelector("input[type='text']")
					?.focus();
			}
		},
	},
};
</script>

<!-- not scoped since the dialog element is attached elsewhere -->
<style lang="scss">
.metadata-dialog {
	position: absolute;
	margin: 0 !important;

	.metadata-dialog-content {
		border: thin solid $color-dactyl-orange !important;

		.metadata-dialog-button-group {
			flex-basis: 50px;

			.metadata-dialog-button {
				&.v-icon.v-icon::after {
					opacity: 0 !important;
				}
			}
		}

		.tag-toggle {
			height: 12px;
			line-height: 12px;
		}

		.v-field--focused .v-field__input {
			font-size: 15px;
		}

		.v-combobox__selection {
			.v-combobox__selection-text {
				font-size: 15px;
			}
		}

		.v-autocomplete__selection {
			.v-autocomplete__selection-text {
				font-size: 15px;
			}
		}

		.blank-cell-row {
			height: 30px;

			.v-label {
				font-size: 14px;
			}
		}

		.v-field__overlay {
			opacity: 0 !important;
			background-color: white !important;
		}
	}
}

:deep(.v-field__overlay) {
	opacity: 0 !important;
	background-color: white !important;
}

@mixin arrow-shared {
	position: absolute;
	z-index: 250;
	width: 0;
	height: 0;
}

.arrow-up {
	@include arrow-shared;

	border-right: $arrow-clear-border;
	border-bottom: $arrow-filled-border;
	border-left: $arrow-clear-border;
}

.arrow-down {
	@include arrow-shared;

	border-top: $arrow-filled-border;
	border-right: $arrow-clear-border;
	border-left: $arrow-clear-border;
}

.arrow-right {
	@include arrow-shared;

	border-top: $arrow-clear-border;
	border-bottom: $arrow-clear-border;
	border-left: $arrow-filled-border;
}

.arrow-left {
	@include arrow-shared;

	border-top: $arrow-clear-border;
	border-right: $arrow-filled-border;
	border-bottom: $arrow-clear-border;
}

.composite-key-selector-key {
	font-size: 14px;
}

.composite-key-selector-path-container {
	display: flex;
	flex-direction: column;
	gap: 3px;
}

.composite-key-selector-path {
	margin-left: 10px;
	color: gray;
	font-size: 12px;
	font-style: italic;
}

.composite-key-selector-list-menu .v-select-list {
	padding: 0px;
}

.composite-key-selector-list-menu .v-list-item:not(:last-child) {
	border-bottom: 1px dotted #000;
}

.composite-key-selector-title-container {
	width: 100%;
}

.composite-key-selector-list-item {
	display: flex;
	align-items: center;
}

.table-header-warning-container {
	font-size: 12px;
}
</style>
