<template>
	<v-row
		align="start"
		align-content="start"
		justify="start"
	>
		<v-overlay
			:model-value="loading"
			class="align-center justify-center"
		>
			<v-progress-circular
				color="primary"
				indeterminate
				size="64"
			/>
		</v-overlay>

		<v-col
			v-if="!loading"
			cols="12"
			order="1"
		>
			<paper-table
				training-queue-mode
				training
			/>
		</v-col>
	</v-row>
</template>

<script>
import { mapMutations } from "vuex";

import PaperTable from "@/pages/PaperManager/PaperTable";

export default {
	name: "TrainingDashboard",

	components: {
		PaperTable,
	},

	data() {
		return {
			loading: false,
		};
	},

	computed: {
		paper() {
			return this.$store.getters.paper;
		},
		saveUUID() {
			return this.$store.getters.saveUUID;
		},
		user() {
			return this.$store.getters.user;
		},
	},

	created() {
		this.clear();
	},

	methods: {
		...mapMutations([
			"clearPaper",
			"clearSaveUUID",
			"setPaper",
			"setSaveUUID",
		]),
		clear() {
			this.clearPaper();
			this.clearSaveUUID();
		},
	},
};
</script>

<style scoped lang="scss">
.col {
	flex-grow: 0;
}
</style>
