import _, { add } from "lodash";
import { v4 as uuidv4 } from "uuid";

/**
 * Initializes WPD Tree widget.
 */
function init(wpd, wpdDocument, dataEntryVM) {
	// overwrite the entire TreeWidget class to fix issues with the constructor
	// and add functionality
	wpd.TreeWidget = class {
		constructor($elem) {
			this.$mainElem = $elem;
			this.treeData = null;
			this.itemColors = {};
			this.$mainElem.removeEventListener("click", (e) =>
				this._onclick(e.target)
			);
			this.$mainElem.removeEventListener("keydown", (e) =>
				this._onkeydown(e)
			);
			this.$mainElem.removeEventListener("dblclick", (e) =>
				this._ondblclick(e)
			);
			this.$mainElem.addEventListener("click", (e) => {
				this._onclick(e.target);
			});
			this.$mainElem.addEventListener("keydown", (e) =>
				this._onkeydown(e)
			);
			this.$mainElem.addEventListener("dblclick", (e) =>
				this._ondblclick(e)
			);
			this.idmap = [];
			this.itemCount = 0;
			this.selectedPath = null;
		}

		// overwrite tree widget _renderFolder function to use id prefix
		_renderFolder(data, basePath, isInnerFolder) {
			if (data == null) {
				return;
			}

			const tags = dataEntryVM.getTags();
			const studyTag = _.find(tags, {
				name: wpd.CompositeKeyUtils.studyTag,
			});
			const studyPartTag = _.find(tags, {
				name: wpd.CompositeKeyUtils.studyPartTag,
			});
			const studyArmTag = _.find(tags, {
				name: wpd.CompositeKeyUtils.studyArmTag,
			});
			const populationTag = _.find(tags, {
				name: wpd.CompositeKeyUtils.populationTag,
			});
			const subgroupTag = _.find(tags, {
				name: wpd.CompositeKeyUtils.subgroupTag,
			});

			let htmlStr = "";

			if (isInnerFolder) {
				htmlStr = '<ul class="tree-list">';
			} else {
				htmlStr = '<ul class="tree-list-root">';
			}

			const fileManager = wpd.appData.getFileManager();
			const datasetFileMap = fileManager.getDatasetNameMap();
			const currentFileIndex = fileManager.currentFileIndex();
			const fileDatasets = wpd.appData
				.getPlotData()
				.getDatasets()
				.filter((d) => datasetFileMap[d.name] === currentFileIndex);

			const pageManager = wpd.appData.getPageManager();
			const currentPageDatasets =
				pageManager && wpd.appData.isMultipage()
					? pageManager.getCurrentPageDatasets()
					: fileDatasets;

			const currentPageCompositeKeys = currentPageDatasets.flatMap(
				(dataset) => {
					const pixels = dataset.getAllPixels();
					return pixels
						.map((pixel) => pixel.metadata?.compositeKey)
						.filter((key) => !!key);
				}
			);

			for (let i = 0; i < data.length; i++) {
				const item = data[i];
				this.itemCount++;

				if (typeof item === "string") {
					htmlStr += `<li title="${item}">`;

					let itemPath = `${basePath}/${item}`;

					htmlStr += `<span class="tree-item" id="${this.prefix}tree-item-id-${this.itemCount}">${item}</span>`;

					this.idmap[this.itemCount] = itemPath;
				} else if (typeof item === "object") {
					const labelKey = Object.keys(item)[0];
					if (item[labelKey].type) {
						htmlStr += `<li class="axis-dataset-tree-list">`;
						htmlStr +=
							'<div class="axis-dataset-tree-list-content">';
					} else {
						htmlStr += "<li>";
						htmlStr +=
							'<div class="tree-list-content tree-list-content">';
					}

					let additionalClasses = "";
					if (item[labelKey].classes) {
						additionalClasses = ` ${item[labelKey].classes.join(
							" "
						)}`;
					}

					let dataAttributes = "";
					let isStudy = false;
					let isPart = false;
					let isArm = false;
					let isPopulation = false;
					let isSubgroup = false;
					let compositeKey = "";
					if (item[labelKey].data) {
						dataAttributes = item[labelKey].data
							.map(({ key, value }) => `data-${key}="${value}"`)
							.join(" ");

						const map = item[labelKey].data.reduce((acc, item) => {
							acc[item.key] = item.value;
							return acc;
						}, {});

						if (map.branch === "study") {
							isStudy = true;
						} else if (map.branch === "part") {
							isPart = true;
						} else if (map.branch === "arm") {
							isArm = true;
						} else if (map.branch === "population") {
							isPopulation = true;
						} else if (map.branch === "subgroup") {
							isSubgroup = true;
						}

						const compositeKeyObj = item[labelKey].data.find(
							(obj) => obj.key === "composite-key"
						);
						compositeKey = compositeKeyObj
							? compositeKeyObj.value
							: "";
					}

					const isStudyTree =
						isStudy ||
						isPart ||
						isArm ||
						isPopulation ||
						isSubgroup;
					const isOnCurrentPage =
						!compositeKey ||
						currentPageCompositeKeys.some((key) =>
							wpd.CompositeKeyUtils.isChildKey(key, compositeKey)
						);

					const title =
						item[labelKey].compositeKey ||
						item[labelKey].title ||
						"";
					htmlStr += `
						<div class="tree-folder-container">
							<div
								class="tree-folder ${
									dataEntryVM.reworkStudyTree ||
									isOnCurrentPage
										? ""
										: "tree-off-page"
								}${additionalClasses}"
								id="${this.prefix}tree-item-id-${this.itemCount}"
								title="${title}"
								${dataAttributes}
							>
								${labelKey}
							</div>
					`;

					const reworkStudyTree = dataEntryVM.reworkStudyTree;
					if ((reworkStudyTree || isOnCurrentPage) && isStudyTree) {
						let currentTag;
						if (isStudy) {
							currentTag = studyTag;
						} else if (isPart) {
							currentTag = studyPartTag;
						} else if (isArm) {
							currentTag = studyArmTag;
						} else if (isPopulation) {
							currentTag = populationTag;
						} else {
							currentTag = subgroupTag;
						}

						htmlStr += `
							<input
								data-tag-uuid="${currentTag.uuid}"
								type="button"
								value="✎"
								class="edit-button study-row-button collected"
								onclick="wpd.tree.editAbbreviation(this, event);"
								title="Edit study abbreviation"
							/>`;
					}

					if (
						item[labelKey].type &&
						item[labelKey].type.endsWith("-axis")
					) {
						let title = "Rename";
						if (
							item[labelKey].type === "xy-axis" ||
							item[labelKey].type === "bar-axis"
						) {
							title = "Rename Graph";
						} else if (item[labelKey].type === "table-axis") {
							title = "Rename Table";
						}
						htmlStr += `
							<input
								type="button"
								value="✎"
								title="${title}"
								class="edit-button"
								onclick="wpd.tree.renameAxis('${labelKey}');"
							/>
						`;
					}

					if (
						item[labelKey].type === "xy-dataset" ||
						item[labelKey].type === "bar-dataset"
					) {
						htmlStr += `
							<input
								type="button"
								value="✎"
								title="Edit Graph dataset"
								class="edit-button"
								onclick="wpd.tree.editDataset('${item[labelKey].name}');"
							/>
						`;
					}

					htmlStr += `</div>`;
					htmlStr += `<div class="study-row-button-container">`;

					if (isStudy) {
						htmlStr += this._studyButtons(
							isOnCurrentPage,
							studyTag,
							studyPartTag,
							studyArmTag
						);
					} else if (isPart) {
						htmlStr += this._studyPartButtons(
							isOnCurrentPage,
							studyArmTag
						);
					} else if (isArm) {
						htmlStr += this._studyArmButtons(
							isOnCurrentPage,
							studyArmTag,
							populationTag,
							subgroupTag
						);
					} else if (isPopulation) {
						htmlStr += this._studyPopulationButtons(
							isOnCurrentPage,
							populationTag,
							subgroupTag
						);
					} else if (isSubgroup) {
						htmlStr += this._studySubgroupButtons(
							isOnCurrentPage,
							subgroupTag
						);
					}

					htmlStr += `</div>`;

					// add axis edit buttons
					if (
						item[labelKey].type &&
						item[labelKey].type !== "image-axis"
					) {
						const map = {
							"bar-axis": "bar graph",
							"bar-dataset": "bar graph",
							"table-axis": "table",
							"table-dataset": "table",
							"xy-axis": "XY-graph",
							"xy-dataset": "XY-graph",
						};

						if (item[labelKey].type.endsWith("-axis")) {
							// axes have edit and rename buttons
							// for xy-axes, in addition to those, it has an edit label button
							htmlStr += '<div class="axis-dataset-tree-row">';

							// add study branch button
							if (item[labelKey].type !== "table-axis") {
								htmlStr += `
								<input
									type="button"
									value="+branch"
									title="Add study branch"
									class="axis-dataset-tree-button axis-dataset-add-button"
									style="padding-top: 0px;"
									onclick="wpd.tree.addStudyBranch('${labelKey}');"
								/>
							`;
							}

							// edit axis button
							htmlStr += `
								<input
									type="button"
									value="⚙"
									title="Edit ${map[item[labelKey].type]} axis"
									class="axis-dataset-tree-button"
									style="padding-top: 0px;"
									onclick="wpd.tree.editAxis('${labelKey}');"
								/>
							`;

							// delete axis button
							htmlStr += `
								<input
									type="button"
									value="⌫"
									title="Delete ${map[item[labelKey].type]} axis"
									class="axis-dataset-tree-button"
									style="font-size: 10px; color: red;"
									onclick="wpd.tree.deleteAxis('${labelKey}');"
								/>
							`;

							htmlStr += "</div>";
						} else if (item[labelKey].type.endsWith("-dataset")) {
							// datasets have edit and rename buttons

							htmlStr += '<div class="axis-dataset-tree-row">';

							// add data point button
							htmlStr += `
								<input
									type="button"
									value="+"
									title="Add data point"
									class="axis-dataset-tree-button"
									style="padding-top: 0px;"
									onclick="wpd.tree.addGraphDataPoint('${item[labelKey].name}');"
								/>
							`;

							// edit dataset button (point groups)
							htmlStr += `
								<input
									type="button"
									value="{}"
									title="Edit ${labelKey} point groups"
									class="axis-dataset-tree-button"
									style="font-size: 8px;"
									onclick="wpd.tree.editPointGroups('${item[labelKey].name}');"
								/>
							`;

							// delete dataset button
							htmlStr += `
								<input
									type="button"
									value="⌫"
									title="Delete ${map[item[labelKey].type]} dataset"
									class="axis-dataset-tree-button"
									style="font-size: 10px; color: red;"
									onclick="wpd.tree.deleteDataset('${item[labelKey].name}');"
								/>
							`;

							htmlStr += "</div>";
						}
					}

					htmlStr += "</div>";
					htmlStr += `<hr style="color: #dddddd; height: 1px; margin: 1px 0 1px 0;">`;

					// escape slashes so the selection logic continues to work
					const path = wpd.utils.escapeSlashes(
						item[labelKey].name || labelKey
					);

					this.idmap[this.itemCount] = isStudyTree
						? compositeKey
						: `${basePath}/${path}`;

					htmlStr += this._renderFolder(
						item[labelKey].children,
						this.idmap[this.itemCount],
						true
					);
				}

				htmlStr += "</li>";
			}

			htmlStr += "</ul>";

			return htmlStr;
		}

		_studyButtons(isOnCurrentPage, studyTag, studyPartTag, studyArmTag) {
			return `
				${
					dataEntryVM.reworkStudyTree
						? ``
						: `
							<input
								data-tag-uuid="${studyTag.uuid}"
								type="button"
								value="+"
								class="add-study-data-point-button study-row-button collected"
								onclick="wpd.tree.addStudyDataPoint(this, event);"
								title="Add data point"
							/>
						`
				}
				<input
					data-tag-uuid="${studyPartTag.uuid}"
					type="button"
					value="P"
					class="create-study-part-button study-row-button"
					onclick="wpd.tree.addNewStudyPart(this, event);"
					title="Add study part"
				/>
				<input
					data-tag-uuid="${studyArmTag.uuid}"
					type="button"
					value="A"
					class="create-study-arm-button study-row-button"
					onclick="wpd.tree.addNewStudyArm(this, event);"
					title="Add study arm"
				/>	
				${
					dataEntryVM.reworkStudyTree || isOnCurrentPage
						? `
						<input
							type="button"
							value="⌫"
							class="delete-study-button study-row-button"
							onclick="wpd.tree.showDeleteStudyElementPopup(this, event);"
							title="Delete study"
						/>`
						: ``
				}
			`;
		}

		_studyPartButtons(isOnCurrentPage, studyArmTag) {
			return `
				${
					dataEntryVM.reworkStudyTree
						? ``
						: `
							<input
								data-tag-uuid="${studyArmTag.uuid}"
								type="button"
								value="+"
								class="add-study-arm-data-point-button study-row-button collected"
								onclick="wpd.tree.addStudyPartDataPoint(this, event);"
								title="Add data point"
							/>
						`
				}
				<input
					data-tag-uuid="${studyArmTag.uuid}"
					type="button"
					value="A"
					class="create-study-arm-button study-row-button"
					onclick="wpd.tree.addNewStudyArm(this, event);"
					title="Add study arm"
				/>
				${
					dataEntryVM.reworkStudyTree || isOnCurrentPage
						? `
						<input
							type="button"
							value="⌫"
							class="delete-study-part-button study-row-button"
							onclick="wpd.tree.showDeleteStudyElementPopup(this, event);"
							title="Delete study part"
						/>`
						: ``
				}
			`;
		}

		_studyArmButtons(
			isOnCurrentPage,
			studyArmTag,
			populationTag,
			subgroupTag
		) {
			return `
				${
					dataEntryVM.reworkStudyTree
						? ``
						: `
							<input
								data-tag-uuid="${studyArmTag.uuid}"
								type="button"
								value="+"
								class="add-study-arm-data-point-button study-row-button collected"
								onclick="wpd.tree.addStudyArmDataPoint(this, event);"
								title="Add data point"
							/>
						`
				}
				<input
					data-tag-uuid="${populationTag.uuid}"
					type="button"
					value="pop"
					class="create-population-button study-row-button"
					onclick="wpd.tree.addNewPopulation(this, event);"
					title="Add population"
				/>
				<input
					data-tag-uuid="${subgroupTag.uuid}"
					type="button"
					value="sub"
					class="create-subgroup-button study-row-button"
					onclick="wpd.tree.addNewSubgroup(this, event);"
					title="Add subgroup"
				/>
				${
					dataEntryVM.reworkStudyTree || isOnCurrentPage
						? `
						<input
							type="button"
							value="⌫"
							class="delete-study-arm-button study-row-button"
							onclick="wpd.tree.showDeleteStudyElementPopup(this, event);"
							title="Delete study arm"
						/>`
						: ``
				}
			`;
		}

		_studyPopulationButtons(isOnCurrentPage, populationTag, subgroupTag) {
			return `
				${
					dataEntryVM.reworkStudyTree
						? ``
						: `
							<input
								data-tag-uuid="${populationTag.uuid}"
								type="button"
								value="+"
								class="add-study-population-data-point-button study-row-button collected"
								onclick="wpd.tree.addStudyPopulationDataPoint(this, event);"
								title="Add data point"
							/>
						`
				}
				<input
					data-tag-uuid="${subgroupTag.uuid}"
					type="button"
					value="sub"
					class="create-subgroup-button study-row-button"
					onclick="wpd.tree.addNewSubgroup(this, event);"
					title="Add subgroup"
				/>
				${
					dataEntryVM.reworkStudyTree || isOnCurrentPage
						? `
						<input
							type="button"
							value="⌫"
							class="delete-study-arm-button study-row-button"
							onclick="wpd.tree.showDeleteStudyElementPopup(this, event);"
							title="Delete study population"
						/>`
						: ``
				}
			`;
		}

		_studySubgroupButtons(isOnCurrentPage, subgroupTag) {
			return `
				${
					dataEntryVM.reworkStudyTree
						? ``
						: `
							<input
								data-tag-uuid="${subgroupTag.uuid}"
								type="button"
								value="+"
								class="add-study-subgroup-data-point-button study-row-button collected"
								onclick="wpd.tree.addStudySubgroupDataPoint(this, event);"
								title="Add data point"
							/>
						`
				}
				${
					dataEntryVM.reworkStudyTree || isOnCurrentPage
						? `
						<input
							type="button"
							value="⌫"
							class="delete-study-arm-button study-row-button"
							onclick="wpd.tree.showDeleteStudyElementPopup(this, event);"
							title="Delete study subgroup"
						/>`
						: ``
				}
			`;
		}

		// Expected format:
		// treeData = ["item0", {"folder0": ["sub-item0", "sub-item1"]}, "item1"]
		// itemColors = {"path0" : wpd.Color, "path1" : wpd.Color}
		// overwrite tree widget render function to include id prefix
		render(treeData, itemColors, prefix = "") {
			this.idmap = [];
			this.itemCount = 0;
			this.treeData = treeData;
			this.itemColors = itemColors;
			this.prefix = prefix;

			this.$mainElem.innerHTML = this._renderFolder(
				this.treeData,
				"",
				false
			);

			this._addStudyButton();

			this.selectedPath = null;
		}

		_addStudyButton() {
			const studyList =
				wpdDocument.getElementsByClassName("study-list")[0];

			const addStudyButton = wpdDocument.getElementsByClassName(
				"create-study-button"
			)[0];

			if (studyList && !addStudyButton) {
				const tags = dataEntryVM.getTags();
				const studyKeyTag = _.find(tags, {
					name: wpd.CompositeKeyUtils.studyTag,
				});

				studyList.insertAdjacentHTML(
					"afterbegin",
					`
					<input
						data-tag-uuid="${studyKeyTag.uuid}"
						type="button"
						value='Add study'
						title="Create study"
						class="create-study-button"
						onclick="wpd.tree.addNewStudy(this);"
					/>`
				);
			}
		}

		_unselectAll() {
			const $folders = this.$mainElem.querySelectorAll(".tree-folder");
			const $items = this.$mainElem.querySelectorAll(".tree-item");

			$folders.forEach(function ($e) {
				$e.classList.remove("tree-selected");
			});
			$items.forEach(function ($e) {
				$e.classList.remove("tree-selected");
			});
			this.selectedPath = null;
		}

		// overwrite tree widget selectPath function to use id prefix
		async selectPath(
			itemPath,
			suppressSecondaryActions,
			preserveActive,
			ignoreCallback,
			selectNextInstance
		) {
			const itemId = this.idmap.indexOf(itemPath);
			if (itemId >= 0) {
				const previousPath = this.selectedPath;
				this._unselectAll();
				this.selectedPath = itemPath;
				const $item = wpdDocument.getElementById(
					`${this.prefix}tree-item-id-${itemId}`
				);
				$item.classList.add("tree-selected");

				if (!ignoreCallback && this.itemSelectionCallback != null) {
					this.itemSelectionCallback(
						$item,
						itemPath,
						suppressSecondaryActions,
						preserveActive,
						previousPath,
						selectNextInstance
					);
				}
			}
		}

		// overwrite tree widget _onclick function to use id prefix
		async _onclick(target) {
			const isItem = target.classList.contains("tree-item");
			const isFolder = target.classList.contains("tree-folder");
			const isDisabled = target.classList.contains("tree-disabled");

			if (!isDisabled && (isItem || isFolder)) {
				const previousPath = this.selectedPath;
				this._unselectAll();
				target.classList.add("tree-selected");
				if (this.itemSelectionCallback != null) {
					const itemId = parseInt(
						target.id.replace(`${this.prefix}tree-item-id-`, ""),
						10
					);

					if (!isNaN(itemId)) {
						this.selectedPath = this.idmap[itemId];
						await this.itemSelectionCallback(
							target,
							this.idmap[itemId],
							false,
							false,
							previousPath,
							true
						);
					}
				}
			}
		}

		_onkeydown(e) {
			// allow either F2 or Meta+R to trigger rename
			if (e.key === "F2" || (e.key.toLowerCase() === "r" && e.metaKey)) {
				if (this.itemRenameCallback) {
					this.itemRenameCallback(e.target, this.selectedPath, false);
					e.preventDefault();
				}
			}
		}

		_ondblclick(e) {
			if (this.itemRenameCallback) {
				this.itemRenameCallback(e.target, this.selectedPath, false);
				e.preventDefault();
				e.stopPropagation();
			}
		}

		onItemSelection(callback) {
			this.itemSelectionCallback = callback;
		}

		onItemRename(callback) {
			this.itemRenameCallback = callback;
		}

		getSelectedPath() {
			return this.selectedPath;
		}
	};

	// overwrite root tree
	wpd.tree = (function () {
		// tree widgets
		let rootTree = null;
		let axisTree = null;

		// current axis and dataset
		let activeAxis = null;
		let activeDataset = null;

		// store information for the wizard
		let wizard = false;
		let wizardDatasets = [];

		const mode = {
			text: "Text",
			graph: "Graph",
			table: "Table",
		};

		// polyfill for IE11/Microsoft Edge
		if (window.NodeList && !NodeList.prototype.forEach) {
			NodeList.prototype.forEach = function (callback, thisArg) {
				thisArg = thisArg || window;
				for (let i = 0; i < this.length; i++) {
					callback.call(thisArg, this[i], i, this);
				}
			};
		}

		function textPath() {
			return `/${mode.text}`;
		}

		function graphPath() {
			return `/${mode.graph}`;
		}

		function tablePath() {
			return `/${mode.table}`;
		}

		function buildTree() {
			// if rootTree is null something is terribly wrong, short-circuit
			if (rootTree == null) {
				return;
			}

			const itemColors = {};
			const treeData = [
				{
					[mode.text]: {
						// TODO: tree-disabled?
						classes: [],
						title: `${mode.text} data`,
						children: [],
					},
				},
				{
					[mode.graph]: {
						title: `${mode.graph} data`,
						children: [],
					},
				},
				{
					[mode.table]: {
						title: `${mode.table} data`,
						children: [],
					},
				},
			];

			rootTree.render(treeData, itemColors);

			showTreeItemWidget(null);
		}

		function getRootTreeSelectedPath() {
			if (rootTree) {
				return rootTree.getSelectedPath();
			}

			return null;
		}

		function showTreeItemWidget(id) {
			const $rootTrees = wpdDocument.querySelectorAll(".tree-widget");
			$rootTrees.forEach(function ($e) {
				if ($e.id === id) {
					$e.style.display = "inline";
				} else {
					$e.style.display = "none";
				}
			});
		}

		function resetGraphics() {
			wpd.graphicsWidget.removeTool();
			wpd.graphicsWidget.resetData();
			wpd.sidebar.clear();
		}

		function onDatasetSelection(
			elem,
			datasetName,
			suppressSecondaryActions
		) {
			// get dataset index
			const plotData = wpd.appData.getPlotData();
			const dsNamesColl = plotData.getDatasetNames();
			const dsIdx = dsNamesColl.indexOf(datasetName);
			if (dsIdx >= 0) {
				if (!suppressSecondaryActions) {
					// clean up existing UI
					resetGraphics();
				}

				wpd.tree.setActiveDataset(plotData.getDatasets()[dsIdx]);

				if (!suppressSecondaryActions) {
					// set up UI for the new dataset
					wpd.acquireData.load();
				}
			}

			// dispatch dataset select event
			wpd.events.dispatch("wpd.dataset.select", {
				dataset: activeDataset,
			});
		}

		function onDatasetGroupSelection() {
			let axesList = [];
			let datasetList = [];

			const plotData = wpd.appData.getPlotData();
			const fileManager = wpd.appData.getFileManager();
			const datasetFileMap = fileManager.getDatasetNameMap();
			const currentFileIndex = fileManager.currentFileIndex();

			let datasets = plotData
				.getDatasets()
				.filter((d) => datasetFileMap[d.name] === currentFileIndex);

			if (wpd.appData.isMultipage()) {
				const pageManager = wpd.appData.getPageManager();
				const currentPage = pageManager.currentPage();
				const datasetPageMap = pageManager.getDatasetNameMap();
				for (let ds of datasets.filter(
					(d) => datasetPageMap[d.name] === currentPage
				)) {
					axesList.push(plotData.getAxesForDataset(ds));
					datasetList.push(ds);
				}
			} else {
				for (let ds of datasets) {
					axesList.push(plotData.getAxesForDataset(ds));
					datasetList.push(ds);
				}
			}
			wpd.graphicsWidget.setRepainter(
				new wpd.MultipltDatasetRepainter(axesList, datasetList)
			);
		}

		function onTextSelection() {
			let axis = null;
			let dataset = null;

			const plotData = wpd.appData.getPlotData();
			const fileManager = wpd.appData.getFileManager();

			const datasetFileMap = fileManager.getDatasetNameMap();
			const currentFileIndex = fileManager.currentFileIndex();

			// filter datasets associated with the current file
			const fileDatasets = plotData
				.getDatasets()
				.filter((d) => datasetFileMap[d.name] === currentFileIndex);

			let datasets = fileDatasets;

			// filter datasets down to current page, if necessary
			if (wpd.appData.isMultipage()) {
				const pageManager = wpd.appData.getPageManager();
				const currentPage = pageManager.currentPage();
				const datasetPageMap = pageManager.getDatasetNameMap();

				// only include datasets associated with the current file and page
				// loop through datasets associated with the current file and page
				datasets = fileDatasets.filter(
					(d) => datasetPageMap[d.name] === currentPage
				);
			}

			// loop through datasets associated with the current file
			for (let d of datasets) {
				// find the image axis that is not a table axis and not an xy label axis
				// NOTE: there should only be one image axis type per file (per page), except for xy labels
				const a = plotData.getAxesForDataset(d);

				if (
					a instanceof wpd.ImageAxes &&
					!a.getMetadata().table &&
					!a.name.endsWith("Labels")
				) {
					axis = a;
					dataset = d;
				}
			}

			// if axis and dataset cannot be found, create a new one
			if (!axis && !dataset) {
				// create the new axis
				const newAxis = new wpd.ImageAxes();
				newAxis.name = wpd.custom.makeAxisName("Text");
				newAxis.calibrate();

				// add new axis to the collection
				plotData.addAxes(newAxis, wpd.appData.isMultipage());
				fileManager.addAxesToCurrentFile([newAxis]);
				if (wpd.appData.isMultipage()) {
					wpd.appData
						.getPageManager()
						.addAxesToCurrentPage([newAxis]);
				}

				// set newly created axis as active axis
				wpd.tree.setActiveAxis(newAxis);

				// dispatch axes add event
				wpd.events.dispatch("wpd.axes.add", {
					axes: newAxis,
				});

				// make a new uuid
				const uuid = uuidv4();

				// define default text dataset name
				const name = "Text Data";

				// create the new dataset associated with the axis
				const newDataset = new wpd.Dataset();
				newDataset.name = `${name}_${uuid}`;

				newDataset.setMetadata({
					name: name,
					uuid: uuid,
				});

				// add new dataset to collections
				plotData._datasetColl.push(newDataset);
				plotData.setAxesForDataset(newDataset, newAxis);
				fileManager.addDatasetsToCurrentFile([newDataset]);
				if (wpd.appData.isMultipage()) {
					wpd.appData
						.getPageManager()
						.addDatasetsToCurrentPage([newDataset]);
				}

				// set newly created dataset as active dataset
				wpd.tree.setActiveDataset(newDataset);

				// dispatch dataset add event
				wpd.events.dispatch("wpd.dataset.add", {
					dataset: newDataset,
				});
			} else {
				// set active axis and dataset
				wpd.tree.setActiveAxis(axis);
				wpd.tree.setActiveDataset(dataset);
			}

			// dispatch axis and dataset selection events
			wpd.events.dispatch("wpd.axes.select", {
				axes: activeAxis,
			});

			wpd.events.dispatch("wpd.dataset.select", {
				dataset: activeDataset,
			});

			// load tools
			wpd.acquireData.load();

			setTimeout(() => wpd.acquireData.adjustPoints(), 0);

			// initialize tag group select
			wpd.tagGroups.showControls();
		}

		function onGraphSelection(preserveActive) {
			const axisList = [];
			const datasetList = [];

			const labelAxisList = [];
			const labelDatasetList = [];

			const plotData = wpd.appData.getPlotData();
			const fileManager = wpd.appData.getFileManager();

			const datasetFileMap = fileManager.getDatasetNameMap();
			const currentFileIndex = fileManager.currentFileIndex();

			// filter datasets associated with the current file
			const fileDatasets = plotData
				.getDatasets()
				.filter((d) => datasetFileMap[d.name] === currentFileIndex);

			let datasets = fileDatasets;

			// filter datasets down to current page, if necessary
			if (wpd.appData.isMultipage()) {
				const pageManager = wpd.appData.getPageManager();
				const currentPage = pageManager.currentPage();
				const datasetPageMap = pageManager.getDatasetNameMap();

				// only include datasets associated with the current file and page
				datasets = fileDatasets.filter(
					(d) => datasetPageMap[d.name] === currentPage
				);
			}

			// loop through datasets associated with the current file
			for (let dataset of datasets) {
				const axis = plotData.getAxesForDataset(dataset);

				// collect non-image axes
				if (!(axis instanceof wpd.ImageAxes)) {
					axisList.push(axis);
					datasetList.push(dataset);
				} else {
					if (axis.name.endsWith("Labels")) {
						labelAxisList.push(axis);
						labelDatasetList.push(dataset);
					}
				}
			}

			// generate graph tree
			const graphTreeContainer = wpdDocument.getElementsByClassName(
				"tree-widget-graphs-list"
			)[0];

			// clone and replace the container to remove all previous listeners
			const clone = graphTreeContainer.cloneNode();
			graphTreeContainer.parentNode.replaceChild(
				clone,
				graphTreeContainer
			);

			axisTree = new wpd.TreeWidget(clone);
			const graphAxes = [...new Set(axisList)];

			// setup graph tree event handlers
			axisTree.onItemSelection((el, path) => {
				// unselect all data points
				activeDataset?.unselectAll();

				// parse the path and ignore the first match, it contains the entire string
				let [, axisName, datasetName] =
					/^\/([^\/]+)(?:\/([^\/]+))?(?:\/([^\/]+))?$/g.exec(path);

				// axis selected
				if (axisName) {
					// find the axis
					const graphAxis = graphAxes.find(
						(axis) => axis.name === axisName
					);

					// dataset selected
					if (datasetName) {
						// unescape dataset name with slashes
						datasetName = wpd.utils.unescapeSlashes(datasetName);

						// load the tools to add points to the dataset
						onDatasetSelection(null, datasetName);
					} else {
						// no dataset within the axis selected
						// get all datasets associated with the selected axis
						const datasets = plotData.getDatasetsForAxis(graphAxis);

						// get the label axis and dataset
						const labelAxis = labelAxisList.find(
							(axis) => axis.name === `${graphAxis.name} Labels`
						);
						const labelDataset = labelDatasetList.find(
							(dataset) =>
								dataset.name === `${graphAxis.name} Labels`
						);

						// clean up existing UI
						resetGraphics();

						const paintArrays = Array(datasets.length).fill(
							graphAxis
						);
						const paintDatasets = datasets;

						// only include label axis and dataset data if they exist
						if (labelAxis) {
							paintArrays.push(labelAxis);
						}
						if (labelDataset) {
							paintDatasets.push(labelDataset);
						}

						// paint all the datasets associated with the axis
						wpd.graphicsWidget.setRepainter(
							new wpd.MultipltDatasetRepainter(
								paintArrays,
								paintDatasets
							)
						);
					}
				}
			});

			// subfunctions to generate dataset tree within axis tree
			const generateDatasetTreeData = (axis, axisType) => {
				const axisTypeMap = {
					bar: (d) => {
						let displayName = d.name;

						const metadata = d.getMetadata();
						if (metadata.name) {
							displayName = metadata.name;
						}

						return {
							[displayName]: {
								compositeKey: metadata.studyData.compositeKey,
								title: `${axisType} dataset`,
								type: `${axisType}-dataset`,
								name: d.name,
								children: [],
							},
						};
					},
					xy: (d) => {
						let displayName = d.name;

						const metadata = d.getMetadata();
						if (metadata.name) {
							displayName = metadata.name;
						}

						// get the tags and transform it into a map (uuid : name)
						const tags = dataEntryVM.getTags();
						const tagMap = _.zipObject(
							_.map(tags, "uuid"),
							_.chain(tags).map("name").map(_.trim).value()
						);

						return {
							[displayName]: {
								compositeKey: metadata.studyData.compositeKey,
								title: `${axisType} dataset`,
								type: `${axisType}-dataset`,
								name: d.name,
								// graph dataset point groups
								children: d.getPointGroups().map((uuid) => {
									return {
										[tagMap[uuid]]: {
											title: `${displayName} point group`,
											type: `${displayName}-point-group`,
											children: [],
										},
									};
								}),
							},
						};
					},
				};

				return datasetList
					.filter((d) => plotData.getAxesForDataset(d) === axis)
					.map(axisTypeMap[axisType]);
			};

			// generate the axis tree
			const axisTreeData = graphAxes.map((axis) => {
				const axisType = wpd.custom.identifyAxis(axis);

				// graph axis
				return {
					[axis.name]: {
						title: `${axisType} axis`,
						type: `${axisType}-axis`,
						// graph datasets
						children: generateDatasetTreeData(axis, axisType),
					},
				};
			});

			// render the graph tree
			axisTree.render(axisTreeData, null, "graph-axis-");

			// set active axis to null
			if (!preserveActive) {
				wpd.tree.setActiveAxis(null);

				wpd.tagGroups.hideControls();
			}

			// clean up existing UI
			resetGraphics();

			// paint all the datasets
			wpd.graphicsWidget.setRepainter(
				new wpd.MultipltDatasetRepainter(
					[...axisList, ...labelAxisList],
					[...datasetList, ...labelDatasetList]
				)
			);
		}

		function onTableSelection() {
			const axisList = [];
			const datasetList = [];

			const plotData = wpd.appData.getPlotData();
			const fileManager = wpd.appData.getFileManager();

			const datasetFileMap = fileManager.getDatasetNameMap();
			const currentFileIndex = fileManager.currentFileIndex();

			// filter datasets associated with the current file
			const fileDatasets = plotData
				.getDatasets()
				.filter((d) => datasetFileMap[d.name] === currentFileIndex);

			let datasets = fileDatasets;

			// filter datasets down to current page, if necessary
			if (wpd.appData.isMultipage()) {
				const pageManager = wpd.appData.getPageManager();
				const currentPage = pageManager.currentPage();
				const datasetPageMap = pageManager.getDatasetNameMap();

				// only include datasets associated with the current file and page
				datasets = fileDatasets.filter(
					(d) => datasetPageMap[d.name] === currentPage
				);
			}

			// loop through datasets associated with the current file
			for (let dataset of datasets) {
				// collect image axes that are table axes
				const axis = plotData.getAxesForDataset(dataset);

				if (axis instanceof wpd.ImageAxes && axis.getMetadata().table) {
					axisList.push(axis);
					datasetList.push(dataset);
				}
			}

			// generate table tree
			const tableTreeContainer = wpdDocument.getElementsByClassName(
				"tree-widget-tables-list"
			)[0];

			// clone and replace the container to remove all previous listeners
			const clone = tableTreeContainer.cloneNode();
			tableTreeContainer.parentNode.replaceChild(
				clone,
				tableTreeContainer
			);

			axisTree = new wpd.TreeWidget(clone);
			const tableAxes = [...new Set(axisList)];

			// setup table tree event handlers
			axisTree.onItemSelection((el, path) => {
				// unselect all data points
				activeDataset?.unselectAll();

				// remove the leading slash
				const name = path.slice(1);

				// find the axis
				const tableAxis = tableAxes.find((axis) => axis.name === name);

				if (tableAxis) {
					wpd.tree.setActiveAxis(tableAxis);

					const isEmpty = wpd.tableAxis.areTableHeadersEmpty();
					const isValid = wpd.tableAxis.validateTableHeaders();

					// check if table has valid headers
					if (isEmpty || !isValid) {
						wpd.events.dispatch("wpd.axes.select", {
							axes: tableAxis,
						});
					} else {
						// find the first dataset associated with the current axis (table)
						const firstDataset = datasets.find(
							(d) => plotData.getAxesForDataset(d) === tableAxis
						);

						// select the first dataset associated with the current file
						wpd.tree.setActiveDataset(firstDataset);

						wpd.events.dispatch("wpd.dataset.select", {
							dataset: firstDataset,
						});
					}
				}
			});

			// render the table tree
			axisTree.render(
				tableAxes.map((axis) => {
					return {
						[axis.name]: {
							title: `${wpd.custom.identifyAxis(axis)} axis`,
							type: `${wpd.custom.identifyAxis(axis)}-axis`,
							children: [],
						},
					};
				}),
				null,
				"table-axis-"
			);

			// set active axis to null
			wpd.tree.setActiveAxis(null);

			// ensure tag group controls are hidden
			wpd.tagGroups.hideControls();

			// paint all the table columns
			wpd.graphicsWidget.setRepainter(
				new wpd.MultipltDatasetRepainter(axisList, datasetList)
			);
		}

		function editDataset(datasetName) {
			// switch to the given dataset
			switchDataset(datasetName);

			wpd.dataSeriesManagement.showEditDataset();
		}

		function renameDataset(datasetName) {
			// switch to the given dataset
			switchDataset(datasetName);

			wpd.dataSeriesManagement.showRenameDataset();
		}

		function renameAxis(axisName) {
			// switch to the given axis
			_switchAxis(axisName);

			wpd.alignAxes.showRenameAxes();
		}

		function selectAxis(axisName) {
			// switch to the given axis
			const axis = _switchAxis(axisName);

			wpd.events.dispatch("wpd.axes.select", {
				axes: axis,
			});

			return axis;
		}

		function deleteAxis(axisName) {
			// switch to the given axis
			const axis = _switchAxis(axisName);

			// delete axis and associated datasets
			wpd.custom.deleteAxisDeep(axis);

			wpd.tree.refreshPreservingSelection();

			// clean up the canvas
			wpd.graphicsWidget.removeRepainter();
			wpd.graphicsWidget.removeTool();
			wpd.graphicsWidget.resetData();
		}

		function deleteDataset(datasetName) {
			const dataset = switchDataset(datasetName);

			// delete dataset
			wpd.custom.deleteDatasetDeep(dataset);

			wpd.tree.refreshPreservingSelection();
		}

		async function addNewStudy(el) {
			wpd.tagGroups.setActiveKeys({});
			prepareTextSelection();
			await newTagGroup(el, wpd.tagGroups.studyTagGroupName);
		}

		async function addNewStudyPart(el, e) {
			const closestFolder = await selectClosestFolder(el);
			const compositeKey = closestFolder.dataset.compositeKey;
			const parsedKey =
				wpd.CompositeKeyUtils.parseCompositeKey(compositeKey);
			wpd.tagGroups.setActiveKeys(parsedKey);
			await newTagGroup(el, "Study part");
		}

		async function addNewStudyArm(el, e) {
			await _addNewTreeBranch(el, wpd.tagGroups.studyArmTagGroupName);
		}

		async function addNewPopulation(el, e) {
			await _addNewTreeBranch(
				el,
				wpd.tagGroups.studyPopulationTagGroupName
			);
		}

		async function addNewSubgroup(el, e) {
			await _addNewTreeBranch(
				el,
				wpd.tagGroups.studySubgroupTagGroupName
			);
		}

		async function _addNewTreeBranch(el, tagGroupName) {
			const closestFolder = await selectClosestFolder(el);
			const compositeKey = closestFolder.dataset.compositeKey;
			const parsedKey =
				wpd.CompositeKeyUtils.parseCompositeKey(compositeKey);
			wpd.tagGroups.setActiveKeys(parsedKey);
			await newTagGroup(el, tagGroupName);
		}

		async function addStudyDataPoint(el, e) {
			await _addStudyBranchDataPoint(el, wpd.tagGroups.studyTagGroupName);
		}

		async function addStudyPartDataPoint(el, e) {
			await _addStudyBranchDataPoint(
				el,
				wpd.tagGroups.studyPartTagGroupName
			);
		}

		async function addStudyArmDataPoint(el, e) {
			await _addStudyBranchDataPoint(
				el,
				wpd.tagGroups.studyArmTagGroupName
			);
		}

		async function addStudyPopulationDataPoint(el, e) {
			await _addStudyBranchDataPoint(
				el,
				wpd.tagGroups.studyPopulationTagGroupName
			);
		}

		async function addStudySubgroupDataPoint(el, e) {
			await _addStudyBranchDataPoint(
				el,
				wpd.tagGroups.studySubgroupTagGroupName
			);
		}

		async function _addStudyBranchDataPoint(el, tagGroupName) {
			const closestFolder = await selectClosestFolder(el);
			const compositeKey = closestFolder.dataset.compositeKey;
			const existing = findCompositeKeyMatch(compositeKey, tagGroupName);
			const parsedKey =
				wpd.CompositeKeyUtils.parseCompositeKey(compositeKey);
			wpd.tagGroups.setActiveKeys(parsedKey);
			if (existing) {
				wpd.tagGroups.loadTagGroupInstance(existing.metadata.tagGroup);
			} else {
				wpd.tagGroups.newTagGroupInstance(
					wpd.tagGroups.tagGroups.find(
						(group) => group.name === tagGroupName
					)
				);
			}
		}

		async function editAbbreviation(el, e) {
			const closestFolder = await selectClosestFolder(el);
			const compositeKey = closestFolder.dataset.compositeKey;
			const parsedKey =
				wpd.CompositeKeyUtils.parseCompositeKey(compositeKey);
			wpd.tagGroups.setActiveKeys(parsedKey);

			const existing = findCompositeKeyMatch(compositeKey);
			if (existing) {
				wpd.tagGroups.loadTagGroupInstance(existing.metadata.tagGroup);
				await wpd.tagGroups.selectTag(el, true);
			} else {
				wpd.popup.show("edit-non-existent-abbreviation-dialog");
			}
		}

		async function showDeleteStudyElementPopup(el, e) {
			const closestFolder = await selectClosestFolder(el);
			const compositeKey = closestFolder.dataset.compositeKey;
			const existing = findCompositeKeyMatch(compositeKey);
			if (existing) {
				wpd.popup.show("delete-study-element-confirm-dialog");
			} else {
				wpd.popup.show("delete-study-element-empty-dialog");
			}
		}

		function closeDeleteStudyElementPopup() {
			wpd.popup.close("delete-study-element-confirm-dialog");
		}

		function closeDeleteStudyElementEmptyPopup() {
			wpd.popup.close("delete-study-element-empty-dialog");
		}

		function closeEditNonExistentElementPopup() {
			wpd.popup.close("edit-non-existent-abbreviation-dialog");
		}

		async function deleteStudyElement() {
			const studyContainer =
				wpdDocument.getElementById("study-tree-display");
			const selected =
				studyContainer.getElementsByClassName("tree-selected")[0];

			const childFolders = selected
				.closest("li")
				.getElementsByClassName("tree-folder");

			const sortedFolders = Array.from(childFolders).reverse();
			const datasets = wpd.appData.getPlotData().getDatasets();

			for (const folder of sortedFolders) {
				prepareTextSelection();
				const compositeKey = wpdDocument.getElementById(folder.id)
					.dataset.compositeKey;

				if (dataEntryVM.reworkStudyTree) {
					datasets.forEach((ds) => {
						const match = findCompositeKeyMatch(
							compositeKey,
							null,
							ds
						);
						if (match) {
							wpd.tagGroups.loadTagGroupInstance(
								match.metadata.tagGroup
							);
							wpd.tagGroups.clearTags(
								true,
								ds,
								match.metadata.tagGroup.instanceUuid
							);
						}
					});
				} else {
					const existing = findCompositeKeyMatch(compositeKey);
					if (existing) {
						wpd.tagGroups.loadTagGroupInstance(
							existing.metadata.tagGroup
						);

						wpd.tagGroups.clearTags(true);
					}
				}
			}

			await dataEntryVM.autoSaveMetadata();

			activeDataset?.unselectAll();
			wpd.tagGroups.reset();
			closeDeleteStudyElementPopup();
		}

		async function selectClosestFolder(el) {
			const closestFolder = el
				.closest(".tree-list-content")
				.getElementsByClassName("tree-folder")[0];
			const isSelected =
				closestFolder.classList.contains("tree-selected");

			if (!isSelected) {
				await wpd.tagGroups.studyTree._onclick(closestFolder);
			}

			prepareTextSelection();
			return closestFolder;
		}

		function findCompositeKeyMatch(compositeKey, tagGroupName, dataset) {
			const ds = dataset ? dataset : activeDataset;
			return ds
				?.getAllPixels()
				.find(
					(pixel) =>
						(!tagGroupName ||
							pixel.metadata.tagGroup.name === tagGroupName) &&
						pixel.metadata.compositeKey === compositeKey
				);
		}

		async function newTagGroup(el, tagGroupName) {
			const tagGroup = wpd.tagGroups.tagGroups.find(
				(group) => group.name === tagGroupName
			);
			wpd.tagGroups.newTagGroupInstance(tagGroup);
			await wpd.tagGroups.selectTag(el, true);
		}

		function prepareTextSelection() {
			showTreeItemWidget("text-item-tree-widget");
			onTextSelection();
		}

		function editXYLabels(axisName) {
			// switch to the given axis
			_switchAxis(axisName);

			const plotData = wpd.appData.getPlotData();

			// get the label axis for the given axis name
			const xyLabelAxis = plotData
				.getAxesColl()
				.find((a) => a.name === `${axisName} Labels`);

			// find the label dataset associated with this axis
			const xyLabelDataset = plotData.getDatasets().find((d) => {
				return (
					plotData.getAxesForDataset(d) === xyLabelAxis &&
					d.name.startsWith(`${axisName} Labels`)
				);
			});

			// load the xy label dataset
			onDatasetSelection(null, xyLabelDataset.name);
		}

		function addStudyBranch(axisName) {
			wpd.tree.setActiveDataset(null);
			selectAxis(axisName);
			wpd.dataSeriesManagement.showAddDatasetToExistingGraph();
		}

		function editAxis(axisName) {
			// switch to the given axis
			const axis = selectAxis(axisName);

			// image and table axes are both based on the image axis class
			// ignore image and table axes
			if (!(axis instanceof wpd.ImageAxes)) {
				// trigger calibration
				wpd.alignAxes.reloadCalibrationForEditing();
			}
		}

		function addGraphDataPoint(datasetName) {
			switchDataset(datasetName);
		}

		function editPointGroups(datasetName) {
			switchDataset(datasetName);

			wpd.pointGroups.showSettingsPopup();
		}

		function switchDataset(datasetName) {
			// unselect all data points
			activeDataset?.unselectAll();

			// find the named dataset in the collection
			const dataset = wpd.appData
				.getPlotData()
				.getDatasets()
				.find((d) => d.name === datasetName);

			// set the active axis
			wpd.tree.setActiveAxis(
				wpd.appData.getPlotData().getAxesForDataset(dataset)
			);

			// select the dataset
			if (axisTree) {
				axisTree.selectPath(`/${activeAxis.name}/${datasetName}`);
			}

			// set as active axis
			wpd.tree.setActiveDataset(dataset);

			return dataset;
		}

		function _switchAxis(axisName) {
			// unselect all data points
			activeDataset?.unselectAll();

			// select the axis
			axisTree.selectPath(`/${axisName}`);

			// find the named axis in the collection
			const axis = wpd.appData
				.getPlotData()
				.getAxesColl()
				.find((a) => a.name === axisName);

			// set as active axis
			wpd.tree.setActiveAxis(axis);

			return axis;
		}

		function selectDataset(datasetName) {
			// unselect all data points
			activeDataset?.unselectAll();

			onDatasetSelection(null, datasetName);
		}

		function addGraphAxis(el) {
			wizard = true;

			// update button appearance
			el.classList.add("pressed-button");

			// unset active axis and dataset
			wpd.tree.setActiveAxis(null);
			wpd.tree.setActiveDataset(null);

			// unselect axis tree items
			axisTree._unselectAll();

			// trigger table calibration tool
			wpd.alignAxes.addCalibration();
		}

		function addTableAxis(el) {
			// update button appearance
			el.classList.add("pressed-button");

			// unset active axis and dataset
			wpd.tree.setActiveAxis(null);
			wpd.tree.setActiveDataset(null);

			// unselect axis tree items
			axisTree._unselectAll();

			// trigger table calibration tool
			wpd.alignAxes.pickArea();
		}

		function onSelection(
			el,
			path,
			suppressSecondaryActions,
			preserveActive
		) {
			// unselect all data points
			activeDataset?.unselectAll();

			switch (true) {
				case path === `/${mode.text}`:
					showTreeItemWidget("text-item-tree-widget");
					onTextSelection();
					break;
				case path === `/${mode.graph}`:
					showTreeItemWidget("graph-item-tree-widget");
					onGraphSelection(preserveActive);
					break;
				case path === `/${mode.table}`:
					showTreeItemWidget("table-item-tree-widget");
					onTableSelection();
					break;
				default:
					resetGraphics();
					showTreeItemWidget(null);
					wpd.tree.setActiveAxis(null);
			}
		}

		function onRename(el, path) {
			if (path.startsWith("/" + wpd.gettext("datasets") + "/")) {
				wpd.dataSeriesManagement.showRenameDataset();
			} else if (path.startsWith("/" + wpd.gettext("axes") + "/")) {
				wpd.alignAxes.showRenameAxes();
			}
		}

		function init() {
			const container = wpdDocument.getElementById("tree-container");
			rootTree = new wpd.TreeWidget(container);
			rootTree.onItemSelection(onSelection);
			rootTree.onItemRename(onRename);
			buildTree();
		}

		function refresh() {
			buildTree();
		}

		function completeTableCalibration() {
			const isEmpty = wpd.tableAxis.areTableHeadersEmpty();
			const isValid = wpd.tableAxis.validateTableHeaders();
			const hasEmptyHeaders = wpd.tableAxis.hasEmptyTableHeaders();

			if (isEmpty) {
				wpd.tableAxis.showEmptyTableHeadersDialog();
			} else if (!isValid) {
				wpd.tableAxis.showInvalidTableHeadersDialog();
			} else if (hasEmptyHeaders) {
				wpd.tableAxis.showHasEmptyTableHeadersDialog();
			} else {
				wpd.tree.refreshPreservingSelection();
			}
		}

		function refreshPreservingSelection(forceRefresh) {
			if (rootTree != null) {
				// cache existing selection
				const selectedPath = rootTree.getSelectedPath();

				// handle axis tree selection
				let selectedAxis;
				if (axisTree) {
					selectedAxis = axisTree.getSelectedPath();
				}

				// do the refresh
				refresh();

				// re-apply cached selections
				rootTree.selectPath(selectedPath, !forceRefresh, true);

				if (axisTree) {
					axisTree.selectPath(selectedAxis, !forceRefresh, true);
				}
			} else {
				refresh();
			}
		}

		function selectPath(path, suppressSecondaryActions) {
			// unselect all data points
			activeDataset?.unselectAll();

			// handle dataset selection here, this will come from table cell selection tool
			if (path === null) {
				// Unselect everything
				rootTree._unselectAll();

				// clear tag group ui elements
				wpd.tagGroups.hideControls();

				// display everything on this file (and page)
				onDatasetGroupSelection();
			} else {
				rootTree.selectPath(path, suppressSecondaryActions);
			}
		}

		function addMeasurement(mode) {
			wpd.measurement.start(mode);
			refresh();
			let suppressSecondaryActions = true;
			if (wpd.appData.isMultipage()) suppressSecondaryActions = false;
			if (mode === wpd.measurementModes.distance) {
				wpd.tree.selectPath(
					"/" +
						wpd.gettext("measurements") +
						"/" +
						wpd.gettext("distance"),
					suppressSecondaryActions
				);
			} else if (mode === wpd.measurementModes.angle) {
				wpd.tree.selectPath(
					"/" +
						wpd.gettext("measurements") +
						"/" +
						wpd.gettext("angle"),
					suppressSecondaryActions
				);
			} else if (mode === wpd.measurementModes.area) {
				wpd.tree.selectPath(
					"/" +
						wpd.gettext("measurements") +
						"/" +
						wpd.gettext("area"),
					suppressSecondaryActions
				);
			}
		}

		function getActiveDataset() {
			return activeDataset;
		}

		function getActiveAxes() {
			return activeAxis;
		}

		function getAxisTree() {
			return axisTree;
		}

		function setActiveDataset(dataset) {
			activeDataset = dataset;
		}

		function setActiveAxis(axis) {
			activeAxis = axis;
		}

		function inWizard() {
			return wizard;
		}

		function exitWizard() {
			wizard = false;
		}

		function getWizardDatasets() {
			return wizardDatasets;
		}

		function setWizardDatasets(datasets) {
			wizardDatasets = datasets;
		}

		return {
			addGraphAxis: addGraphAxis,
			addGraphDataPoint: addGraphDataPoint,
			addMeasurement: addMeasurement,
			addNewStudy: addNewStudy,
			addNewStudyArm: addNewStudyArm,
			addNewStudyPart: addNewStudyPart,
			addNewPopulation: addNewPopulation,
			addNewSubgroup: addNewSubgroup,
			addStudyBranch: addStudyBranch,
			addStudyDataPoint: addStudyDataPoint,
			addStudyArmDataPoint: addStudyArmDataPoint,
			addStudyPartDataPoint: addStudyPartDataPoint,
			addStudyPopulationDataPoint: addStudyPopulationDataPoint,
			addStudySubgroupDataPoint: addStudySubgroupDataPoint,
			addTableAxis: addTableAxis,
			closeDeleteStudyElementPopup: closeDeleteStudyElementPopup,
			closeDeleteStudyElementEmptyPopup:
				closeDeleteStudyElementEmptyPopup,
			closeEditNonExistentElementPopup: closeEditNonExistentElementPopup,
			completeTableCalibration: completeTableCalibration,
			deleteAxis: deleteAxis,
			deleteDataset: deleteDataset,
			deleteStudyElement: deleteStudyElement,
			editAxis: editAxis,
			editDataset: editDataset,
			editPointGroups: editPointGroups,
			editAbbreviation: editAbbreviation,
			editXYLabels: editXYLabels,
			exitWizard: exitWizard,
			findCompositeKeyMatch: findCompositeKeyMatch,
			getActiveAxes: getActiveAxes,
			getActiveDataset: getActiveDataset,
			getAxisTree: getAxisTree,
			getRootTreeSelectedPath: getRootTreeSelectedPath,
			getWizardDatasets: getWizardDatasets,
			graphPath: graphPath,
			init: init,
			inWizard: inWizard,
			onTableSelection: onTableSelection,
			onTextSelection: onTextSelection,
			refresh: refresh,
			refreshPreservingSelection: refreshPreservingSelection,
			renameAxis: renameAxis,
			renameDataset: renameDataset,
			selectAxis: selectAxis,
			selectDataset: selectDataset,
			selectPath: selectPath,
			setActiveAxis: setActiveAxis,
			setActiveDataset: setActiveDataset,
			setWizardDatasets: setWizardDatasets,
			showDeleteStudyElementPopup: showDeleteStudyElementPopup,
			showTreeItemWidget: showTreeItemWidget,
			switchDataset: switchDataset,
			tablePath: tablePath,
			textPath: textPath,
		};
	})();
}

export { init };
