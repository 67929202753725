import { createApp } from "vue";
import { LDPlugin } from "launchdarkly-vue-client-sdk";
import * as Sentry from "@sentry/vue";
import axios from "axios";
import auth from "./auth";

import vuetify from "./plugins/vuetify";
import "vuetify/styles";

import router from "./router";
import store from "./store";

// main component to render
import App from "./App.vue";

const app = createApp(App);

// launchdarkly
app.use(LDPlugin, {
	clientSideID: process.env.VUE_APP_LD_CLIENT_SIDE_ID,
});

// set baseURL
const protocol = process.env.NODE_ENV === "development" ? "http" : "https";
const host = process.env.VUE_APP_HOST;
const apiPort = process.env.VUE_APP_API_PORT;
axios.defaults.baseURL = `${protocol}://${host}:${apiPort}`;
axios.defaults.withCredentials = true;

// make axios available everywhere
app.config.globalProperties.$http = axios;

// set up route guards to check authentication
router.beforeEach(async (to, from, next) => {
	const isAuthenticated = auth.isAuthenticated();
	store.commit("setAuthenticated", isAuthenticated);

	// unauthenticated
	if (!isAuthenticated) {
		// authentication required
		if (to.matched.some((record) => record.meta.requireAuth)) {
			// sent to login
			next({
				path: "/login",
				params: { nextUrl: to.fullPath },
			});
		}
		// authentication not required
		else if (to.matched.some((record) => record.meta.guest)) {
			next();
		}
	}
	// authenticated
	else {
		// authentication required
		if (to.matched.some((record) => record.meta.requireAuth)) {
			// look for permissions guard
			const record = to.matched.find((record) => record.meta.permissions);

			if (record) {
				// check for user permissions
				const user = store.getters.user;
				await axios
					.get(`/users/${user.uuid}/can-all`, {
						params: {
							permissions: record.meta.permissions,
						},
					})
					.then(() => {
						next();
					})
					.catch(() => {
						// expect 403s here; but if it fails navigation should
						// be aborted regardless of status code
						next(false);
					});
			} else {
				next();
			}
		}
		// authentication not required
		else if (to.matched.some((record) => record.meta.guest)) {
			// send to dashboard instead of guest page
			// NOTE may want to revisit this to allow authenticated users to access guest content
			next("/home/dashboard");
		}
	}
});

// only enabling sentry for production for now
if (process.env.NODE_ENV === "production") {
	Sentry.init({
		app,
		dsn: "https://5e5e8d34c700b4f18b79cc51f7cbf03d@o4508049027891200.ingest.us.sentry.io/4508049048993792",
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration({
				maskAllInputs: false,
				maskAllText: false,
			}),
			Sentry.replayCanvasIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			"localhost",
			/^https:\/\/dactyldata\.com:444/,
		],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

// include dactyl version
app.config.globalProperties.$version = process.env.VUE_APP_VERSION;

// initialize vue instance and render main component
app.use(router);
app.use(store);
// app.use(Vuelidate);
app.use(vuetify);
app.mount("#app");
