/**
 * Initializes the adjust data point tool in WPD.
 */
function init(wpd, wpdDocument, dataEntryVM) {
	// cannot pick and choose functions to override due to how it was written
	// completely overwrite the manual selection tool into a js class
	wpd.DeleteDataPointTool = class {
		constructor(axis, dataset) {
			this.axis = axis;
			this.dataset = dataset;
		}

		static #$button = wpdDocument.getElementById("delete-point-button");

		onAttach() {
			// clear selected data points
			// prior data point selection is ignored by this tool
			this.dataset.unselectAll();

			wpd.DeleteDataPointTool.#$button.classList.add("pressed-button");
			wpd.graphicsWidget.setRepainter(
				new wpd.DataPointsRepainter(this.axis, this.dataset)
			);
		}

		onMouseClick(ev, pos, imagePos) {
			const tupleCallback = (imagePos, index) => {
				let indexes = [];

				const tupleIndex = this.dataset.getTupleIndex(index);

				if (tupleIndex > -1) {
					const indexes = this.dataset.getTuple(tupleIndex);

					// sort indexes in descending order for removal
					const indexesDesc = [...indexes]
						.filter((i) => i !== null)
						.sort((a, b) => b - a);

					// remove each data point in tuple
					indexesDesc.forEach((idx) => {
						this.dataset.removePixelAtIndex(idx);
						// update pixel references in tuples
						this.dataset.refreshTuplesAfterPixelRemoval(idx);
					});

					// remove tuple
					this.dataset.removeTuple(tupleIndex);

					// update current tuple index pointer
					wpd.pointGroups.previousGroup();
				} else {
					// if tuple does not exist, just remove the pixel
					indexes = [
						this.dataset.removeNearestPixel(imagePos.x, imagePos.y),
					];
				}

				finalCallback(indexes);
			};

			const pointCallback = (imagePos) => {
				const nearestIndex = this.dataset.findNearestPixel(
					imagePos.x,
					imagePos.y
				);

				const indexes = [];
				const tagGroupIndexes = this.dataset
					.getTagGroupInstancePixelIndexes(nearestIndex)
					.sort((a, b) => b - a);
				const hasOtherData = tagGroupIndexes.find((index) => {
					if (index === nearestIndex) return false;
					const pixel = this.dataset.getPixel(index);
					if (!pixel || !pixel.metadata) return false;
					const isKeyTag = wpd.tagGroups.isKeyTag(
						pixel.metadata[0]?.tag?.name
					);
					return !isKeyTag;
				});

				const pixel = this.dataset.getPixel(nearestIndex);
				if (!pixel) return;

				const isKeyTagGroup = wpd.tagGroups.isKeyTagGroup(
					pixel.metadata?.tagGroup?.name
				);

				if (hasOtherData || isKeyTagGroup) {
					this.dataset.removeNearestPixel(imagePos.x, imagePos.y);
					indexes.push(nearestIndex);
				} else {
					tagGroupIndexes.forEach((index) => {
						this.dataset.removePixelAtIndex(index);
						indexes.push(index);
					});
				}

				// remove data point index references from tuples
				const tupleIndex = this.dataset.getTupleIndex(nearestIndex);

				if (tupleIndex > -1) {
					this.dataset.removeFromTupleAt(tupleIndex, nearestIndex);

					// update pixel references in tuples
					this.dataset.refreshTuplesAfterPixelRemoval(nearestIndex);

					// remove tuple if no point index references left in tuple
					if (this.dataset.isTupleEmpty(tupleIndex)) {
						this.dataset.removeTuple(tupleIndex);
					}

					// update current tuple index pointer
					wpd.pointGroups.previousGroup();
				}

				finalCallback(indexes);
			};

			const finalCallback = async (indexes) => {
				wpd.graphicsWidget.resetData();
				wpd.graphicsWidget.forceHandlerRepaint();
				wpd.graphicsWidget.updateZoomOnEvent(ev);
				wpd.dataPointCounter.setCount(this.dataset.getCount());

				// dispatch point delete event
				indexes.forEach((index) => {
					wpd.events.dispatch("wpd.dataset.point.delete", {
						axes: this.axis,
						dataset: this.dataset,
						index: index,
					});
				});

				if (indexes.length > 0) {
					await dataEntryVM.autoSaveMetadata();
				}
			};

			// handle point tuple deletion
			if (this.dataset.hasPointGroups()) {
				const index = this.dataset.findNearestPixel(
					imagePos.x,
					imagePos.y
				);

				if (index > -1) {
					// display tuple deletion confirmation popup if point groups exist
					wpd.pointGroups.showDeleteTuplePopup(
						tupleCallback.bind(this, imagePos, index),
						pointCallback.bind(this, imagePos)
					);
				}
			} else {
				pointCallback(imagePos);
			}
		}

		onKeyDown(ev) {
			if (wpd.acquireData.isToolSwitchKey(ev.keyCode)) {
				wpd.acquireData.switchToolOnKeyPress(
					String.fromCharCode(ev.keyCode).toLowerCase()
				);
			}
		}

		onRemove() {
			// clear selected data points
			this.dataset.unselectAll();

			wpd.graphicsWidget.forceHandlerRepaint();

			wpd.DeleteDataPointTool.#$button.classList.remove("pressed-button");
		}
	};
}

export { init };
