import _ from "lodash";
import { parse } from "uuid";
import { v4 as uuidv4 } from "uuid";

/**
 * Initializes a custom group of WPD functions.
 */
function init(wpd, wpdWindow, wpdDocument, dataEntryVM) {
	wpd.custom = {};

	function getDataPointPosition(dataPoint) {
		// allow position-less data points to pass-through
		if (!dataPoint.x && !dataPoint.y) {
			return null;
		}

		const rotation = wpd.graphicsWidget.getRotation();
		const { x, y } = wpd.graphicsWidget.getRotatedCoordinates(
			0,
			rotation,
			dataPoint.x,
			dataPoint.y
		);
		const position = wpd.graphicsWidget.screenPx(x, y);

		// include wpd graphics container offset and scroll positions
		const graphicsContainer =
			wpdDocument.getElementById("graphicsContainer");
		const canvasDiv = wpdDocument.getElementById("canvasDiv");
		const marginLeftText = wpdWindow.getComputedStyle(canvasDiv).marginLeft;
		const marginLeft = parseInt(marginLeftText.replace("px", ""), 10);
		_.assign(position, {
			x: position.x - graphicsContainer.scrollLeft + marginLeft,
			y: position.y - graphicsContainer.scrollTop,
		});

		return position;
	}

	function getTagGroupData(index, dataPoint, axisType) {
		let activeTagGroup = wpd.tagGroups.activeTagGroup;
		const defaultTags = [];

		let tagGroup;
		let mode = "add";

		// check if the data point already has a tag group instance
		if (dataPoint.metadata?.tagGroup) {
			// edits
			mode = "edit";

			// load existing tag group instance, tag group, and tag if this is a
			// text axis
			if (axisType === "image") {
				wpd.tagGroups.loadDataPointTagGroup(index);
				activeTagGroup = wpd.tagGroups.activeTagGroup;
			}

			tagGroup = dataPoint.metadata.tagGroup;
			defaultTags.push(dataPoint.metadata[0].tag);
		} else if (activeTagGroup) {
			// text data adds
			tagGroup = _.pick(activeTagGroup, ["name", "uuid"]);

			// add the tag group instance uuid to the active tag group
			Object.assign(tagGroup, {
				instanceUuid: wpd.tagGroups.activeInstanceUuid,
			});

			// get the selected tag to collect data for
			defaultTags.push(wpd.tagGroups.activeTag);
		} else {
			// something has gone wrong
			console.error(
				"No active tag group or existing tag group on data point"
			);
		}

		return {
			activeTagGroup,
			tagGroup,
			defaultTags,
			mode,
		};
	}

	function setActiveStudyFromAbbreviation([{ name: tagName }], metadata) {
		if (wpd.tagGroups.isKeyTag(tagName)) {
			const map = {
				[wpd.tagGroups.studyTagName]: "Study",
				[wpd.tagGroups.studyPartTagName]: "Part",
				[wpd.tagGroups.studyArmTagName]: "Arm",
				[wpd.tagGroups.studyPopulationTagName]: "Population",
				[wpd.tagGroups.studySubgroupTagName]: "Subgroup",
			};

			if (!_.isEmpty(metadata)) {
				wpd.tagGroups[`active${map[tagName]}Key`] = metadata[0].value;
			}
		}
	}

	function setActiveStudy(compositeKey) {
		const parsedKey = compositeKey
			? wpd.CompositeKeyUtils.parseCompositeKey(compositeKey)
			: {};
		wpd.tagGroups.activeStudyKey = parsedKey.study;
		wpd.tagGroups.activePartKey = parsedKey.studyPart;
		wpd.tagGroups.activeArmKey = parsedKey.studyArm;
		wpd.tagGroups.activePopulationKey = parsedKey.population;
		wpd.tagGroups.activeSubgroupKey = parsedKey.subgroup;
	}

	/**
	 * Processes newly added or updated data point.
	 * @param {Object} dataset - Current WPD dataset object
	 * @param {Number} index   - Index of data point within given WPD dataset object
	 */
	wpd.custom.processDataPoint = async function (
		dataset,
		index,
		currentAxis,
		abbreviationOnly,
		branchSelection
	) {
		const axisType = wpd.custom.identifyAxis(currentAxis);
		const dataPoint = dataset.getPixel(index);

		const position = getDataPointPosition(dataPoint);

		const { activeTagGroup, tagGroup, defaultTags, mode } = getTagGroupData(
			index,
			dataPoint,
			axisType
		);

		// bar graph calculated value
		let calculatedValues = [];
		if (axisType === "bar") {
			const [barValue] = currentAxis.pixelToData(
				dataPoint.x,
				dataPoint.y
			);
			calculatedValues.push(barValue.toString());
		}

		// get existing non-numeric values for auto-suggest
		const suggestions = wpd.custom.getExistingNonNumericValues();

		if (dataPoint.metadata && !abbreviationOnly) {
			const parsedKey = wpd.CompositeKeyUtils.parseCompositeKey(
				dataPoint.metadata.compositeKey
			);

			const activeCompositeKey =
				wpd.CompositeKeyUtils.generateCompositeKey(
					wpd.tagGroups.activeStudyKey,
					wpd.tagGroups.activePartKey,
					wpd.tagGroups.activeArmKey,
					wpd.tagGroups.activePopulationKey,
					wpd.tagGroups.activeSubgroupKey
				);

			if (activeCompositeKey !== dataPoint.metadata.compositeKey) {
				wpd.tagGroups.selectStudyTreeBranch({
					study: parsedKey.study,
					part: parsedKey.studyPart,
					arm: parsedKey.studyArm,
					population: parsedKey.population,
					subgroup: parsedKey.subgroup,
				});
			}
		}

		// composite key before user inputs changes
		let oldCompositeKey;
		if (branchSelection) {
			const existingPixel = dataset
				.getAllPixels()
				.find(
					(pixel) =>
						!_.isEmpty(pixel.metadata) &&
						pixel.metadata.tagGroup.instanceUuid ===
							wpd.tagGroups.activeInstanceUuid &&
						!wpd.CompositeKeyUtils.isEmpty(
							pixel.metadata.compositeKey
						)
				);
			oldCompositeKey = existingPixel?.metadata?.compositeKey;
		} else {
			oldCompositeKey =
				abbreviationOnly || !dataPoint.metadata
					? wpd.CompositeKeyUtils.generateCompositeKey(
							wpd.tagGroups.activeStudyKey,
							wpd.tagGroups.activePartKey,
							wpd.tagGroups.activeArmKey,
							wpd.tagGroups.activePopulationKey,
							wpd.tagGroups.activeSubgroupKey
					  )
					: dataPoint.metadata.compositeKey;
		}

		const isImageTag = activeTagGroup && axisType === "image";
		const presetActiveStudy = isImageTag && !abbreviationOnly;
		if (presetActiveStudy) {
			setActiveStudy(oldCompositeKey);
			wpd.tagGroups.refreshAll();
		}

		dataset.selectPixel(index);

		// wait for user input
		const metadata = await dataEntryVM.captureMetadata({
			abbreviationOnly,
			axisType,
			branchSelection,
			calculatedValues,
			compositeKeys: wpd.tagGroups.compositeKeys,
			currentCompositeKey: oldCompositeKey,
			container: dataset,
			data: branchSelection
				? { 0: { tag: null, value: oldCompositeKey } }
				: dataPoint.metadata,
			defaultTags: branchSelection ? [] : defaultTags,
			indexes: [index],
			position,
			suggestions,
			tagGroup,
			studyArmAbbreviations: wpd.tagGroups.getAllStudyArmKeys(),
			...(dataEntryVM.pdfCopyPasteFlag && {
				selectTextFunction: wpd.acquireData.selectText,
			}),
		});

		if (isImageTag && !presetActiveStudy) {
			if (branchSelection) {
				if (_.isEmpty(metadata)) {
					dataset.removePixelAtIndex(
						dataset.getAllPixels().length - 1
					);
				} else {
					wpd.custom.selectStudyBranch(dataset, metadata);
				}
			} else {
				setActiveStudyFromAbbreviation(defaultTags, metadata);
			}
		}

		if (!_.isEmpty(metadata)) {
			if (dataEntryVM.reworkStudyTree) {
				wpd.custom.fixCompositeKeysAndAbbreviationsV2(
					dataset,
					metadata,
					oldCompositeKey,
					abbreviationOnly,
					branchSelection
				);
			} else {
				wpd.custom.fixCompositeKeysAndAbbreviations(
					dataset,
					metadata,
					oldCompositeKey,
					abbreviationOnly,
					branchSelection
				);
			}
		}

		if (!branchSelection) {
			if (!_.isEmpty(metadata)) {
				// metadata collected, save to wpd
				wpd.custom.saveDataPoint(dataset, index, metadata);
			} else {
				// no metadata, delete data point
				dataset.removePixelAtIndex(index);

				// remove data point index references from tuples
				const tupleIndex = dataset.getTupleIndex(index);

				if (tupleIndex > -1) {
					dataset.removeFromTupleAt(tupleIndex, index);

					// update data point references in tuples
					dataset.refreshTuplesAfterPixelRemoval(index);

					// remove tuple if no point index references left in tuple
					if (dataset.isTupleEmpty(tupleIndex)) {
						dataset.removeTuple(tupleIndex);
					}

					// update current tuple index pointer
					wpd.pointGroups.previousGroup();
				}

				if (abbreviationOnly) {
					wpd.tagGroups.clearTags();
				}
			}
		}

		if (
			_.isEmpty(metadata) &&
			abbreviationOnly &&
			wpd.tagGroups.isKeyTagGroup(wpd.tagGroups.activeTagGroup.name)
		) {
			wpd.tagGroups.reset();
		}

		// refresh tag group displays
		if (isImageTag) {
			// unset active tag uuid
			wpd.tagGroups.activeTag = null;

			// refresh button display, update collected data and studies
			wpd.tagGroups.refreshAll();
		} else {
			// only fresh the studies if this is not text data
			if (!dataEntryVM.reworkStudyTree) {
				wpd.tagGroups.refreshStudies();
			}
		}

		// repaint the dataset
		wpd.custom.repaintDataPoints(dataset);

		// sync metadata keys on data set
		wpd.custom.syncMetadataKeys(dataset);

		await dataEntryVM.autoSaveMetadata();
	};

	wpd.custom.setCompositeKeyAtIndex = function (dataset, index, key) {
		let metadata = dataset.getPixel(index).metadata;
		Object.assign(metadata, {
			compositeKey: key,
		});

		dataset.setMetadataAt(index, metadata);
	};

	wpd.custom.updateAbbreviationValue = function (
		dataset,
		index,
		newValue,
		metadataIndex
	) {
		let metadata = dataset.getPixel(index).metadata;
		Object.assign(metadata[metadataIndex], { value: newValue });
		dataset.setMetadataAt(index, metadata);
	};

	wpd.custom.selectStudyBranch = function (dataset, metadata) {
		if (!metadata || !metadata.compositeKey) return;

		const pixelsBefore = dataset
			.getAllPixels()
			.filter(
				(pixel) =>
					!!pixel.metadata &&
					!_.isEmpty(pixel.metadata) &&
					pixel.metadata.tagGroup.instanceUuid ===
						wpd.tagGroups.activeInstanceUuid &&
					!wpd.tagGroups.isKeyTag(pixel.metadata["0"].tag.name)
			);

		wpd.tagGroups.clearTags();
		setActiveStudy(metadata.compositeKey);
		wpd.tagGroups.newTagGroupInstance(wpd.tagGroups.activeTagGroup);
		const updatedPixels = pixelsBefore.map((pixel) => {
			return {
				...pixel,
				metadata: {
					...pixel.metadata,
					compositeKey: metadata.compositeKey,
					tagGroup: {
						...pixel.metadata.tagGroup,
						instanceUuid: wpd.tagGroups.activeInstanceUuid,
					},
				},
			};
		});
		updatedPixels.forEach((pixel) => {
			dataset.addPixel(pixel.x, pixel.y, pixel.metadata);
			const maxIndex = dataset.getAllPixels().length - 1;
			wpd.custom.saveDataPoint(dataset, maxIndex, pixel.metadata);
		});
	};

	wpd.custom.fixCompositeKeysAndAbbreviationsV2 = function (
		dataset,
		metadata,
		oldCompositeKey,
		abbreviationOnly,
		branchSelection
	) {
		const newCompositeKey = wpd.CompositeKeyUtils.generateCompositeKey(
			wpd.tagGroups.activeStudyKey,
			wpd.tagGroups.activePartKey,
			wpd.tagGroups.activeArmKey,
			wpd.tagGroups.activePopulationKey,
			wpd.tagGroups.activeSubgroupKey
		);

		// Update the composite key for the current pixel
		metadata.compositeKey = newCompositeKey;

		if (!abbreviationOnly || !oldCompositeKey) return;

		const activeInstanceUuid = wpd.tagGroups.activeInstanceUuid;

		const pixels = dataset
			.getAllPixels()
			.filter((pixel) => !!pixel.metadata);

		// Update composite keys for the rest of the tag group instance
		pixels.forEach((pixel, i) => {
			if (
				!_.isEmpty(pixel.metadata) &&
				pixel.metadata.tagGroup.instanceUuid === activeInstanceUuid
			) {
				wpd.custom.setCompositeKeyAtIndex(dataset, i, newCompositeKey);
			}
		});

		if (branchSelection) {
			return;
		}

		const newValue = metadata[0].value;
		const tag = metadata[0].tag.name;
		const tagUuid = metadata[0].tag.uuid;

		const oldParsedKey =
			wpd.CompositeKeyUtils.parseCompositeKey(oldCompositeKey);
		const datasets = wpd.appData.getPlotData().getDatasets();
		datasets.forEach((plotDataset) => {
			const datasetPixels = plotDataset.getAllPixels();
			const datasetMetadata = plotDataset.getMetadata();
			const axis = wpd.appData
				.getPlotData()
				.getAxesForDataset(plotDataset);
			const type = wpd.custom.identifyAxis(axis);

			// Fix any table header data referencing the updated composite key
			if (type === "table") {
				this.fixTableCompositeKeysAndAbbreviations(
					plotDataset,
					oldCompositeKey,
					newCompositeKey,
					newValue,
					tagUuid
				);
			}

			// Fix any graph calibration data referencing the updated composite key
			if (["xy", "bar", "polar", "ternary"].includes(type)) {
				this.fixGraphCompositeKeysAndAbbreviations(
					plotDataset,
					datasetMetadata,
					oldCompositeKey,
					newCompositeKey,
					newValue
				);
			}

			// Fix any data points referencing the updated composite key
			datasetPixels.forEach((pixel, i) => {
				if (
					!pixel.metadata ||
					!pixel.metadata.compositeKey ||
					!wpd.CompositeKeyUtils.isChildKey(
						pixel.metadata.compositeKey,
						oldCompositeKey
					)
				) {
					return;
				}

				let dsMetadata = pixel.metadata;
				const shouldUpdateCompositeKey =
					(tag === wpd.CompositeKeyUtils.studyTag &&
						oldParsedKey.study) ||
					(tag === wpd.CompositeKeyUtils.studyPartTag &&
						oldParsedKey.study &&
						oldParsedKey.studyPart) ||
					(tag === wpd.CompositeKeyUtils.studyArmTag &&
						oldParsedKey.study &&
						oldParsedKey.studyArm) ||
					(tag === wpd.CompositeKeyUtils.populationTag &&
						oldParsedKey.study &&
						oldParsedKey.studyArm &&
						oldParsedKey.population) ||
					(tag === wpd.CompositeKeyUtils.subgroupTag &&
						oldParsedKey.study &&
						oldParsedKey.studyArm &&
						oldParsedKey.subgroup);

				if (shouldUpdateCompositeKey) {
					const updatedCompositeKey = wpd.CompositeKeyUtils.updateKey(
						pixel.metadata.compositeKey,
						tag,
						newValue
					);
					dsMetadata.compositeKey = updatedCompositeKey;
					plotDataset.setMetadataAt(i, dsMetadata);
				}

				const mdIndex = Object.keys(dsMetadata)
					.filter((key) => !isNaN(key))
					.find((key) => {
						const md = dsMetadata[key];
						const mdValue = md.value;
						const mdTag = md.tag.name;
						const shouldUpdateValue =
							(tag === wpd.CompositeKeyUtils.studyTag &&
								mdTag === wpd.CompositeKeyUtils.studyTag &&
								mdValue === oldParsedKey.study) ||
							(tag === wpd.CompositeKeyUtils.studyPartTag &&
								mdTag === wpd.CompositeKeyUtils.studyPartTag &&
								mdValue === oldParsedKey.studyPart) ||
							(tag === wpd.CompositeKeyUtils.studyArmTag &&
								mdTag === wpd.CompositeKeyUtils.studyArmTag &&
								mdValue === oldParsedKey.studyArm) ||
							(tag === wpd.CompositeKeyUtils.populationTag &&
								mdTag === wpd.CompositeKeyUtils.populationTag &&
								mdValue === oldParsedKey.population) ||
							(tag === wpd.CompositeKeyUtils.subgroupTag &&
								mdTag === wpd.CompositeKeyUtils.subgroupTag &&
								mdValue === oldParsedKey.subgroup);

						return shouldUpdateValue;
					});

				if (mdIndex) {
					wpd.custom.updateAbbreviationValue(
						plotDataset,
						i,
						newValue,
						mdIndex
					);
				}
			});
		});
	};

	wpd.custom.fixTableCompositeKeysAndAbbreviations = function (
		dataset,
		oldKey,
		newKey,
		newValue,
		tagUuid
	) {
		const axis = wpd.appData.getPlotData().getAxesForDataset(dataset);
		const axisMetadata = axis.getMetadata();

		const calibration = axisMetadata?.calibration;
		if (calibration && calibration.cols) {
			const headers = calibration.cols.headers ?? [];
			headers.forEach((col) => {
				if (col.compositeKey === oldKey) {
					const currentValue =
						wpd.CompositeKeyUtils.getCompositeKeyValue(
							col.compositeKey
						);
					col.compositeKey = newKey;

					Object.keys(col)
						.filter((mdIndex) => !isNaN(mdIndex))
						.forEach((mdIndex) => {
							const md = col[mdIndex];
							if (
								md.tag.uuid === tagUuid &&
								md.value === currentValue
							) {
								md.value = newValue;
							}
						});
				}
			});
		}

		if (calibration && calibration.rows) {
			const headers = calibration.rows.headers ?? [];
			headers.forEach((row) => {
				if (row.compositeKey === oldKey) {
					const currentValue =
						wpd.CompositeKeyUtils.getCompositeKeyValue(
							row.compositeKey
						);

					row.compositeKey = newKey;

					Object.keys(row)
						.filter((mdIndex) => !isNaN(mdIndex))
						.forEach((mdIndex) => {
							const md = row[mdIndex];
							if (
								md.tag.uuid === tagUuid &&
								md.value === currentValue
							) {
								md.value = newValue;
							}
						});
				}
			});
		}

		axis.setMetadata(axisMetadata);
	};

	wpd.custom.fixGraphCompositeKeysAndAbbreviations = function (
		dataset,
		metadata,
		oldKey,
		newKey,
		newValue
	) {
		if (
			metadata.studyData?.compositeKey &&
			metadata.studyData.compositeKey === oldKey
		) {
			const currentValue = wpd.CompositeKeyUtils.getCompositeKeyValue(
				metadata.studyData.compositeKey
			);

			metadata.studyData.compositeKey = newKey;

			if (metadata.name === currentValue) {
				metadata.name = newValue;
			}
			dataset.setMetadata(metadata);
		}
	};

	wpd.custom.fixCompositeKeysAndAbbreviations = function (
		dataset,
		metadata,
		oldCompositeKey,
		abbreviationOnly,
		branchSelection
	) {
		const newCompositeKey = wpd.CompositeKeyUtils.generateCompositeKey(
			wpd.tagGroups.activeStudyKey,
			wpd.tagGroups.activePartKey,
			wpd.tagGroups.activeArmKey,
			wpd.tagGroups.activePopulationKey,
			wpd.tagGroups.activeSubgroupKey
		);

		// Update the composite key for the current pixel
		metadata.compositeKey = newCompositeKey;

		if (!abbreviationOnly || !oldCompositeKey) return;

		const pixels = dataset
			.getAllPixels()
			.filter((pixel) => !!pixel.metadata);

		// Update composite keys for the rest of the tag group instance
		pixels.forEach((pixel, i) => {
			if (
				!_.isEmpty(pixel.metadata) &&
				pixel.metadata.tagGroup.instanceUuid ===
					wpd.tagGroups.activeInstanceUuid
			) {
				wpd.custom.setCompositeKeyAtIndex(dataset, i, newCompositeKey);
			}
		});

		if (branchSelection) return;

		const newValue = metadata[0].value;
		const tag = metadata[0].tag.name;

		// Update all child elements with the new abbreviation value and composite key as needed
		const oldParsedKey =
			wpd.CompositeKeyUtils.parseCompositeKey(oldCompositeKey);
		pixels.forEach((pixel, i) => {
			if (
				!pixel.metadata ||
				!pixel.metadata.compositeKey ||
				!wpd.CompositeKeyUtils.isChildKey(
					pixel.metadata.compositeKey,
					oldCompositeKey
				)
			) {
				return;
			}

			let metadata = pixel.metadata;
			const pixelValue = metadata[0].value;
			const pixelTag = metadata[0].tag.name;
			const shouldUpdateValue =
				(tag === wpd.CompositeKeyUtils.studyTag &&
					pixelTag === wpd.CompositeKeyUtils.studyTag &&
					pixelValue === oldParsedKey.study) ||
				(tag === wpd.CompositeKeyUtils.studyPartTag &&
					pixelTag === wpd.CompositeKeyUtils.studyPartTag &&
					pixelValue === oldParsedKey.studyPart) ||
				(tag === wpd.CompositeKeyUtils.studyArmTag &&
					pixelTag === wpd.CompositeKeyUtils.studyArmTag &&
					pixelValue === oldParsedKey.studyArm) ||
				(tag === wpd.CompositeKeyUtils.populationTag &&
					pixelTag === wpd.CompositeKeyUtils.populationTag &&
					pixelValue === oldParsedKey.population) ||
				(tag === wpd.CompositeKeyUtils.subgroupTag &&
					pixelTag === wpd.CompositeKeyUtils.subgroupTag &&
					pixelValue === oldParsedKey.subgroup);

			if (shouldUpdateValue) {
				wpd.custom.updateAbbreviationValue(dataset, i, newValue, 0);
			}

			const shouldUpdateCompositeKey =
				(tag === wpd.CompositeKeyUtils.studyTag &&
					oldParsedKey.study) ||
				(tag === wpd.CompositeKeyUtils.studyPartTag &&
					oldParsedKey.study &&
					oldParsedKey.studyPart) ||
				(tag === wpd.CompositeKeyUtils.studyArmTag &&
					oldParsedKey.study &&
					oldParsedKey.studyArm) ||
				(tag === wpd.CompositeKeyUtils.populationTag &&
					oldParsedKey.study &&
					oldParsedKey.studyArm &&
					oldParsedKey.population) ||
				(tag === wpd.CompositeKeyUtils.subgroupTag &&
					oldParsedKey.study &&
					oldParsedKey.studyArm &&
					oldParsedKey.subgroup);

			if (shouldUpdateCompositeKey) {
				const updatedCompositeKey = wpd.CompositeKeyUtils.updateKey(
					pixel.metadata.compositeKey,
					tag,
					newValue
				);
				metadata.compositeKey = updatedCompositeKey;
				dataset.setMetadataAt(i, metadata);
			}
		});
	};

	/**
	 * Processes newly added or updated data points.
	 * @param {Object} dataset - Current WPD dataset object
	 * @param {Number} indexes - Indexes of data points within given WPD dataset object
	 */
	wpd.custom.processDataPoints = async function (
		dataset,
		indexes,
		abbreviationOnly
	) {
		// get existing non-numeric values for auto-suggest
		const suggestions = wpd.custom.getExistingNonNumericValues();

		const newMetadata = await dataEntryVM.captureMetadata({
			abbreviationOnly: abbreviationOnly,
			position: null,
			data: null,
			container: dataset,
			indexes: indexes,
			suggestions,
			studyArmAbbreviations: wpd.tagGroups.getAllStudyArmKeys(),
		});

		if (!_.isEmpty(newMetadata)) {
			wpd.custom.saveDataPoints(dataset, indexes, newMetadata);

			// sync metadata keys on data set
			wpd.custom.syncMetadataKeys(dataset);
		}

		return new Promise((resolve) => resolve());
	};

	/**
	 * Triggers repainting of data points.
	 * @param {Object} dataset - Current WPD dataset object
	 */
	wpd.custom.repaintDataPoints = function (dataset) {
		wpd.graphicsWidget.forceHandlerRepaint();
		wpd.dataPointCounter.setCount(dataset.getCount());
	};

	/**
	 * Writes metadata to a data point. Overwrites.
	 * @param {Object} dataset  - Current WPD dataset object
	 * @param {Number} index    - Index of data point within given WPD dataset object
	 * @param {Object} metadata - Metadata object to write to data point
	 */
	wpd.custom.saveDataPoint = function (dataset, index, metadata) {
		// store metadata in data point
		dataset.setMetadataAt(index, metadata);
	};

	/**
	 * Writes metadata to data points. Overwrites.
	 * @param {Object} dataset  - Current WPD dataset object
	 * @param {Number} indexes  - Indexes of data points within given WPD dataset object
	 * @param {Object} metadata - Metadata object to write to data points
	 */
	wpd.custom.saveDataPoints = function (dataset, indexes, newMetadata) {
		// multiple points metadata dialog does not return uuids
		_.forEach(indexes, (index) => {
			let metadata = dataset.getPixel(index).metadata;

			// leave uuid on each point intact if it already exists
			if (!metadata || _.isEmpty(metadata)) {
				// point does not have any metadata, assign a UUID
				metadata = {
					uuid: uuidv4(),
				};
			}

			wpd.custom.saveDataPoint(dataset, index, {
				...newMetadata,
				uuid: metadata.uuid,
			});
		});
	};

	/**
	 * Keeps metadata keys on the dataset up-to-date.
	 * @param  {Object} dataset - Current WPD dataset object
	 */
	wpd.custom.syncMetadataKeys = function (dataset) {
		// gather all data point metadata objects in an array
		const allMetadata = _.map(dataset.getAllPixels(), "metadata");

		// collect arrays of keys of each metadata object into an array
		const collectedKeys = _.map(allMetadata, (metadata) =>
			_.keys(metadata)
		);

		// find the unique union between all arrays of keys
		const metadataKeys = _.union(...collectedKeys);

		// maintain label order
		if (_.includes(metadataKeys, "label")) {
			_.pull(metadataKeys, "label");
			metadataKeys.unshift("label");
		}

		// maintain overrides order
		if (_.includes(metadataKeys, "overrides")) {
			_.pull(metadataKeys, "overrides");
			metadataKeys.push("overrides");
		}

		// set metadata keys on data set
		dataset.setMetadataKeys(metadataKeys);
	};

	/**
	 * Determines axis type of a given WPD axis object.
	 * @param  {Object} axis - WPD axis object
	 * @return {String}      - Axis type of the given WPD axis
	 */
	wpd.custom.identifyAxis = function (axis) {
		let axisType;

		switch (Object.getPrototypeOf(axis)) {
			case Object.getPrototypeOf(new wpd.BarAxes()):
				axisType = "bar";
				break;
			case Object.getPrototypeOf(new wpd.ImageAxes()):
				// table axes are image axes under the hood
				if (axis.getMetadata().table) {
					axisType = "table";
				} else {
					axisType = "image";
				}
				break;
			case Object.getPrototypeOf(new wpd.MapAxes()):
				axisType = "map";
				break;
			case Object.getPrototypeOf(new wpd.PolarAxes()):
				axisType = "polar";
				break;
			case Object.getPrototypeOf(new wpd.TernaryAxes()):
				axisType = "ternary";
				break;
			case Object.getPrototypeOf(new wpd.XYAxes()):
				axisType = "xy";
				break;
			default:
				axisType = "unknown";
		}

		return axisType;
	};

	/**
	 * Determines if the given WPD axis object is a graph axis.
	 * @param  {Object} axis - WPD axis object
	 * @return {Boolean}     - Whether or not axis is a graph axis
	 */
	wpd.custom.isGraphAxis = (axis) => {
		return _.includes(
			["xy", "bar", "polar", "ternary"],
			wpd.custom.identifyAxis(axis)
		);
	};

	/**
	 * Returns a set of unique non-numeric metadata values currently used.
	 * @return {Array} - A set of unique non-numeric metadata values
	 */
	wpd.custom.getExistingNonNumericValues = function () {
		const existingValues = new Set();

		const datasets = wpd.appData.getPlotData().getDatasets();

		// if tag groups are present, limit to the active tag
		let activeTagUuid = "";
		if (wpd.tagGroups.activeTag) {
			activeTagUuid = wpd.tagGroups.activeTag.uuid;
		}

		// loop through every dataset
		datasets.forEach((dataset) => {
			// loop through each existing data point
			dataset.getAllPixels().forEach((dataPoint) => {
				Object
					// filter out non-numeric keys, numeric keys are where metadata values and tags are stored
					.values(_.omitBy(dataPoint.metadata, wpd.utils.isWord))
					// filter out numeric values
					.filter((data) => {
						return (
							isNaN(data.value) &&
							data.tag?.uuid === activeTagUuid
						);
					})
					.forEach(({ value }) => existingValues.add(value));
			});
		});

		return Array.from(existingValues);
	};

	/**
	 * Generates a new axis name, given a base name. Will enumerate the name based on existing axes and base name.
	 * @param  {String}             - Axis base name
	 * @param  {Boolean} includeOne - Include "1" at the end of the first occurrence
	 * @return {String}             - A new axis name
	 */
	wpd.custom.makeAxisName = function (baseName, includeOne) {
		// get all existing axis names
		const existingAxisNames = wpd.appData.getPlotData().getAxesNames();

		// avoid conflict with an existing name
		let index = 2;
		let axisName = baseName;

		// if include "1" is true, add the "1"
		if (includeOne) {
			axisName = `${axisName} 1`;
		}

		while (existingAxisNames.indexOf(axisName) >= 0) {
			axisName = `${baseName} ${index}`;
			index++;
		}

		return axisName;
	};

	/**
	 * DEPRECATED
	 * Generates a new dataset name, given a base name. Will enumerate the name based on existing datasets and base name.
	 * @param  {String}  baseName   - Dataset base name
	 * @param  {Boolean} includeOne - Include "1" at the end of the first occurrence
	 * @return {String}             - A new dataset name
	 */
	wpd.custom.makeDatasetName = function (baseName, includeOne) {
		const existingDatasetNames = wpd.appData
			.getPlotData()
			.getDatasetNames();

		// avoid conflict with an existing name
		let index = 2;
		let datasetName = baseName;

		// if include "1" is true, add the "1"
		if (includeOne) {
			datasetName = `${datasetName} 1`;
		}

		while (existingDatasetNames.indexOf(datasetName) >= 0) {
			datasetName = `${baseName} ${index}`;
			index++;
		}

		return datasetName;
	};

	/**
	 * Deletes given axis and associated datasets from all collections.
	 */
	wpd.custom.deleteAxisDeep = function (axis) {
		// clear active axis and dataset
		wpd.tree.setActiveAxis(null);
		wpd.tree.setActiveDataset(null);

		const plotData = wpd.appData.getPlotData();

		// get all datasets associated with the axis
		const datasets = plotData.getDatasetsForAxis(axis);

		// get xy label axis if it exists
		const labelAxis = plotData
			.getAxesColl()
			.find((a) => a.name === `${axis.name} Labels`);
		const labelDataset = plotData
			.getDatasets()
			.find((d) => d.name === `${axis.name} Labels`);

		// delete axes and datasets from plot data
		plotData.deleteAxes(axis);
		plotData.deleteAxes(labelAxis);
		for (const dataset of datasets) {
			plotData.deleteDataset(dataset);
		}
		plotData.deleteDataset(labelDataset);

		// delete axes and datasets from file manager
		const fileManager = wpd.appData.getFileManager();
		fileManager.deleteAxesFromCurrentFile([axis, labelAxis]);
		fileManager.deleteDatasetsFromCurrentFile([...datasets, labelDataset]);

		// delete active axis from page manager
		if (wpd.appData.isMultipage()) {
			const pageManager = wpd.appData.getPageManager();
			pageManager.deleteAxesFromCurrentPage([axis, labelAxis]);
			pageManager.deleteDatasetsFromCurrentPage([
				...datasets,
				labelDataset,
			]);
		}
	};

	/**
	 * Deletes given dataset from all collections.
	 */
	wpd.custom.deleteDatasetDeep = function (dataset) {
		// clear active dataset
		wpd.tree.setActiveDataset(null);

		const plotData = wpd.appData.getPlotData();

		plotData.deleteDataset(dataset);

		// delete axes and datasets from file manager
		const fileManager = wpd.appData.getFileManager();
		fileManager.deleteDatasetsFromCurrentFile([dataset]);

		// delete active axis from page manager
		if (wpd.appData.isMultipage()) {
			const pageManager = wpd.appData.getPageManager();
			pageManager.deleteDatasetsFromCurrentPage([dataset]);
		}
	};

	/**
	 * Get default decimal points to round to
	 */
	wpd.custom.defaultDecimalPlaces = 5;
}

export { init };
