import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import Awesomplete from "awesomplete";

/**
 * Initializes WPD dataSeriesManagement object.
 */
function init(wpd, wpdDocument, dataEntryVM) {
	const datasetAutocompletes = [];
	const tagGroupAutocompletes = [];
	const allTagGroups = dataEntryVM.getTagGroups();
	const tagGroups = [
		_.find(allTagGroups, { name: "Outcomes" }),
		_.find(allTagGroups, { name: "Baseline/Demographics" }),
	];

	const studyArmAbbreviationTag = _.find(dataEntryVM.getTags(), [
		"name",
		wpd.CompositeKeyUtils.studyArmTag,
	]);

	let container = null;
	let saveButton = null;
	let invalidCount = 0;
	let defaultTagGroup = null;

	// dataset input select handler, triggers validation
	const datasetSelectHandler = ({ originalEvent }) => {
		const input = originalEvent.target
			.closest(".add-dataset-row-actions")
			.querySelector(".add-dataset-study-arm-input");

		wpd.dataSeriesManagement.validateRow(input, false);
	};

	const editDatasetSelectHandler = ({ originalEvent }) => {
		const input = originalEvent.target
			.closest(".edit-dataset-row-actions")
			.querySelector(".edit-dataset-study-arm-input");

		wpd.dataSeriesManagement.validateRow(input, true);
	};

	// tag group input select handler, triggers validation
	const tagGroupSelectHandler = ({ originalEvent }) => {
		const input = originalEvent.target
			.closest(".add-dataset-row-actions")
			.querySelector(".add-dataset-tag-group-input");

		wpd.dataSeriesManagement.validateRow(input, false);
	};

	const editTagGroupSelectHandler = ({ originalEvent }) => {
		const input = originalEvent.target
			.closest(".edit-dataset-row-actions")
			.querySelector(".edit-dataset-tag-group-input");

		wpd.dataSeriesManagement.validateRow(input, true);
	};

	// row inputs blur handler
	const rowInputBlur = function ({ target }) {
		wpd.dataSeriesManagement.validateRow(target, false);
	};

	const editRowInputBlur = function ({ target }) {
		wpd.dataSeriesManagement.validateRow(target, true);
	};

	// row inputs keyup handler
	const rowInputKeyUp = _.debounce(
		function (event) {
			// check for shift+enter key stroke
			if (event.shiftKey && event.key === "Enter") {
				wpd.dataSeriesManagement.addRow(true);
			}
		},
		200,
		{
			leading: true,
		}
	);

	// click handler for autocompletes to allow user to see the options
	const clickHandler = (event) => {
		const index = event.target.closest(".add-dataset-row").dataset.index;

		let awesomplete;
		if (event.target.classList.contains("add-dataset-study-arm-input")) {
			awesomplete = datasetAutocompletes[index];
		} else {
			awesomplete = tagGroupAutocompletes[index];
		}

		if (awesomplete.ul.childNodes.length === 0) {
			awesomplete.minChars = 0;
			awesomplete.evaluate();
		} else if (awesomplete.ul.hasAttribute("hidden")) {
			awesomplete.open();
		} else {
			awesomplete.close();
		}
	};

	const editClickHandler = (event) => {
		const index = event.target.closest(".edit-dataset-row").dataset.index;

		let awesomplete;
		if (event.target.classList.contains("edit-dataset-study-arm-input")) {
			awesomplete = datasetAutocompletes[index];
		} else {
			awesomplete = tagGroupAutocompletes[index];
		}

		if (awesomplete.ul.childNodes.length === 0) {
			awesomplete.minChars = 0;
			awesomplete.evaluate();
		} else if (awesomplete.ul.hasAttribute("hidden")) {
			awesomplete.open();
		} else {
			awesomplete.close();
		}
	};

	const checkboxHandler = (event) => {
		if (event.target.checked) {
			setAllTagGroupInputs();
		} else {
			// clear default value for new tag group inputs
			defaultTagGroup = null;
		}
	};

	const setAllTagGroupInputs = () => {
		const tagGroupCheckbox = container.children[0].querySelector(
			".add-dataset-same-tag-groups-checkbox"
		);

		if (tagGroupCheckbox.checked) {
			const tagGroupInputs = container.getElementsByClassName(
				"add-dataset-tag-group-input"
			);

			// set default value for new tag group inputs
			defaultTagGroup = tagGroupInputs[0].value.trim();

			// set any existing tag group inputs to target value
			Array.prototype.forEach.call(
				tagGroupInputs,
				(tagGroupInput, index) => {
					if (index > 0) {
						tagGroupInput.value = defaultTagGroup;
						wpd.dataSeriesManagement.validateRow(tagGroupInput);
					}
				}
			);
		}
	};

	// remove all listeners for a pair of dataset and tag group inputs
	const removeListeners = (
		datasetInput,
		tagGroupInput,
		tagGroupCheckbox,
		isEdit
	) => {
		datasetInput.removeEventListener(
			"awesomplete-selectcomplete",
			isEdit ? editDatasetSelectHandler : datasetSelectHandler
		);
		datasetInput.removeEventListener(
			"click",
			isEdit ? editClickHandler : clickHandler
		);
		datasetInput.removeEventListener(
			"blur",
			isEdit ? editRowInputBlur : rowInputBlur
		);

		if (!isEdit) {
			datasetInput.removeEventListener("keyup", rowInputKeyUp);
		}

		tagGroupInput.removeEventListener(
			"awesomplete-selectcomplete",
			isEdit ? editTagGroupSelectHandler : tagGroupSelectHandler
		);
		tagGroupInput.removeEventListener(
			"click",
			isEdit ? editClickHandler : clickHandler
		);
		tagGroupInput.removeEventListener(
			"blur",
			isEdit ? editRowInputBlur : rowInputBlur
		);

		if (!isEdit) {
			tagGroupInput.removeEventListener("keyup", rowInputKeyUp);
		}

		tagGroupCheckbox?.removeEventListener("click", checkboxHandler);
	};

	// attach all listeners for a pair of dataset and tag group inputs
	const attachListeners = (
		datasetInput,
		tagGroupInput,
		tagGroupCheckbox,
		isEdit
	) => {
		removeListeners(datasetInput, tagGroupInput, tagGroupCheckbox, isEdit);

		datasetInput.addEventListener(
			"awesomplete-selectcomplete",
			isEdit ? editDatasetSelectHandler : datasetSelectHandler
		);
		datasetInput.addEventListener(
			"click",
			isEdit ? editClickHandler : clickHandler
		);
		datasetInput.addEventListener(
			"blur",
			isEdit ? editRowInputBlur : rowInputBlur
		);

		if (!isEdit) {
			datasetInput.addEventListener("keyup", rowInputKeyUp);
		}

		tagGroupInput.addEventListener(
			"awesomplete-selectcomplete",
			isEdit ? editTagGroupSelectHandler : tagGroupSelectHandler
		);
		tagGroupInput.addEventListener(
			"click",
			isEdit ? editClickHandler : clickHandler
		);
		tagGroupInput.addEventListener(
			"blur",
			isEdit ? editRowInputBlur : rowInputBlur
		);

		if (!isEdit) {
			tagGroupInput.addEventListener("keyup", rowInputKeyUp);
		}

		tagGroupCheckbox?.addEventListener("click", checkboxHandler);
	};

	// updated element reference and dataset name generation function call
	wpd.dataSeriesManagement.showAddDataset = function () {
		wpd.popup.show("add-dataset-popup");

		const compositeKeys = wpd.tagGroups.compositeKeys;
		container = wpdDocument.getElementById("add-dataset-study-arm-inputs");
		saveButton = wpdDocument.getElementById("add-dataset-save-button");

		const datasetNames = compositeKeys.map((key) => key.key);

		const datasetInput = container.children[0].querySelector(
			".add-dataset-study-arm-input"
		);
		const tagGroupInput = container.children[0].querySelector(
			".add-dataset-tag-group-input"
		);
		const tagGroupCheckbox = container.children[0].querySelector(
			".add-dataset-same-tag-groups-checkbox"
		);

		// initialize autocompletes
		datasetAutocompletes[0] = this._compositeKeyInput(
			datasetInput,
			datasetNames
		);

		tagGroupAutocompletes[0] = this._tagGroupInput(
			tagGroupInput,
			tagGroups
		);

		// add event listeners
		attachListeners(datasetInput, tagGroupInput, tagGroupCheckbox, false);

		// add on blur listener for first tag group input only
		tagGroupInput.removeEventListener("blur", setAllTagGroupInputs);
		tagGroupInput.addEventListener("blur", setAllTagGroupInputs);

		// disable save button
		saveButton.disabled = true;
	};

	wpd.dataSeriesManagement._compositeKeyInput = function (
		input,
		datasetNames
	) {
		const awesomplete = new Awesomplete(input, {
			list: datasetNames,
			autoFirst: false,
			maxItems: 20,
			minChars: 0,
			item: function (text, input) {
				const parsedKey = wpd.CompositeKeyUtils.parseCompositeKey(text);
				const itemName =
					parsedKey.subgroup ||
					parsedKey.population ||
					parsedKey.studyArm ||
					parsedKey.studyPart ||
					parsedKey.study;

				const population = parsedKey.subgroup
					? parsedKey.population
					: "";
				const arm =
					parsedKey.subgroup || parsedKey.population
						? parsedKey.studyArm
						: "";
				const part = parsedKey.studyArm ? parsedKey.studyPart : "";
				const study =
					parsedKey.studyArm || parsedKey.studyPart
						? parsedKey.study
						: "";
				let htmlElement = document.createElement("li");
				htmlElement.innerHTML = `
		<div class='composite-key-selector-container'>
		<span class='composite-key-selector-key'>${itemName}</span>
		<div class='composite-key-selector-path-container'>${
			population
				? `<span class='composite-key-selector-path'>${population}</span>`
				: ""
		}${
					arm
						? `<span class='composite-key-selector-path'>${arm}</span>`
						: ""
				}${
					part
						? `<span class='composite-key-selector-path'>${part}</span>`
						: ""
				}${
					study
						? `<span class='composite-key-selector-path'>${study}</span>`
						: ""
				}</div></div>`;
				return htmlElement;
			},
			replace: function (suggestion) {
				const key = suggestion.label;
				const parsedKey = wpd.CompositeKeyUtils.parseCompositeKey(key);
				this.input.value =
					parsedKey.subgroup ||
					parsedKey.population ||
					parsedKey.studyArm ||
					parsedKey.studyPart ||
					parsedKey.study;

				this.input.setAttribute("data-composite-key", key);
			},
			container: function (input) {
				var container = Awesomplete.$.create("div", {
					className: "awesomplete composite-key-awesomplete",
				});
				input.parentNode.insertBefore(container, input);
				return container;
			},
		});

		return awesomplete;
	};

	wpd.dataSeriesManagement._tagGroupInput = function (input, tagGroups) {
		return new Awesomplete(input, {
			list: tagGroups,
			maxItems: 20,
			minChars: 0,
			data: function (item) {
				return {
					label: item.name,
					value: item.uuid,
				};
			},
			// insert label instead of value into the input
			replace: function (suggestion) {
				this.input.value = suggestion.label;
			},
		});
	};

	// function to generate dataset name input given an index
	wpd.dataSeriesManagement._generateDatasetNameInput = function (index) {
		return `
			<div class="add-dataset-row" data-index="${index}">
				<div class="add-dataset-row-label">
					Study Branch <span class="add-dataset-number">${index + 1}</span>:
				</div>

				<div class="add-dataset-row-actions">
					<input
						type="text"
						class="add-dataset-study-arm-input"
						data-type="composite-key"
					/>

					<input
						type="text"
						class="add-dataset-tag-group-input"
						data-type="tag-group"
					/>

					<input
						type="button"
						value="⌫"
						class="dataset-remove-button"
						onclick="wpd.dataSeriesManagement.removeRow(event);"
					/>

					<br />

					<span class="error-message"></span>
				</div>
			</div>
		`;
	};

	// function to reset add dataset dialog
	wpd.dataSeriesManagement.resetDialog = function (isEdit) {
		const inputs = container.children;

		// destroy all instances of awesomplete
		for (let i = 0; i < datasetAutocompletes.length; i++) {
			datasetAutocompletes[i].destroy();
			tagGroupAutocompletes[i].destroy();
		}
		datasetAutocompletes.length = 0;
		tagGroupAutocompletes.length = 0;

		const classPrefix = isEdit ? "edit" : "add";

		// remove the rest and set the value of the first name input to ""
		while (inputs.length > 1) {
			// remove select event handler
			const datasetInput = inputs[inputs.length - 1].querySelector(
				`.${classPrefix}-dataset-study-arm-input`
			);
			const tagGroupInput = inputs[inputs.length - 1].querySelector(
				`.${classPrefix}-dataset-tag-group-input`
			);

			removeListeners(datasetInput, tagGroupInput, null, isEdit);

			inputs[inputs.length - 1].remove();
		}

		const datasetInput = inputs[0].querySelector(
			`.${classPrefix}-dataset-study-arm-input`
		);

		datasetInput.removeAttribute("data-composite-key");
		datasetInput.value = "";
		datasetInput.classList.remove("input-error");

		const tagGroupInput = inputs[0].querySelector(
			`.${classPrefix}-dataset-tag-group-input`
		);
		tagGroupInput.value = "";
		tagGroupInput.classList.remove("input-error");

		if (!isEdit) {
			const tagGroupCheckbox = inputs[0].querySelector(
				`.${classPrefix}-dataset-same-tag-groups-checkbox`
			);

			if (tagGroupCheckbox) {
				tagGroupCheckbox.checked = false;
			}
			removeListeners(
				datasetInput,
				tagGroupInput,
				tagGroupCheckbox,
				false
			);
		} else {
			removeListeners(datasetInput, tagGroupInput, null, true);
		}

		datasetInput
			.closest(`.${classPrefix}-dataset-row-actions`)
			.querySelector(".error-message").innerText = "";

		// reset invalid count to 0
		invalidCount = 0;

		// reset save button
		saveButton.disabled = true;
	};

	// function to remove a dataset name row
	wpd.dataSeriesManagement.removeRow = function (event) {
		const index = event.target.closest(".add-dataset-row").dataset.index;
		const rows = container.children;

		// destroy and remove awesomplete instance at the given index
		datasetAutocompletes[index].destroy;
		datasetAutocompletes.splice(index, 1);
		tagGroupAutocompletes[index].destroy;
		tagGroupAutocompletes.splice(index, 1);

		// remove select event handler
		const datasetInput = rows[index].querySelector(
			".add-dataset-study-arm-input"
		);
		const tagGroupInput = rows[index].querySelector(
			".add-dataset-tag-group-input"
		);

		removeListeners(datasetInput, tagGroupInput);

		// remove the dataset name input at the given index
		rows[index].remove();

		const hadDatasetError = datasetInput.classList.contains("input-error");
		const hadTagGroupError =
			tagGroupInput.classList.contains("input-error");
		if (hadDatasetError) {
			invalidCount--;
		}
		if (hadTagGroupError) {
			invalidCount--;
		}

		wpd.dataSeriesManagement.validateRow(
			rows[0].querySelector(".add-dataset-study-arm-input")
		);

		// re-index inputs
		if (rows.length > 1) {
			for (let i = 1; i < rows.length; i++) {
				rows[i].closest(".add-dataset-row").dataset.index = i;
				rows[i].querySelector(".dataset-remove-button").dataset.index =
					i;
				rows[i].querySelector(".add-dataset-number").innerHTML = i + 1;
				wpd.dataSeriesManagement.validateRow(
					rows[i].querySelector(".add-dataset-study-arm-input")
				);
			}
		}
	};

	// function to add a new dataset name input
	wpd.dataSeriesManagement.addRow = function (focusNewInput) {
		const rows = container.children;

		// insert a new input at the end of the container
		container.insertAdjacentHTML(
			"beforeend",
			wpd.dataSeriesManagement._generateDatasetNameInput(rows.length)
		);

		const compositeKeys = wpd.tagGroups.compositeKeys;
		const datasetNames = compositeKeys.map((key) => key.key);

		const newDatasetInput = rows[rows.length - 1].querySelector(
			".add-dataset-study-arm-input"
		);
		const newTagGroupInput = rows[rows.length - 1].querySelector(
			".add-dataset-tag-group-input"
		);

		// initialize awesomplete
		datasetAutocompletes.push(
			this._compositeKeyInput(newDatasetInput, datasetNames)
		);

		tagGroupAutocompletes.push(
			this._tagGroupInput(newTagGroupInput, tagGroups)
		);

		// attach event handlers
		attachListeners(newDatasetInput, newTagGroupInput);

		// disable save button
		saveButton.disabled = true;

		// set focus on the new input if specified
		if (focusNewInput) {
			newDatasetInput.focus();
		}

		// set default tag group
		if (defaultTagGroup !== null) {
			newTagGroupInput.value = defaultTagGroup;
		}
	};

	// row inputs validator
	wpd.dataSeriesManagement.validateRow = function (input, isEdit) {
		const allCompositeKeys = wpd.tagGroups.compositeKeys.map(
			(key) => key.key
		);

		const row = input.closest(
			isEdit ? ".edit-dataset-row-actions" : ".add-dataset-row-actions"
		);

		const rowIndex = isEdit
			? "0"
			: row.closest(".add-dataset-row").getAttribute("data-index");

		const allRows = Array.from(
			container.querySelectorAll(
				isEdit
					? ".edit-dataset-row-actions"
					: ".add-dataset-row-actions"
			)
		);

		const isAddNew =
			!isEdit && container.id === "add-new-dataset-study-arm-inputs";

		const activeDataset = wpd.tree.getActiveDataset();
		const existingCompositeKeys =
			isEdit || isAddNew
				? wpd.appData
						.getPlotData()
						.getDatasetsForAxis(wpd.tree.getActiveAxes())
						.filter(
							(d) =>
								!!d.getMetadata()?.studyData &&
								(!activeDataset ||
									d.name !== activeDataset.name)
						)
						.map((d) => d.getMetadata().studyData.compositeKey)
				: allRows
						.filter(
							(row) =>
								row
									.closest(".add-dataset-row")
									.getAttribute("data-index") !== rowIndex
						)
						.map((row) =>
							row.querySelector(".add-dataset-study-arm-input")
						)
						.filter((input) => input.value !== "")
						.map((input) =>
							input.getAttribute("data-composite-key")
						);

		const allTagGroups = tagGroups.map((tagGroup) => tagGroup.name);
		const branchInput = row.querySelector(
			isEdit
				? ".edit-dataset-study-arm-input"
				: ".add-dataset-study-arm-input"
		);
		const hasBranchInputError =
			branchInput.classList.contains("input-error");
		const branchValue = branchInput.getAttribute("data-composite-key");
		const branchText = branchInput.value;

		const isBranchEmpty = _.isEmpty(branchValue);
		const isBranchInvalid = !_.includes(allCompositeKeys, branchValue);
		const isBranchDuplicate = _.includes(
			existingCompositeKeys,
			branchValue
		);

		const tagGroupInput = row.querySelector(
			isEdit
				? ".edit-dataset-tag-group-input"
				: ".add-dataset-tag-group-input"
		);
		const hasTagGroupInputError =
			tagGroupInput.classList.contains("input-error");
		const tagGroupValue = tagGroupInput.value.trim();
		const isTagGroupEmpty = _.isEmpty(tagGroupValue);
		const isTagGroupInvalid = !_.includes(allTagGroups, tagGroupValue);

		const errorContainer = row.querySelector(".error-message");
		const errorMessages = [];
		if (isBranchEmpty) {
			if (!hasBranchInputError) {
				invalidCount++;
				branchInput.classList.add("input-error");
			}
			errorMessages.push("study branch cannot be empty");
		} else if (isBranchInvalid) {
			if (!hasBranchInputError) {
				invalidCount++;
				branchInput.classList.add("input-error");
			}
			errorMessages.push(`${branchValue} is not a valid study branch`);
		} else if (isBranchDuplicate) {
			if (!hasBranchInputError) {
				invalidCount++;
				branchInput.classList.add("input-error");
			}
			errorMessages.push(`${branchText} already exists on this graph`);
		} else {
			if (hasBranchInputError) {
				invalidCount--;
				branchInput.classList.remove("input-error");
			}
		}

		if (isTagGroupEmpty) {
			if (!hasTagGroupInputError) {
				invalidCount++;
				tagGroupInput.classList.add("input-error");
			}
			errorMessages.push("tag group cannot be empty");
		} else if (isTagGroupInvalid) {
			if (!hasTagGroupInputError) {
				invalidCount++;
				tagGroupInput.classList.add("input-error");
			}
			errorMessages.push(`${tagGroupValue} is not a valid tag group`);
		} else {
			if (hasTagGroupInputError) {
				invalidCount--;
				tagGroupInput.classList.remove("input-error");
			}
		}

		if (invalidCount > 0) {
			const errorMessage = errorMessages.join("; ");
			errorContainer.innerText = errorMessage;
			saveButton.disabled = true;
		} else {
			errorContainer.innerText = "";
			saveButton.disabled = false;
		}
	};

	// overwrite rename dataset function to refresh the tree while preserving selection
	wpd.dataSeriesManagement.renameDataset = function () {
		const $dsName = wpdDocument.getElementById("rename-dataset-name-input");

		// add a new event listener to stop propagation
		const keyupHandler = (event) => event.stopPropagation();

		$dsName.removeEventListener("keyup", keyupHandler);
		$dsName.addEventListener("keyup", keyupHandler);

		wpd.popup.close("rename-dataset-popup");

		const ds = wpd.tree.getActiveDataset();
		ds.name = $dsName.value.trim();
		wpd.tree.refreshPreservingSelection();
		wpd.tree.selectPath(
			`/${wpd.tree.getActiveAxes().name}/${ds.name}`,
			true
		);
	};

	// new function to handle dataset adding from the add datasets popup
	wpd.dataSeriesManagement.addDatasets = function () {
		if (invalidCount > 0) {
			return;
		}

		const rows = Array.from(container.children);

		// get existing values in the add dataset popup
		const studyBranchKeys = rows
			.map((row) => row.querySelector(".add-dataset-study-arm-input"))
			.filter((input) => input.value !== "")
			.map((input) => input.getAttribute("data-composite-key"));
		const tagGroupNames = rows
			.map((row) => row.querySelector(".add-dataset-tag-group-input"))
			.filter((input) => input.value !== "")
			.map((input) => input.value);

		const pairs = studyBranchKeys.map((key, index) => [
			key,
			tagGroupNames[index],
		]);
		const uniquePairs = Array.from(
			new Set(pairs.map((pair) => JSON.stringify(pair)))
		).map((pair) => JSON.parse(pair));
		const uniqueStudyBranchKeys = studyBranchKeys;
		const uniqueTagGroupNames = tagGroupNames;
		const plotData = wpd.appData.getPlotData();
		const fileManager = wpd.appData.getFileManager();
		const pageManager = wpd.appData.getPageManager();
		const isMultipage = wpd.appData.isMultipage();
		const newDatasets = [];
		for (let i = 0; i < uniqueStudyBranchKeys.length; i++) {
			// make a new uuid
			const uuid = uuidv4();
			const dataset = new wpd.Dataset();

			// create the datasets
			dataset.name = `${uniqueStudyBranchKeys[i]}_${uuid}`;
			const compositeKey = uniqueStudyBranchKeys[i];
			const parsedKey =
				wpd.CompositeKeyUtils.parseCompositeKey(compositeKey);

			const nameValue =
				parsedKey.subgroup ||
				parsedKey.population ||
				parsedKey.studyArm ||
				parsedKey.studyPart ||
				parsedKey.study;

			dataset.setMetadata({
				name: nameValue,
				uuid: uuid,
				studyData: {
					compositeKey,
					tagGroup: _.chain(tagGroups)
						.find(["name", uniqueTagGroupNames[i]])
						.pick(["name", "uuid"])
						.value(),
				},
			});

			// collect newly created datasets
			newDatasets.push(dataset);

			// register the dataset
			plotData.addDataset(dataset);
			fileManager.addDatasetsToCurrentFile([dataset]);

			if (isMultipage) {
				pageManager.addDatasetsToCurrentPage([dataset]);
			}

			// set the first dataset as the active dataset
			wpd.tree.setActiveDataset(dataset);

			// dispatch dataset add event
			wpd.events.dispatch("wpd.dataset.add", {
				dataset: dataset,
			});
		}

		// store newly created datasets in the tree if in wizard
		if (wpd.tree.inWizard()) {
			wpd.tree.setWizardDatasets(newDatasets);
		}

		// reset popup
		wpd.dataSeriesManagement.resetDialog();

		// close popup
		wpd.popup.close("add-dataset-popup");

		// prompt point group creation
		wpd.pointGroups.showSettingsPopup();
	};

	// new function to handle canceling from the add dataset popup
	wpd.dataSeriesManagement.cancelDatasetAdd = function () {
		// reset the popup
		wpd.dataSeriesManagement.resetDialog();

		// close popup
		wpd.popup.close("add-dataset-popup");

		// bail on graph add
		wpd.alignAxes.cancelGraphAdd();
	};

	wpd.dataSeriesManagement.showAddDatasetToExistingGraph = function () {
		wpd.popup.show("add-graph-dataset-dialog");

		const compositeKeys = wpd.tagGroups.compositeKeys;
		const datasetNames = compositeKeys.map((key) => key.key);

		container = wpdDocument.getElementById(
			"add-new-dataset-study-arm-inputs"
		);
		saveButton = wpdDocument.getElementById("add-new-dataset-save-button");

		const datasetInput = container.querySelector(
			".add-dataset-study-arm-input"
		);

		datasetAutocompletes[0] = this._compositeKeyInput(
			datasetInput,
			datasetNames
		);

		const tagGroupInput = container.querySelector(
			".add-dataset-tag-group-input"
		);

		tagGroupAutocompletes[0] = this._tagGroupInput(
			tagGroupInput,
			tagGroups
		);

		attachListeners(datasetInput, tagGroupInput, null, false);

		datasetInput.blur();
	};

	wpd.dataSeriesManagement.showEditDataset = function () {
		const dataset = wpd.tree.getActiveDataset();
		const metadata = dataset.getMetadata();

		wpd.popup.show("edit-graph-dataset-dialog");

		const compositeKeys = wpd.tagGroups.compositeKeys;
		const datasetNames = compositeKeys.map((key) => key.key);

		container = wpdDocument.getElementById("edit-dataset-study-arm-inputs");
		saveButton = wpdDocument.getElementById("edit-dataset-save-button");

		const datasetInput = container.querySelector(
			".edit-dataset-study-arm-input"
		);
		datasetInput.value = metadata.name;
		datasetInput.setAttribute(
			"data-composite-key",
			metadata.studyData?.compositeKey
		);

		datasetAutocompletes[0] = this._compositeKeyInput(
			datasetInput,
			datasetNames
		);

		const tagGroupInput = container.querySelector(
			".edit-dataset-tag-group-input"
		);

		tagGroupInput.value = metadata.studyData?.tagGroup?.name;
		tagGroupAutocompletes[0] = this._tagGroupInput(
			tagGroupInput,
			tagGroups
		);

		attachListeners(datasetInput, tagGroupInput, null, true);

		datasetInput.blur();
	};

	wpd.dataSeriesManagement.saveAddDataset = function () {
		this.saveDataset(false);
	};

	wpd.dataSeriesManagement.saveEditDataset = function () {
		this.saveDataset(true);
	};

	wpd.dataSeriesManagement.saveDataset = function (isEdit) {
		if (invalidCount > 0) {
			return;
		}

		const datasetClass = isEdit
			? ".edit-dataset-study-arm-input"
			: ".add-dataset-study-arm-input";
		const datasetInput = container.querySelector(datasetClass);
		const tagGroupClass = isEdit
			? ".edit-dataset-tag-group-input"
			: ".add-dataset-tag-group-input";
		const tagGroupInput = container.querySelector(tagGroupClass);

		const compositeKey = datasetInput.getAttribute("data-composite-key");
		const tagGroupValue = tagGroupInput.value.trim();
		const newTagGroup = _.chain(tagGroups)
			.find(["name", tagGroupValue])
			.pick(["name", "uuid"])
			.value();

		const parsedKey = wpd.CompositeKeyUtils.parseCompositeKey(compositeKey);
		const nameValue =
			parsedKey.subgroup ||
			parsedKey.population ||
			parsedKey.studyArm ||
			parsedKey.studyPart ||
			parsedKey.study;

		const activeDataset = wpd.tree.getActiveDataset();
		const dataset = activeDataset ? activeDataset : new wpd.Dataset();

		const uuid = activeDataset
			? activeDataset.getMetadata().uuid
			: uuidv4();

		dataset.name = `${nameValue}_${uuid}`;
		dataset.setMetadata({
			name: nameValue,
			uuid,
			studyData: {
				compositeKey,
				tagGroup: newTagGroup,
			},
		});

		if (!isEdit) {
			const dataset = new wpd.Dataset();
			const uuid = uuidv4();
			dataset.name = `${nameValue}_${uuid}`;
			dataset.setMetadata({
				name: nameValue,
				uuid,
				studyData: {
					compositeKey,
					tagGroup: newTagGroup,
				},
			});

			const plotData = wpd.appData.getPlotData();
			const fileManager = wpd.appData.getFileManager();
			const pageManager = wpd.appData.getPageManager();
			const isMultipage = wpd.appData.isMultipage();
			plotData.addDataset(dataset);
			fileManager.addDatasetsToCurrentFile([dataset]);

			if (isMultipage) {
				pageManager.addDatasetsToCurrentPage([dataset]);
			}

			// set the first dataset as the active dataset
			wpd.tree.setActiveDataset(dataset);

			// dispatch dataset add event
			wpd.events.dispatch("wpd.dataset.add", {
				dataset: dataset,
			});
			wpd.tree.refreshPreservingSelection();
			wpd.tree.switchDataset(dataset.name);
		} else {
			const dataset = wpd.tree.getActiveDataset();
			const uuid = dataset.getMetadata().uuid;
			dataset.name = `${nameValue}_${uuid}`;
			dataset.setMetadata({
				name: nameValue,
				uuid,
				studyData: {
					compositeKey,
					tagGroup: newTagGroup,
				},
			});

			const [
				studyKeyTag,
				partKeyTag,
				armKeyTag,
				populationKeyTag,
				subgroupKeyTag,
			] = wpd.CompositeKeyUtils.branchTags.map((tag) =>
				_.find(wpd.tagGroups.tags, { name: tag })
			);

			dataset
				.getAllPixels()
				.filter((pixel) => !!pixel.metadata)
				.forEach((pixel) => {
					pixel.metadata.compositeKey = compositeKey;
					pixel.metadata.tagGroup = newTagGroup;
					let mIndex = 0;
					const nonKeyMetadata = Object.keys(pixel.metadata)
						.filter(
							(key) =>
								!isNaN(key) &&
								!wpd.tagGroups.isKeyTag(
									pixel.metadata[key].tag.name
								)
						)
						.map((key) => pixel.metadata[key]);

					Object.keys(pixel.metadata)
						.filter((key) => !isNaN(key))
						.forEach((key) => delete pixel.metadata[key]);

					const keyMappings = [
						{ key: "study", tag: studyKeyTag },
						{ key: "studyPart", tag: partKeyTag },
						{ key: "studyArm", tag: armKeyTag },
						{
							key: "population",
							tag: populationKeyTag,
						},
						{
							key: "subgroup",
							tag: subgroupKeyTag,
						},
					];

					keyMappings.forEach(({ key, tag }) => {
						const val = parsedKey[key];
						if (val) {
							pixel.metadata[mIndex++] = {
								tag: {
									name: tag.name,
									uuid: tag.uuid,
								},
								value: val,
								hidden: true,
							};
						}
					});

					nonKeyMetadata.forEach((metadatum) => {
						pixel.metadata[mIndex++] = metadatum;
					});
				});
			wpd.tree.refreshPreservingSelection();
			wpd.tree.switchDataset(dataset.name);
		}

		wpd.dataSeriesManagement.resetDialog(isEdit);

		const dialogId = isEdit
			? "edit-graph-dataset-dialog"
			: "add-graph-dataset-dialog";
		wpd.popup.close(dialogId);
	};

	wpd.dataSeriesManagement.cancelAddDataset = function () {
		wpd.dataSeriesManagement.resetDialog(false);
		wpd.popup.close("add-graph-dataset-dialog");
	};

	wpd.dataSeriesManagement.cancelEditDataset = function () {
		wpd.dataSeriesManagement.resetDialog(true);
		wpd.popup.close("edit-graph-dataset-dialog");
	};

	// override rename dataset popup function to use display name instead of name
	wpd.dataSeriesManagement.showRenameDataset = function () {
		const dataset = wpd.tree.getActiveDataset();
		const $renameInput = wpdDocument.getElementById(
			"rename-dataset-name-input"
		);

		// get dataset metadata
		const metadata = dataset.getMetadata();

		// check for metadata name
		if (metadata.name) {
			$renameInput.value = metadata.name;
		} else {
			$renameInput.value = dataset.name;
		}

		wpd.popup.show("rename-dataset-popup");
	};

	// override rename dataset logic to recreate ${displayName}_${uuid} for name and remove duplicate check
	wpd.dataSeriesManagement.renameDataset = function () {
		wpd.popup.close("rename-dataset-popup");

		const dataset = wpd.tree.getActiveDataset();

		const $renameInput = wpdDocument.getElementById(
			"rename-dataset-name-input"
		);

		// get dataset metadata
		const metadata = dataset.getMetadata();

		const newName = $renameInput.value.trim();

		let displayName = newName;

		if (metadata.name && metadata.uuid) {
			// update the name in the dataset metadata
			metadata.name = newName;
			dataset.setMetadata(metadata);

			displayName = `${newName}_${metadata.uuid}`;
		}

		dataset.name = displayName;

		wpd.tree.refreshPreservingSelection();
	};

	wpd.dataSeriesManagement.renameKeypress = function (e) {
		if (e.key === "Enter") {
			wpd.dataSeriesManagement.renameDataset();
		}
	};

	wpd.dataSeriesManagement.setSameTagGroupForAll = function () {};
}

export { init };
