/**
 * Adds repainters for table axis.
 */
function apply(wpd, dataEntryVM) {
	/**
	 * Repainter class responsible for drawing the rectangle. All pixel values and rotations here are bound to the image,
	 * unless otherwise noted.
	 */
	wpd.TableRepainter = class {
		constructor(table) {
			this.ctx = wpd.graphicsWidget.getAllContexts().dataCtx;

			this.area = table.area;
			this.rows = table.rows;
			this.cols = table.cols;

			// calculate area min/max to always get the same corners
			this.areaMin = 0;
			this.areaMax = 0;
			this.calculateAreaMinMax();

			// rotation related variables
			this.rotation = table.rotation ?? 0;
			this.swap = wpd.TableRepainter.swapTest(this.rotation);
			this.topLeft = ((rotation) => {
				const topLeftDirection = {
					0: { x: "-", y: "-" },
					90: { x: "-", y: "+" },
					180: { x: "+", y: "+" },
					270: { x: "+", y: "-" },
				};
				return topLeftDirection[rotation];
			})(this.rotation);

			this.drawHeaders = table.drawHeaders ?? true;
			this.hover = table.hover;
			this.selecting = table.selecting;
			this.activeCell = table.activeCell;
			this.colData = table.colData;

			this.painterName = "TableRepainter";
		}

		// factor to multiply cell width or height by for headers
		static rowHeaderGradientFactor = 3;
		static colHeaderGradientFactor = 3;

		// position of row and column headers for all rotations
		static headerLocation = {
			rows: {
				0: "left",
				90: "bottom",
				180: "right",
				270: "top",
			},
			cols: {
				0: "top",
				90: "left",
				180: "bottom",
				270: "right",
			},
		};

		static swapTest(rotation) {
			// true if rotation is 90 or 270 degrees
			return rotation % 180 === 90;
		}

		static getTopLeftDirection(rotation) {
			const directionMap = {
				0: { x: "-", y: "-" },
				90: { x: "-", y: "+" },
				180: { x: "+", y: "+" },
				270: { x: "+", y: "-" },
			};

			return directionMap[rotation];
		}

		calculateAreaMinMax() {
			if (this.area.length > 1) {
				this.areaMin = {
					x:
						this.area[0].x > this.area[1].x
							? this.area[1].x
							: this.area[0].x,
					y:
						this.area[0].y > this.area[1].y
							? this.area[1].y
							: this.area[0].y,
				};
				this.areaMax = {
					x:
						this.area[0].x > this.area[1].x
							? this.area[0].x
							: this.area[1].x,
					y:
						this.area[0].y > this.area[1].y
							? this.area[0].y
							: this.area[1].y,
				};
			}
		}

		toScreenPixels(imagePixels) {
			if (dataEntryVM.highQualityPdfZoomFlag) {
				const pageManager = wpd.appData.getPageManager();
				let pdfScaleFactor = 1;
				if (pageManager instanceof wpd.PDFManager) {
					pdfScaleFactor = pageManager.currentScaleFactor;
				}

				const zoomRatio =
					wpd.graphicsWidget.getZoomRatio() * pdfScaleFactor;

				return imagePixels.map((pixel) => pixel * zoomRatio);
			} else {
				const zoomRatio = wpd.graphicsWidget.getZoomRatio();

				return imagePixels.map((pixel) => pixel * zoomRatio);
			}
		}

		getAreaWidth() {
			return this.areaMax.x - this.areaMin.x;
		}

		getAreaHeight() {
			return this.areaMax.y - this.areaMin.y;
		}

		getRowCount() {
			return this.rows.dividers.length + 1;
		}

		getColumnCount() {
			return this.cols.dividers.length + 1;
		}

		getAverageCellWidth() {
			return (
				this.getAreaWidth() /
				(this.swap ? this.getRowCount() : this.getColumnCount())
			);
		}

		getAverageCellHeight() {
			return (
				this.getAreaHeight() /
				(this.swap ? this.getColumnCount() : this.getRowCount())
			);
		}

		getCellWidth(colIndex) {
			const dividers = this.swap
				? this.rows.dividers
				: this.cols.dividers;
			const ends =
				this.topLeft.x === "-"
					? [this.areaMin.x, this.areaMax.x]
					: [this.areaMax.x, this.areaMin.x];

			const x0 = colIndex === 0 ? ends[0] : dividers[colIndex - 1];
			const x1 =
				colIndex === dividers.length ? ends[1] : dividers[colIndex];

			return x1 - x0;
		}

		getCellHeight(rowIndex) {
			const dividers = this.swap
				? this.cols.dividers
				: this.rows.dividers;
			const ends =
				this.topLeft.y === "-"
					? [this.areaMin.y, this.areaMax.y]
					: [this.areaMax.y, this.areaMin.y];

			const y0 = rowIndex === 0 ? ends[0] : dividers[rowIndex - 1];
			const y1 =
				rowIndex === dividers.length ? ends[1] : dividers[rowIndex];

			return y1 - y0;
		}

		getColStart(colIndex) {
			const axis = this.swap ? "y" : "x";

			const index = colIndex - 1;
			const areaStart =
				this.topLeft[axis] === "+"
					? this.areaMax[axis]
					: this.areaMin[axis];

			// if the active column is the first or last, use the x value of the area
			return colIndex < 1 ? areaStart : this.cols.dividers[index];
		}

		getRowStart(rowIndex) {
			const axis = this.swap ? "x" : "y";

			const index = rowIndex - 1;
			const areaStart =
				this.topLeft[axis] === "+"
					? this.areaMax[axis]
					: this.areaMin[axis];

			// if the active column is the first or last, use the x value of the area
			return rowIndex < 1 ? areaStart : this.rows.dividers[index];
		}

		drawTableArea(ctx) {
			// draw a black rectangle
			if (this.area.length > 1) {
				// set active color when defining area
				if (!this.rows?.dividers && !this.cols?.dividers) {
					ctx.strokeStyle = wpd.Colors.active;
				} else {
					ctx.strokeStyle = wpd.Colors.headerActiveDarkA;
				}

				ctx.lineWidth = 6;

				// recalculate area min/max to get the top-left and bottom-right corners, related to image
				this.calculateAreaMinMax();

				const args = [
					this.areaMin.x,
					this.areaMin.y,
					this.getAreaWidth(),
					this.getAreaHeight(),
				];

				ctx.strokeRect(...this.toScreenPixels(args));
			}
		}

		drawTableDivisions(ctx) {
			const lineWidth = 4;

			// draw rows
			if (this.rows?.dividers.length > 0) {
				for (let i = 0; i < this.rows.dividers.length; i++) {
					ctx.beginPath();
					ctx.lineWidth = lineWidth;

					// set active color when adjusting rows
					if (i === this.selecting?.row) {
						ctx.strokeStyle = wpd.Colors.active;
					} else {
						ctx.strokeStyle = wpd.Colors.headerActiveDarkA;
					}

					const moveToArgs = this.swap
						? [this.rows.dividers[i], this.areaMin.y]
						: [this.areaMin.x, this.rows.dividers[i]];

					const lineToArgs = this.swap
						? [this.rows.dividers[i], this.areaMax.y]
						: [this.areaMax.x, this.rows.dividers[i]];

					ctx.moveTo(...this.toScreenPixels(moveToArgs));
					ctx.lineTo(...this.toScreenPixels(lineToArgs));
					ctx.stroke();
				}
			}

			// draw columns
			if (this.cols?.dividers.length > 0) {
				for (let i = 0; i < this.cols.dividers.length; i++) {
					ctx.beginPath();
					ctx.lineWidth = lineWidth;

					// set active color when adjusting rows
					if (i === this.selecting?.col) {
						ctx.strokeStyle = wpd.Colors.active;
					} else {
						ctx.strokeStyle = wpd.Colors.headerActiveDarkA;
					}

					const moveToArgs = this.swap
						? [this.areaMin.x, this.cols.dividers[i]]
						: [this.cols.dividers[i], this.areaMin.y];

					const lineToArgs = this.swap
						? [this.areaMax.x, this.cols.dividers[i]]
						: [this.cols.dividers[i], this.areaMax.y];

					ctx.moveTo(...this.toScreenPixels(moveToArgs));
					ctx.lineTo(...this.toScreenPixels(lineToArgs));
					ctx.stroke();
				}
			}
		}

		drawActiveCellOutline(ctx) {
			if (
				this.activeCell?.col != null &&
				this.activeCell?.col > -1 &&
				this.activeCell?.row != null &&
				this.activeCell?.row > -1
			) {
				const args = this.swap
					? [
							this.getRowStart(this.activeCell.row),
							this.getColStart(this.activeCell.col),
							this.getCellWidth(this.activeCell.row),
							this.getCellHeight(this.activeCell.col),
					  ]
					: [
							this.getColStart(this.activeCell.col),
							this.getRowStart(this.activeCell.row),
							this.getCellWidth(this.activeCell.col),
							this.getCellHeight(this.activeCell.row),
					  ];

				// translate to screen pixels
				const screenArgs = this.toScreenPixels(args);

				// draw the rectangle
				ctx.lineWidth = 6;
				ctx.strokeStyle = wpd.Colors.active;
				ctx.strokeRect(...screenArgs);
			}
		}

		getHeaderGradientArgs(type, headerWidth, headerHeight) {
			// define gradient line with 2 points:
			//  [x0, y0, x1, y1]
			const args = {
				top: [0, this.areaMin.y, 0, this.areaMin.y - headerHeight],
				right: [this.areaMax.x, 0, this.areaMax.x + headerWidth, 0],
				bottom: [0, this.areaMax.y, 0, this.areaMax.y + headerHeight],
				left: [this.areaMin.x, 0, this.areaMin.x - headerWidth, 0],
			};

			return args[wpd.TableRepainter.headerLocation[type][this.rotation]];
		}

		getRowHeaderRectangleArgs(index, headerWidth, headerHeight) {
			// define rectangle with a point, width, and height
			//  [x, y, width, height]
			const args = {
				top: [
					index === 0
						? this.areaMax.x
						: this.rows.dividers[index - 1],
					this.areaMin.y - headerHeight,
					this.getCellWidth(index) + 1,
					headerHeight,
				],
				right: [
					this.areaMax.x + headerWidth,
					index === 0
						? this.areaMax.y
						: this.rows.dividers[index - 1],
					-headerWidth,
					this.getCellHeight(index) + 1,
				],
				bottom: [
					index === 0
						? this.areaMin.x
						: this.rows.dividers[index - 1],
					this.areaMax.y + headerHeight,
					this.getCellWidth(index) - 1,
					-headerHeight,
				],
				left: [
					this.areaMin.x - headerWidth,
					index === 0
						? this.areaMin.y
						: this.rows.dividers[index - 1],
					headerWidth,
					this.getCellHeight(index) - 1,
				],
			};

			return args[
				wpd.TableRepainter.headerLocation["rows"][this.rotation]
			];
		}

		getColHeaderRectangleArgs(index, headerWidth, headerHeight) {
			// define rectangle with a point, width, and height
			//  [x, y, width, height]
			const args = {
				top: [
					index === 0
						? this.areaMin.x
						: this.cols.dividers[index - 1],
					this.areaMin.y - headerHeight,
					this.getCellWidth(index) - 1,
					headerHeight,
				],
				right: [
					this.areaMax.x + headerWidth,
					index === 0
						? this.areaMin.y
						: this.cols.dividers[index - 1],
					-headerWidth,
					this.getCellHeight(index) - 1,
				],
				bottom: [
					index === 0
						? this.areaMax.x
						: this.cols.dividers[index - 1],
					this.areaMax.y + headerHeight,
					this.getCellWidth(index) + 1,
					-headerHeight,
				],
				left: [
					this.areaMin.x - headerWidth,
					index === 0
						? this.areaMax.y
						: this.cols.dividers[index - 1],
					headerWidth,
					this.getCellHeight(index) + 1,
				],
			};

			return args[
				wpd.TableRepainter.headerLocation["cols"][this.rotation]
			];
		}

		drawHeaderGradients(ctx) {
			// width/height of gradients are calculated based on width/height of cells multipled by a factor
			const headerWidth =
				this.getAverageCellWidth() *
				wpd.TableRepainter.rowHeaderGradientFactor;
			const headerHeight =
				this.getAverageCellHeight() *
				wpd.TableRepainter.colHeaderGradientFactor;

			if (!this.rows?.headerless && this.rows?.headers.length) {
				// draw header gradients on calibrated rotation top-left
				const gradientArgs = this.getHeaderGradientArgs(
					"rows",
					headerWidth,
					headerHeight
				);

				// define gradient start and end points
				const screenGradientArgs = this.toScreenPixels(gradientArgs);

				// create gradient
				const gradientGood = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientBad = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientGoodHover = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientBadHover = ctx.createLinearGradient(
					...screenGradientArgs
				);

				// define color stops
				wpd.Colors.addColorStop("good", gradientGood);
				wpd.Colors.addColorStop("bad", gradientBad);
				wpd.Colors.addColorStop("goodHover", gradientGoodHover);
				wpd.Colors.addColorStop("badHover", gradientBadHover);

				// draw headers
				for (let i = 0; i < this.rows.headers.length; i++) {
					if (this.rows.headers[i]) {
						// header defined, good
						if (this.hover?.header?.rows === i) {
							ctx.fillStyle = gradientGoodHover;
						} else {
							ctx.fillStyle = gradientGood;
						}
					} else {
						// header not defined, bad
						if (this.hover?.header?.rows === i) {
							ctx.fillStyle = gradientBadHover;
						} else {
							ctx.fillStyle = gradientBad;
						}
					}

					const rowRectangleArgs = this.getRowHeaderRectangleArgs(
						i,
						headerWidth,
						headerHeight
					);

					ctx.fillRect(...this.toScreenPixels(rowRectangleArgs));
				}
			}

			if (!this.cols?.headerless && this.cols?.headers.length) {
				const gradientArgs = this.getHeaderGradientArgs(
					"cols",
					headerWidth,
					headerHeight
				);

				// define gradient start and end points
				const screenGradientArgs = this.toScreenPixels(gradientArgs);

				// create gradient
				const gradientGood = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientBad = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientGoodHover = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientBadHover = ctx.createLinearGradient(
					...screenGradientArgs
				);

				// define color stops
				wpd.Colors.addColorStop("good", gradientGood);
				wpd.Colors.addColorStop("bad", gradientBad);
				wpd.Colors.addColorStop("goodHover", gradientGoodHover);
				wpd.Colors.addColorStop("badHover", gradientBadHover);

				// draw headers
				for (let i = 0; i < this.cols.headers.length; i++) {
					if (this.cols.headers[i]) {
						// header defined, good
						if (this.hover?.header?.cols === i) {
							ctx.fillStyle = gradientGoodHover;
						} else {
							ctx.fillStyle = gradientGood;
						}
					} else {
						// header not defined, bad
						if (this.hover?.header?.cols === i) {
							ctx.fillStyle = gradientBadHover;
						} else {
							ctx.fillStyle = gradientBad;
						}
					}

					const colRectangleArgs = this.getColHeaderRectangleArgs(
						i,
						headerWidth,
						headerHeight
					);

					ctx.fillRect(...this.toScreenPixels(colRectangleArgs));
				}
			}
		}

		fillCells(ctx) {
			if (this.activeCell?.col != null && this.colData) {
				// width/height of gradients are calculated based on width/height of cells multipled by a factor
				const headerWidth =
					this.getAverageCellWidth() *
					wpd.TableRepainter.rowHeaderGradientFactor;
				const headerHeight =
					this.getAverageCellHeight() *
					wpd.TableRepainter.colHeaderGradientFactor;

				// create gradients for active row/col headers
				const rowGradientArgs = this.getHeaderGradientArgs(
					"rows",
					headerWidth,
					headerHeight
				);
				const colGradientArgs = this.getHeaderGradientArgs(
					"cols",
					headerWidth,
					headerHeight
				);

				// create gradient
				const rowGradient = ctx.createLinearGradient(
					...this.toScreenPixels(rowGradientArgs)
				);
				const colGradient = ctx.createLinearGradient(
					...this.toScreenPixels(colGradientArgs)
				);

				// define gradient color stops
				wpd.Colors.addColorStop("active", rowGradient);
				wpd.Colors.addColorStop("active", colGradient);

				// create draw functions
				const drawRowGradient = (index) => {
					const headerArgs = this.getRowHeaderRectangleArgs(
						index,
						headerWidth,
						headerHeight
					);
					ctx.fillStyle = rowGradient;
					ctx.fillRect(...this.toScreenPixels(headerArgs));
				};
				const drawColGradient = (index) => {
					const headerArgs = this.getColHeaderRectangleArgs(
						index,
						headerWidth,
						headerHeight
					);
					ctx.fillStyle = colGradient;
					ctx.fillRect(...this.toScreenPixels(headerArgs));
				};

				const yAxis = this.swap ? "x" : "y";

				// process all columns
				for (let i = 0; i < this.colData.length; i++) {
					const x = this.getColStart(i);

					// fill in each cell in the column
					for (let j = 0; j < this.colData[i].length; j++) {
						// define the cell rectangle
						const y =
							j === 0
								? this.topLeft[yAxis] === "-"
									? this.areaMin[yAxis]
									: this.areaMax[yAxis]
								: this.rows.dividers[j - 1];

						// cell has defined headers, assume data should be collected
						if (
							(this.cols.headers[i] && this.rows.headers[j]) ||
							(this.rows.headerless && this.cols.headers[i]) ||
							(this.cols.headerless && this.rows.headers[j])
						) {
							// cell has data and data has values other than uuid and rowIndex
							if (
								this.colData[i][j] &&
								Object.keys(this.colData[i][j].metadata)
									.length > 2
							) {
								if (
									this.hover?.cell.cols === i &&
									this.hover?.cell.rows === j
								) {
									// fill header gradients
									if (!this.rows.headerless) {
										drawRowGradient(j);
									}
									if (!this.cols.headerless) {
										drawColGradient(i);
									}

									ctx.fillStyle = wpd.Colors.goodHoverA;
								} else {
									ctx.fillStyle = wpd.Colors.goodA;
								}
							} else {
								// no data
								if (
									this.hover?.cell.cols === i &&
									this.hover?.cell.rows === j
								) {
									// fill header gradients
									if (!this.rows.headerless) {
										drawRowGradient(j);
									}
									if (!this.cols.headerless) {
										drawColGradient(i);
									}

									// fill hover colors
									if (this.colData[i][j] === undefined) {
										ctx.fillStyle = wpd.Colors.nullHoverA;
									} else {
										ctx.fillStyle = wpd.Colors.badHoverA;
									}
								} else {
									if (this.colData[i][j] === undefined) {
										ctx.fillStyle = wpd.Colors.nullA;
									} else {
										ctx.fillStyle = wpd.Colors.badA;
									}
								}
							}
						} else {
							// otherwise, data not required
							ctx.fillStyle = wpd.Colors.nullA;
						}

						let cellTopLeft = [x, y];
						let rowIndex = j;
						let colIndex = i;
						if (this.swap) {
							cellTopLeft = [y, x];
							rowIndex = i;
							colIndex = j;
						}

						const args = [
							...cellTopLeft,
							this.getCellWidth(colIndex),
							this.getCellHeight(rowIndex),
						];

						ctx.fillRect(...this.toScreenPixels(args));
					}
				}
			}
		}

		onAttach() {
			wpd.graphicsWidget.resetData();
		}

		onRedraw() {
			this.drawTableArea(this.ctx);
			this.drawTableDivisions(this.ctx);
			if (this.drawHeaders) {
				this.drawHeaderGradients(this.ctx);
			}
			this.drawActiveCellOutline(this.ctx);
			this.fillCells(this.ctx);
		}

		onForcedRedraw() {
			wpd.graphicsWidget.resetData();
		}

		drawPoints() {
			this.onRedraw();
		}
	};
}

export { apply };
