<template>
	<v-container>
		<v-row>
			<v-select
				v-model="attachAction"
				:items="attachActions"
				label="Attach to paper"
				persistent-hint
				hint="Files must be attached to a paper."
				:menu-props="{ offsetY: true }"
				prepend-icon="mdi-file-tree-outline"
			/>
		</v-row>

		<v-row
			v-if="attachAction === 'EXIST'"
			class="my-5 mb-10"
		>
			<paper-search
				v-model="paperSelect"
				:default-paper="defaultPaper"
				:disabled="!!submitting"
			/>
		</v-row>

		<v-row
			v-else
			class="my-5"
		>
			<v-col
				class="pa-0"
				cols="12"
			>
				<paper-edit
					ref="paperEdit"
					v-model:invalid="subInvalid"
					create-only
					nested
					no-actions
					:hide-file-upload="hideFileUpload"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import _ from "lodash";

import { mapMutations } from "vuex";

import PaperEdit from "@/pages/PaperManager/PaperEdit";
import PaperSearch from "@/pages/PaperManager/PaperSearch";

export default {
	name: "PaperSelect",

	components: {
		PaperEdit,
		PaperSearch,
	},

	props: {
		defaultPaper: { type: Object, default: () => null },
		hideFileUpload: { type: Boolean },
	},
	emits: ["update:invalid"],

	data() {
		return {
			attachAction: "NEW",
			attachActions: [
				{
					title: "Create a new paper",
					value: "NEW",
				},
				{
					title: "Add to an existing paper",
					value: "EXIST",
				},
			],
			invalid: true,
			paperSelect: null,
			subInvalid: true,
			submitting: false,
		};
	},

	watch: {
		attachAction() {
			this.invalid = true;
		},
		defaultPaper() {
			this.selectPaper();
		},
		invalid(val) {
			if (val) {
				this.$emit("update:invalid", true);
			} else {
				this.$emit("update:invalid", false);
			}
		},
		paperSelect(val) {
			if (this.attachAction === "EXIST") {
				if (!val) {
					this.invalid = true;
				} else {
					this.invalid = false;
				}
			}
		},
		subInvalid(val) {
			if (this.attachAction === "NEW") {
				if (val) {
					this.invalid = true;
				} else {
					this.invalid = false;
				}
			}
		},
	},

	mounted() {
		this.selectPaper();

		this.$watch("$refs.paperEdit.$v.$invalid", function (val) {
			if (this.attachAction === "NEW") {
				if (val) {
					this.invalid = true;
				} else {
					this.invalid = false;
				}
			}
		});
	},

	methods: {
		...mapMutations(["showNotification"]),
		clear() {
			if (_.isEmpty(this.defaultPaper)) {
				this.paperSelect = null;
				this.attachAction = "NEW";
			} else {
				// revert to default paper if it exists
				this.selectPaper();
			}

			if (this.$refs.paperEdit) {
				this.$refs.paperEdit.clear();
			}
		},
		select() {
			const context = this;

			context.submitting = true;

			return new Promise((resolve, reject) => {
				if (context.attachAction === "NEW") {
					// create paper
					context.$refs.paperEdit
						.saveRequest("add")
						.then((response) => resolve(response.data.paper))
						.catch((error) => reject(error))
						.finally(() => (context.submitting = false));
				} else {
					// select paper
					resolve(context.paperSelect);

					context.submitting = false;
				}
			});
		},
		selectPaper() {
			if (!_.isEmpty(this.defaultPaper)) {
				const paper = Object.assign({}, this.defaultPaper);
				this.paperSelect = paper;
				this.attachAction = "EXIST";
			} else {
				this.clear();
			}
		},
	},
};
</script>

<style scoped lang="scss"></style>
