<template>
	<v-row
		align="start"
		align-content="start"
		justify="start"
	>
		<v-overlay
			:model-value="loading"
			class="align-center justify-center"
		>
			<v-progress-circular
				color="primary"
				indeterminate
				size="64"
			/>
		</v-overlay>

		<v-col
			v-if="!loading && displayPaperTable"
			cols="12"
			order="1"
		>
			<paper-table
				queue-mode
				qc-mode
			/>
		</v-col>
		<v-col
			v-else-if="!loading"
			cols="6"
			order="2"
		>
			<!-- <paper-staging
				qc-mode
				:single-mode="!displayPaperTable"
			/> -->
		</v-col>
	</v-row>
</template>

<script>
import { mapMutations } from "vuex";

import PaperTable from "@/pages/PaperManager/PaperTable";

export default {
	name: "QCDashboard",

	components: {
		PaperTable,
	},

	data() {
		return {
			displayPaperTable: false,
			loading: false,
			inProgress: false,
		};
	},

	computed: {
		paper() {
			return this.$store.getters.qcPaper;
		},
		saveUUID() {
			return this.$store.getters.qcSaveUUID;
		},
		user() {
			return this.$store.getters.user;
		},
	},

	created() {
		this.checkUserPaperTablePermission();
	},

	methods: {
		...mapMutations([
			"clearQCPaper",
			"clearQCSaveUUID",
			"setQCPaper",
			"setQCSaveUUID",
		]),
		checkUserPaperTablePermission() {
			this.loading = "secondary";

			this.$http
				.get(`/users/${this.user.uuid}/can-all`, {
					params: {
						permissions: ["access_work_queues"],
					},
				})
				.then((response) => {
					if (response.data.allowed) {
						this.displayPaperTable = true;
					} else {
						this.displayPaperTable = false;
					}
				})
				.catch(() => {
					// do not display errors on permission checks
					this.displayPaperTable = false;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped lang="scss">
.col {
	flex-grow: 0;
}
</style>
