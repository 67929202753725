import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

function generate(wpd, wpdDocument, dataEntryVM) {
	return (function (vm) {
		let currentAxis = null;

		/**
		 * User script entrypoint.
		 */
		function run() {
			attachListeners();
		}

		/**
		 * Attaches listeners to WPD events.
		 */
		function attachListeners() {
			// unregister all previous listeners
			wpd.events.removeAllListeners("wpd.axes.add");
			wpd.events.removeAllListeners("wpd.axes.select");
			wpd.events.removeAllListeners("wpd.axes.delete");
			wpd.events.removeAllListeners("wpd.dataset.add");
			wpd.events.removeAllListeners("wpd.dataset.select");
			wpd.events.removeAllListeners("wpd.dataset.point.add");
			wpd.events.removeAllListeners("wpd.dataset.point.select");
			wpd.events.removeAllListeners("wpd.dataset.point.delete");

			// axis select
			wpd.events.addListener("wpd.axes.select", (payload) => {
				const axis = payload.axes;

				const tweakButton = wpdDocument.getElementById(
					"tweak-axes-calibration-button"
				);
				const tableTweakButton = wpdDocument.getElementById(
					"tweak-table-axis-calibration-button"
				);

				if (axis instanceof wpd.ImageAxes && axis.getMetadata().table) {
					// hide default tweak button and show custom tweak button
					tweakButton.classList.add("hidden");
					tableTweakButton.classList.remove("hidden");

					// display header calibration sidebar
					wpd.alignAxes.displayDetailsSidebar(axis.getMetadata());
				} else {
					// non-table axes should have the default tweak button displayed
					tweakButton.classList.remove("hidden");
					tableTweakButton.classList.add("hidden");

					// unset default image point tools
					wpd.graphicsWidget.removeRepainter();
					wpd.graphicsWidget.removeTool();
					wpd.graphicsWidget.resetData();
					wpd.sidebar.clear();
				}
			});

			// axis delete
			wpd.events.addListener("wpd.axes.delete", () => {
				wpd.graphicsWidget.removeTool();
				wpd.graphicsWidget.removeRepainter();
				wpd.graphicsWidget.resetData();
			});

			// dataset add
			wpd.events.addListener("wpd.dataset.add", (payload) => {
				const dataset = payload.dataset;

				// associate dataset with the active axis
				wpd.appData
					.getPlotData()
					.setAxesForDataset(dataset, wpd.tree.getActiveAxes());

				// include alpha value for data points
				dataset.colorRGB = new wpd.Color(
					...wpd.Colors.getValues(wpd.Colors.goodA)
				);

				// overwrite point group save function if dataset is a table dataset
				if (dataset.getMetadata()?.table) {
					dataset.setPointGroups = function (pointGroups) {
						this._groupNames = pointGroups;

						// get metadata tags from dactyl
						const map = {};
						const tags = vm.getTags();
						pointGroups.forEach((uuid) => {
							const tag = tags.find((tag) => tag.uuid === uuid);

							map[tag.uuid] = tag.name;
						});

						const metadata = dataset.getMetadata();
						dataset.setMetadata(
							_.assign(metadata, { tagMap: map })
						);
					};
				}
			});

			// dataset select
			wpd.events.addListener("wpd.dataset.select", (payload) => {
				const plotData = wpd.appData.getPlotData();

				// store current axis for metadata dialog
				currentAxis = plotData.getAxesForDataset(payload.dataset);

				// set table header tool if this is a table column
				if (
					currentAxis instanceof wpd.ImageAxes &&
					currentAxis.getMetadata().table &&
					payload.dataset.getMetadata().table
				) {
					// unset default image point tools
					wpd.graphicsWidget.removeRepainter();
					wpd.graphicsWidget.removeTool();
					wpd.graphicsWidget.resetData();
					wpd.sidebar.clear();

					// set table header tool
					wpd.graphicsWidget.setTool(
						new wpd.TableCellSelectionTool(
							currentAxis,
							payload.dataset.getMetadata().colIndex
						)
					);
				} else if (wpd.custom.isGraphAxis(currentAxis)) {
					// make sure tag group controls are hidden
					wpd.tagGroups.hideControls();

					const metadata = payload.dataset.getMetadata();
					const parsedKey = wpd.CompositeKeyUtils.parseCompositeKey(
						metadata.studyData.compositeKey
					);
					wpd.tagGroups.setActiveKeys(parsedKey);

					if (dataEntryVM.reworkStudyTree) {
						wpd.tagGroups.refreshStudies(
							metadata.studyData.compositeKey,
							true
						);
					} else {
						wpd.tagGroups.refreshStudies();
					}

					wpd.acquireData.manualSelection();
				}

				// set focus on canvas
				wpdDocument.getElementById("topCanvas").dispatchEvent(
					new MouseEvent("mousedown", {
						bubbles: true,
						button: 0,
						buttons: 1,
						composed: true,
						detail: 1,
					})
				);
			});

			// data point add
			wpd.events.addListener("wpd.dataset.point.add", async (payload) => {
				const dataPoint = payload.dataset.getPixel(payload.index);
				if (wpd.custom.isGraphAxis(currentAxis)) {
					const [
						studyKeyTag,
						partKeyTag,
						armKeyTag,
						populationKeyTag,
						subgroupKeyTag,
					] = wpd.CompositeKeyUtils.branchTags.map((tag) =>
						_.find(wpd.tagGroups.tags, { name: tag })
					);

					// do not trigger metadata dialog for graphs
					const axisType = wpd.custom.identifyAxis(currentAxis);
					const studyData = payload.dataset.getMetadata().studyData;
					const axesLabelData =
						currentAxis.getMetadata().axesLabelData;

					if (studyData && axesLabelData) {
						// graph data adds
						// add data points for study arm abbreviation, x-value,
						// x-unit, y-value, y-unit
						const valueUnitTag = wpd.tagGroups.findTag({
							name: "Unit",
						});
						const timeUnitTag = wpd.tagGroups.findTag({
							name: "Time (unit)",
						});

						const [xValue, yValue] = currentAxis.pixelToData(
							dataPoint.x,
							dataPoint.y
						);

						// find the "change from baseline" tag
						const changeFromBaselineTag = wpd.tagGroups.findTag({
							name: "Change from baseline",
						});

						// define the tag group shared across the set of data points
						const tagGroup = {
							name: studyData.tagGroup.name,
							uuid: studyData.tagGroup.uuid,
							instanceUuid: uuidv4(),
						};

						if (axisType === "xy") {
							// handle xy graphs axis data
							// find the right value tag for x and y values
							const xValueTag = wpd.tagGroups.findTag({
								name: axesLabelData.x.dataType,
							});

							const yValueTag = wpd.tagGroups.findTag({
								name: axesLabelData.y.dataType,
							});

							// find the right unit tag for x and y units
							const xUnitTag =
								axesLabelData.x.dataType === "Time (number)"
									? timeUnitTag
									: valueUnitTag;

							const yUnitTag =
								axesLabelData.y.dataType === "Time (number)"
									? timeUnitTag
									: valueUnitTag;

							// add change from baseline data
							const changeFromBaseline = {};
							let lastIndex = 4;
							if (axesLabelData.x.changeFromBaseline) {
								changeFromBaseline[++lastIndex] = {
									tag: changeFromBaselineTag,
									value: "Yes",
									axis: "x",
								};
							}
							if (axesLabelData.y.changeFromBaseline) {
								changeFromBaseline[++lastIndex] = {
									tag: changeFromBaselineTag,
									value: "Yes",
									axis: "y",
								};
							}

							// add study arm abbreviation, x-value, x-unit, y-value,
							// y-unit to the newly added data point
							let mIndex = 0;
							const metadata = {
								...changeFromBaseline,
								type: axisType,
								tagGroup: tagGroup,
								compositeKey:
									wpd.CompositeKeyUtils.generateCompositeKey(
										wpd.tagGroups.activeStudyKey,
										wpd.tagGroups.activePartKey,
										wpd.tagGroups.activeArmKey,
										wpd.tagGroups.activePopulationKey,
										wpd.tagGroups.activeSubgroupKey
									),
							};

							const keyMappings = [
								{
									activeKey: "activeStudyKey",
									tag: studyKeyTag,
								},
								{
									activeKey: "activePartKey",
									tag: partKeyTag,
								},
								{
									activeKey: "activeArmKey",
									tag: armKeyTag,
								},
								{
									activeKey: "activePopulationKey",
									tag: populationKeyTag,
								},
								{
									activeKey: "activeSubgroupKey",
									tag: subgroupKeyTag,
								},
							];

							keyMappings.forEach(({ activeKey, tag }) => {
								const val = wpd.tagGroups[activeKey];
								if (val) {
									setMetadata(
										metadata,
										tag,
										val,
										mIndex++,
										true
									);
								}
							});

							metadata[mIndex++] = {
								tag: xValueTag,
								value: wpd.utils.toDecimalPlaces(
									xValue,
									axesLabelData.x.decimals
								),
								axis: "x",
							};
							metadata[mIndex++] = {
								tag: xUnitTag,
								value: axesLabelData.x.unit,
								axis: "x",
							};
							metadata[mIndex++] = {
								tag: yValueTag,
								value: wpd.utils.toDecimalPlaces(
									yValue,
									axesLabelData.y.decimals
								),
								axis: "y",
							};
							metadata[mIndex++] = {
								tag: yUnitTag,
								value: axesLabelData.y.unit,
								axis: "y",
							};

							payload.dataset.setMetadataAt(
								payload.index,
								metadata
							);
						} else if (axisType === "bar") {
							// handle bar graph axis data
							const barHeaders =
								currentAxis.getMetadata().barHeaders;

							if (barHeaders) {
								const headers = barHeaders.headers;

								// bar axis pixelToData always returns one value
								const value = xValue;

								// figure out if categories are on the vertical
								// or horizontal axis
								const [valueOrientation, headerOrientation] =
									barHeaders.headerOrientation ===
									"HORIZONTAL"
										? ["y", "x"]
										: ["x", "y"];

								const startKey = `start${headerOrientation.toUpperCase()}`;
								const endKey = `end${headerOrientation.toUpperCase()}`;
								const headerIndex = _.findIndex(
									headers,
									(header) => {
										return _.inRange(
											dataPoint[headerOrientation],
											header[startKey],
											header[endKey]
										);
									}
								);

								// prepare header data for insertion
								let lastIndex = 2;
								const headerData =
									headerIndex > -1
										? _.chain(headers[headerIndex].metadata)
												.omitBy(wpd.utils.isWord)
												.keys()
												.reduce((result, key) => {
													result[++lastIndex] =
														headers[
															headerIndex
														].metadata[key];

													return result;
												}, {})
												.value()
										: null;

								// find the right value tag for value
								const valueTag = wpd.tagGroups.findTag({
									name: axesLabelData.bar.dataType,
								});

								// find the right unit tag for units
								const unitTag =
									axesLabelData.bar.dataType ===
									"Time (number)"
										? timeUnitTag
										: valueUnitTag;

								// add change from baseline data
								const changeFromBaseline = {};
								if (axesLabelData.bar.changeFromBaseline) {
									changeFromBaseline[++lastIndex] = {
										tag: changeFromBaselineTag,
										value: "Yes",
										axis: valueOrientation,
									};
								}

								// add study arm abbreviation, x-value, x-unit, y-value,
								// y-unit to the newly added data point
								let mIndex = 0;
								const metadata = {
									...changeFromBaseline,
									type: axisType,
									tagGroup: tagGroup,
									compositeKey: studyData.compositeKey,
								};

								const parsedKey =
									wpd.CompositeKeyUtils.parseCompositeKey(
										studyData.compositeKey
									);

								if (headerData) {
									Object.values(headerData).forEach(
										(header) => {
											if (
												header.tag.name ===
												wpd.CompositeKeyUtils
													.referenceArmTag
											) {
												metadata.referenceCompositeKey =
													header.value;
											}
											metadata[mIndex++] = header;
										}
									);
								}

								const keyMappings = [
									{ key: "study", tag: studyKeyTag },
									{ key: "studyPart", tag: partKeyTag },
									{ key: "studyArm", tag: armKeyTag },
									{
										key: "population",
										tag: populationKeyTag,
									},
									{
										key: "subgroup",
										tag: subgroupKeyTag,
									},
								];

								keyMappings.forEach(({ key, tag }) => {
									const val = parsedKey[key];
									if (val) {
										setMetadata(
											metadata,
											tag,
											val,
											mIndex++,
											true
										);
									}
								});

								metadata[mIndex++] = {
									tag: valueTag,
									value: wpd.utils.toDecimalPlaces(
										value,
										axesLabelData.bar.decimals
									),
									axis: valueOrientation,
								};

								metadata[mIndex++] = {
									tag: unitTag,
									value: axesLabelData.bar.unit,
									axis: valueOrientation,
								};

								payload.dataset.setMetadataAt(
									payload.index,
									metadata
								);
							}
						}
					}
				} else {
					await wpd.custom.processDataPoint(
						payload.dataset,
						payload.index,
						currentAxis
					);

					// switch to adjust data point tool
					wpd.acquireData.adjustPoints();
				}

				// select all the added data points
				payload.dataset.unselectAll();

				// repaint the dataset and refresh the zoom image
				wpd.graphicsWidget.forceHandlerRepaint();
				const { x, y } = dataPoint;
				wpd.graphicsWidget.updateZoomToImagePosn(x, y);
			});

			// data point select
			wpd.events.addListener(
				"wpd.dataset.point.select",
				async (payload) => {
					if (payload.indexes.length && payload.indexes[0] >= 0) {
						// at least one point has been selected and this is not
						// an unselect (unselected returns [-1])

						// redraw points
						wpd.graphicsWidget.forceHandlerRepaint();

						if (payload.indexes.length === 1) {
							await wpd.custom.processDataPoint(
								payload.dataset,
								payload.indexes[0],
								currentAxis,
								payload.abbreviationOnly
							);
						} else {
							// multiple points selected
							// TODO: Disabling metadata collection for multiple points
							// for now; we need to figure out what happens if you select
							// points from different tag groups
							// await wpd.custom.processDataPoints(
							// 	payload.dataset,
							// 	payload.indexes,
							// 	payload.abbreviationOnly
							// );
						}
					}
				}
			);

			// data point delete
			wpd.events.addListener("wpd.dataset.point.delete", (payload) => {
				// refresh tag group displays
				if (wpd.tagGroups.activeTagGroup) {
					wpd.tagGroups.refreshAll(true);
				}

				wpd.custom.syncMetadataKeys(payload.dataset);
			});
		}

		function setMetadata(metadata, tag, value, index, hidden) {
			if (value) {
				metadata[index] = {
					tag: {
						name: tag.name,
						uuid: tag.uuid,
					},
					value: value,
					...(hidden ? { hidden: true } : {}),
				};
			}
		}

		return {
			run: run,
		};
	})(dataEntryVM);
}

export { generate };
