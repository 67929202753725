// store.js
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

const getDefaultState = () => {
	return {
		authState: Cookies.get("authenticated"),
		dialogExpanded: false,
		dirty: false,
		discardDataEntry: false,
		done: false,
		exit: false,
		fetchMetadata: false,
		help: "",
		metadataCache: {
			data: {},
		},
		module: "wpd",
		notification: {
			message: "",
			status: "",
			persist: false,
		},
		paper: null,
		qcPaper: null,
		qcSave: false,
		qcSaveUUID: null,
		resetDataEntry: false,
		save: false,
		saveInProgress: false,
		saveUUID: null,
		sessionProjects: [],
		sessionOrganizations: [],
		user: {
			id: -1,
			cookie_notice_dismissed_at: null,
			email: "",
			first_name: "",
			last_name: "",
			initials: "",
			uuid: "",
		},
		validate: false,
	};
};

const store = createStore({
	plugins: [
		createPersistedState({
			paths: [
				"dialogExpanded",
				"paper",
				"qcPaper",
				"qcSaveUUID",
				"saveUUID",
				"sessionProjects",
				"sessionOrganizations",
				"user.id",
				"user.email",
				"user.first_name",
				"user.last_name",
				"user.initials",
				"user.uuid",
				"user.cookie_notice_dismissed_at",
			],
		}),
	],
	state: getDefaultState(),
	getters: {
		dialogExpanded: (state) => state.dialogExpanded,
		dirty: (state) => state.dirty,
		discardDataEntry: (state) => state.discardDataEntry,
		done: (state) => state.done,
		exit: (state) => state.exit,
		fetchMetadata: (state) => state.fetchMetadata,
		help: (state) => state.help,
		isAuthenticated: (state) => Boolean(state.authState),
		metadataCache: (state) => state.metadataCache.data,
		module: (state) => state.module,
		paper: (state) => state.paper,
		qcPaper: (state) => state.qcPaper,
		resetDataEntry: (state) => state.resetDataEntry,
		qcSave: (state) => state.qcSave,
		qcSaveUUID: (state) => state.qcSaveUUID,
		save: (state) => state.save,
		saveInProgress: (state) => state.saveInProgress,
		saveUUID: (state) => state.saveUUID,
		validate: (state) => state.validate,
		user: (state) => state.user,
	},
	mutations: {
		reset(state) {
			Object.assign(state, getDefaultState());
		},
		showNotification(state, payload) {
			state.notification.message = payload.message;
			state.notification.status = payload.status;
			state.notification.persist = payload.persist;
			state.notification.timeout = payload.timeout;
		},
		clearNotification(state) {
			state.notification.message = "";
			state.notification.status = "";
			state.notification.persist = false;
		},
		setAuthenticated(state, value) {
			state.authState = value;
		},
		setUser(state, payload) {
			state.user.id = payload.id;
			state.user.uuid = payload.uuid;
			state.user.email = payload.email;
			state.user.first_name = payload.first_name;
			state.user.last_name = payload.last_name;
			state.user.initials =
				payload.first_name.charAt(0) + payload.last_name.charAt(0);
			state.user.cookie_notice_dismissed_at =
				payload.cookie_notice_dismissed_at;
		},
		clearUser(state) {
			state.user = getDefaultState().user;
		},
		setDialogExpanded(state, payload) {
			state.dialogExpanded = payload;
		},
		setPaper(state, payload) {
			state.paper = payload;
		},
		clearPaper(state) {
			state.paper = null;
		},
		setQCPaper(state, payload) {
			state.qcPaper = payload;
		},
		clearQCPaper(state) {
			state.qcPaper = null;
		},
		sendSave(state, payload) {
			if (payload) {
				if (payload.type === "reset_data_entry") {
					state.resetDataEntry = true;
				} else if (payload.type === "discard_data_entry") {
					state.discardDataEntry = true;
				} else if (payload.type === "data_entry") {
					state.save = true;
				} else if (payload.type === "quality_control") {
					state.qcSave = true;
				}

				if (payload.done) {
					state.done = true;
				}
				if (payload.exit) {
					state.exit = true;
				}
				if (payload.resolve) {
					state.resolve = payload.resolve;
				}
			}
		},
		receiveSave(state) {
			state.discardDataEntry = false;
			state.resetDataEntry = false;
			state.saveInProgress = false;
			state.save = false;
			state.qcSave = false;
			state.done = false;
			state.exit = false;

			if (state.resolve) {
				state.resolve();
				state.resolve = null;
			}
		},
		sendValidate(state) {
			state.validate = true;
		},
		receiveValidate(state) {
			state.validate = false;
		},
		sendFetchMetadata(state) {
			state.fetchMetadata = true;
		},
		receiveFetchMetadata(state) {
			state.fetchMetadata = false;
		},
		setMetadataCache(state, payload) {
			state.metadataCache.data = payload;
		},
		clearMetadataCache(state) {
			state.metadataCache.data = {};
		},
		setDirty(state) {
			state.dirty = true;
		},
		clearDirty(state) {
			state.dirty = false;
		},
		setSaveInProgress(state, payload) {
			state.saveInProgress = payload;
		},
		setSaveUUID(state, payload) {
			state.saveUUID = payload;
		},
		clearSaveUUID(state) {
			state.saveUUID = null;
		},
		setQCSaveUUID(state, payload) {
			state.qcSaveUUID = payload;
		},
		clearQCSaveUUID(state) {
			state.qcSaveUUID = null;
		},
		setSessionProjects(state, payload) {
			state.sessionProjects = payload;
		},
		clearSessionProjects(state) {
			state.sessionProjects = [];
		},
		setSessionOrganizations(state, payload) {
			state.sessionOrganizations = payload;
		},
		clearSessionOrganizations(state) {
			state.sessionOrganizations = [];
		},
		setHelp(state, payload) {
			state.help = payload;
		},
		clearHelp(state) {
			state.help = "";
		},
	},
});

export default store;
