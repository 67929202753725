<template>
	<v-dialog
		v-model="dialog"
		max-width="600"
	>
		<template #activator="{ props }">
			<v-btn
				v-bind="props"
				id="logout-button"
				class="bg-secondary text-white"
				elevation="2"
			>
				<span class="logout mr-1">Log out</span>

				<v-icon size="small"> mdi-logout-variant </v-icon>
			</v-btn>
		</template>

		<v-card
			class="pb-5"
			:disabled="!!loading"
			:loading="loading"
		>
			<v-toolbar
				color="primary"
				dark
				flat
			>
				<v-toolbar-title>Log out</v-toolbar-title>

				<v-spacer />

				<v-tooltip location="bottom">
					<template #activator="{ props }">
						<v-btn
							v-bind="props"
							icon
							size="large"
							@click="close"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</template>

					<span>Close</span>
				</v-tooltip>
			</v-toolbar>

			<v-card-text
				v-if="dirty"
				class="pa-10 pb-0 mb-n5 text-center text-button text-error"
			>
				{{ dirtyText }}
			</v-card-text>

			<v-card-text class="pa-10 text-center text-subtitle-1">
				{{ confirmationText }}
			</v-card-text>

			<v-card-actions>
				<v-spacer />

				<v-btn
					class="bg-secondary text-white"
					elevation="2"
					@click="logout"
				>
					Log out
				</v-btn>

				<v-btn
					elevation="2"
					@click="close"
				>
					Cancel
				</v-btn>

				<v-spacer />
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import * as Sentry from "@sentry/vue";
import auth from "@/auth";
import { mapMutations } from "vuex";

export default {
	name: "VLogout",
	emits: ["deactivate-activity-timer"],

	data() {
		return {
			confirmationText: "Are you sure you want to log out?",
			dirtyText: "Warning: All unsaved changes will be lost.",
			dialog: false,
			loading: false,
			notification: {
				message: "",
				status: "",
			},
		};
	},

	computed: {
		dirty() {
			return this.$store.getters.dirty;
		},
		token() {
			return this.$store.getters.token;
		},
	},

	methods: {
		...mapMutations(["reset", "setAuthenticated", "showNotification"]),
		close: function () {
			this.dialog = false;
		},
		logout: function () {
			this.loading = "secondary";

			// deactivate activity tracker
			this.$emit("deactivate-activity-timer");

			try {
				this.$http
					.post("/users/logout")
					.then((response) => {
						// clear store (no need to clear token specifically)
						this.reset();

						// close dialog
						this.close();

						// display success
						this.showNotification(response.data);
					})
					.catch((error) => {
						// display error

						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}
					})
					.finally(() => {
						this.loading = false;

						// clear sentry user
						Sentry.setUser(null);

						// clear cookie
						auth.clearAuthenticated();
						this.setAuthenticated(false);

						// redirect to welcome page
						this.$router.push("/");
					});
			} catch (error) {
				console.error(error, "Logout error");

				// clear sentry user
				Sentry.setUser(null);

				// clear the cookie if something has gone wrong
				auth.clearAuthenticated();

				// redirect to welcome page
				this.$router.push("/");
			}
		},
	},
};
</script>

<style scoped lang="scss">
.logout {
	line-height: 36px;
}
</style>
